import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Quote } from "../../../utils/httpUtils";
import NewCategoryModal from "../../../modals/NewCategoryModal";
import CreateSavedQuoteModalMobile from "../../../modals/mobile/CreateSavedQuoteModalMobile";
import { GreenButton } from "../../reusableComponents/Buttons";
import {
  CustomSelect,
  CustomSelectWithLabel,
  CustomTextField,
} from "../../reusableComponents/Inputs";

const AddSingleQuoteMobile = (props) => {
  const navigate = useNavigate();
  const [savedQuoteTitle, setSavedQuoteTitle] = useState("");
  const [quoteCategory, setQuoteCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [quoteCategories, setQuoteCategories] = useState(
    props.quoteCategories || []
  );
  const [savedQuotes, setSavedQuotes] = useState(props.savedQuotes || []);
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const [displayCreateSavedQuoteModal, setDisplayCreateSavedQuoteModal] =
    useState(false);
  const [propertyId, setPropertyId] = useState(props.propertyId);

  useEffect(() => {
    setSavedQuotes(props.savedQuotes);
    setQuoteCategories(props.quoteCategories);
    setPropertyId(props.propertyId);
  }, [props.savedQuotes, props.quoteCategories, props.propertyId]);

  const handleCategoryModalOpen = () => {
    setDisplayCategoryModal(true);
  };

  const handleCategoryModalClose = () => {
    setDisplayCategoryModal(false);
  };

  const handleSavedQuoteModalOpen = () => {
    setDisplayCreateSavedQuoteModal(true);
  };

  const handleSavedQuoteModalClose = () => {
    setDisplayCreateSavedQuoteModal(false);
  };

  const handleSavedQuoteSelect = (selectedTitle) => {
    const selectedSavedQuote = savedQuotes.find(
      (quote) => quote.savedQuoteTitle === selectedTitle
    );
    setSavedQuoteTitle(selectedSavedQuote?.savedQuoteTitle || "");
    setTitle(selectedSavedQuote?.title || "");
    setPrice(selectedSavedQuote?.price || "");
    setDescription(selectedSavedQuote?.description || "");
    setQuoteCategory(selectedSavedQuote?.quoteCategory || "");
  };

  const addQuote = () => {
    const data = {
      quoteCategory,
      price,
      title,
      description,
      propertyId,
    };
    Quote()
      .postJson("addQuote", data)
      .then((response) => {
        if (response.response.status === 200) {
          props.updateProperty();
          return;
        } else {
          console.log("error creating new quote");
        }
      });
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Typography
          variant="h4"
          component="div"
          sx={{ textAlign: "center", marginBottom: 2, userSelect: "none" }}
        >
          Add Single Quote
        </Typography>
        <Box mb={2} textAlign="center">
          <Typography
            variant="body1"
            component="div"
            sx={{
              textAlign: "center",
              userSelect: "none",
            }}
          >
            Select Saved Quote
          </Typography>
          <CustomSelect
            value={savedQuoteTitle}
            onChange={(e) => handleSavedQuoteSelect(e.target.value)}
            options={savedQuotes?.map((quote) => ({
              label: quote.savedQuoteTitle,
              value: quote.savedQuoteTitle,
            }))}
            variant="outlined"
            fullWidth
            onAddNewClick={handleSavedQuoteModalOpen}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{ borderBottom: "1px solid black", marginBottom: "20px" }}
            ></div>
            <CustomSelectWithLabel
              label="Quote Category"
              value={quoteCategory}
              onChange={(e) => setQuoteCategory(e.target.value)}
              options={
                quoteCategories?.length > 0
                  ? quoteCategories.map((category) => ({
                      label: category,
                      value: category,
                    }))
                  : []
              }
              fullWidth
              onAddNewClick={handleCategoryModalOpen}
              sx={{ marginBottom: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ marginBottom: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Price"
              variant="outlined"
              fullWidth
              value={price}
              onChange={(e) => {
                const numericInput = e.target.value.replace(/[^0-9.]/g, "");
                const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(numericInput);

                if (isValidInput) {
                  setPrice(numericInput);
                }
              }}
              sx={{ marginBottom: 1 }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Description"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Grid>
        </Grid>

        <div style={{ marginTop: "30px" }}>
          <GreenButton
            onClick={addQuote}
            disabled={
              quoteCategory.length === 0 ||
              title.length === 0 ||
              description.length === 0 ||
              price.length === 0
            }
          >
            Add Quote
          </GreenButton>
        </div>
      </Grid>
      <NewCategoryModal
        open={displayCategoryModal}
        onClose={handleCategoryModalClose}
        updateVendor={props.updateVendor}
      />
      <CreateSavedQuoteModalMobile
        open={displayCreateSavedQuoteModal}
        onClose={handleSavedQuoteModalClose}
        updateVendor={props.updateVendor}
        displayCategoryModal={displayCategoryModal}
        quoteCategories={quoteCategories}
        openCategoryModal={handleCategoryModalOpen}
        closeCategoryModal={handleCategoryModalClose}
      />
    </Grid>
  );
};

export default AddSingleQuoteMobile;
