import React, { useEffect, useState } from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { useParams, useNavigate } from "react-router-dom";
import { Job as JobRoute } from "../../../utils/httpUtils";
import {
  formatDateTime,
  formatAddressNoZipMobile,
} from "../../../utils/utils";
import BottomNavbar from "../../../components/vendorComponents/mobile/BottomNavBarJobMobile";

import {
  GreenButton,
} from "../../../components/reusableComponents/Buttons";
import CustomCheckbox from "../../../components/reusableComponents/Customcheckbox";
import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import ScheduleJobModalMobile from "../../../modals/mobile/ScheduleJobModalMobile";
import RecurringServiceModal from "../../../modals/RecurringServiceModal";
import CancelRecurringModal from "../../../modals/CancelRecurringModal";
import ManualPaymentModal from "../../../modals/ManualPaymentModal";

function JobMobile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showScheduleJobModal, setShowScheduleJobModal] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [customer, setCustomer] = useState();
  const [selectedService, setSelectedService] = useState(null);
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);

  useEffect(() => {
    getJob();
  }, []);

  useEffect(() => {
    if (job) {
      setPropertyId(job.propertyId);
      setIsLoading(false);
    }
  }, [job]);

  useEffect(() => {
    if (job) {
      setCustomerId(job.customerId);
    }
  }, [job]);

  const getJob = () => {
    const data = {
      jobId: id,
    };
    JobRoute()
      .getJson("getJobForVendor", data)
      .then((response) => {
        setJob(response.data.job);
        setServices(response.data.services);
        setCustomer(response.data.customer);
      });
  };

  const scheduleJob = () => {
    setShowScheduleJobModal(true);
  };

  const handleCheckboxChange = (service) => {
    if (service.recurring?.isRecurring) {
      setSelectedService(service);
      setShowCancelRecurring(true);
    } else {
      setSelectedService(service);
      setShowRecurringServiceModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowScheduleJobModal(false);
    setShowRecurringServiceModal(false);
  };

  const handleUpdate = () => {
    getJob();
  };

  const updateStatus = (value) => {
    const data = {
      jobId: id,
      jobStatus: value,
    };
    JobRoute()
      .postJson("updateJobStatus", data)
      .then((response) => {
        getJob();
      });
  };

  const cancelJob = () => {
    console.log("cancel job");
  };

  const goToAddress = () => {
    navigate(`/address/${propertyId}`);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const goToCustomer = () => {
    navigate(`/customers/${customer._id}`);
  };

  const openPaymentModal = () => {
    setDisplayPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setDisplayPaymentModal(false);
  };

  const goToInvoice = (jobId) => {
    navigate(`/invoice/${job.invoiceId}`);
  };

  const buildAddress = () => {
    const bits = formatAddressNoZipMobile(job.address.readableAddress);
    return (
      <Typography component="div" sx={{ fontSize: "18px" }}>
        {bits[0]}
        <br />
        {bits[1]}
      </Typography>
    );
  };

  return (
    <div>
      <VendorNavbar />
      <ScheduleJobModalMobile
        open={showScheduleJobModal}
        onClose={() => setShowScheduleJobModal(false)}
        jobId={job?._id}
        handleUpdate={getJob}
      />
      <ManualPaymentModal
        open={displayPaymentModal}
        onClose={() => setDisplayPaymentModal(false)}
        job={job}
      />
      <RecurringServiceModal
        open={showRecurringServiceModal}
        onClose={() => setShowRecurringServiceModal(false)}
        service={selectedService}
        handleUpdate={getJob}
        jobId={id}
        propertyId={propertyId}
        customerId={customer?._id}
        scheduledDate={job?.scheduledDate}
      />
      <CancelRecurringModal
        open={showCancelRecurring}
        onClose={() => setShowCancelRecurring(false)}
        service={selectedService}
        handleUpdate={getJob}
      />

      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ padding: "5px", marginTop: "-50px" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={8} sx={{ textAlign: "left" }}>
                    <Typography variant="body1">
                      {job?.address?.readableAddress && buildAddress()}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} sx={{ textAlign: "right" }}>
                    <GreenButton onClick={goToAddress}>Address</GreenButton>
                  </Grid>

                  {/* <Divider sx={{ marginTop: 2, marginBottom: 2, backgroundColor: 'black', height: 2 }} /> */}

                  <Grid item xs={12} sm={12} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: "18px" }}>
                      {customer?.firstName} {customer?.lastName}
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {customer?.email}
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {formatPhoneNumber(customer?.phoneNumber)}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={4} sm={4} sx={{ textAlign: "right"}}>
                  <GreenButton variant="contained" color="primary" onClick={goToCustomer}>
                      View Customer
                    </GreenButton>
                  </Grid> */}

                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "18px" }}>
                      Scheduled Date:{" "}
                      {job?.scheduledDate
                        ? formatDateTime(job.scheduledDate)
                        : "Not Scheduled"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ fontSize: "18px" }}>
                      Total Price: $
                      {job?.totalPrice && job?.totalPrice.toFixed(2)}
                    </Typography>
                  </Grid>
                  {job?.upsell > 0 && (
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: "18px" }}>
                        Upsell Amount: ${job?.upsell.toFixed(2)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "18px" }}>Service</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Price</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Recurring</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Frequency</TableCell>
              <TableCell sx={{ fontSize: "18px" }}>Last Performed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services?.map((service, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: "18px" }}>
                  {service.quoteTitle}
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>
                  ${service?.quotePrice?.toFixed(2)}
                </TableCell>
                <TableCell>
                  <CustomCheckbox
                    checked={!!service.recurring?.isRecurring}
                    onChange={() => handleCheckboxChange(service)}
                  />
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>
                  {service.recurring?.recurringFrequency
                    ? `${service.recurring.recurringFrequency} ${service.recurring.recurringUnit}`
                    : "-"}
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>
                  {service.recurring?.lastPerformed
                    ? formatDateTime(service.recurring.lastPerformed)
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BottomNavbar
        onSchedule={() => setShowScheduleJobModal(true)}
        onComplete={() => updateStatus("COMPLETED")}
        onPayment={openPaymentModal}
      />
    </div>
  );
}

export default JobMobile;
