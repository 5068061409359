import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import ExistingQuotesByAddress from "../../../components/vendorComponents/ExistingQuotesByAddress";
import { Vendor } from "../../../utils/httpUtils";
import QuoteStastics from "../../../components/vendorComponents/QuoteStatistics";
import NewQuote from "../../../components/vendorComponents/NewQuote";

function QuotesDesktop() {
  const [jobs, setJobs] = useState();

  useEffect(() => {
    getAllJobsForVendor();
  }, []);

  const getAllJobsForVendor = () => {
    Vendor()
      .getJson("getAllJobsForVendor")
      .then((response) => {
        setJobs(response.data);
      });
  };

  return (
    <div>
      <VendorNavbar />
      <div id="vendorDashboard">
        <Grid container columnSpacing={4}>
          <Grid item xs={5}>
            <Grid container direction="column">
              <Grid item>
                <ExistingQuotesByAddress jobs={jobs} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <NewQuote />
              </Grid>
              <Grid item>
                <QuoteStastics />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default QuotesDesktop;
