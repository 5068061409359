import React from 'react'
import VendorNavbar from '../../components/vendorComponents/VendorNavbar'

function AdminDashboard() {
  return (
    <>
        <VendorNavbar/>
    </>
  )
}

export default AdminDashboard