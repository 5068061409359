import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { Vendor } from "../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  formatDateTime,
  determineName,
  formatPhoneNumber,
  formatAddress,
  mapJobStatus,
} from "../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  styled,
} from "@mui/material";

const AllCustomerJobs = () => {
  const { id } = useParams();
  const [jobs, setJobs] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("scheduledDate");
  const [jobsLoading, setJobsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("scheduledDate");
  const [customer, setCustomer] = useState(null);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getAllJobsForCustomer();
  }, []);

  const getAllJobsForCustomer = () => {
    const data = {
      customerId: id,
    };
    Vendor()
      .getJson("getAllJobsForCustomer", data)
      .then((response) => {
        if (response?.response?.status === 200) {
          setJobs(response.data.jobs);
          setCustomer(response.data.customer);
        } else {
          console.log("error communicating with server");
        }
      });
    setJobsLoading(false);
  };

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const sortedJobs = () => {
    if (!Array.isArray(jobs)) {
      return [];
    }

    return [...jobs].sort((a, b) => {
      let aValue = a[sortColumn];
      let bValue = b[sortColumn];

      if (sortColumn === "scheduledDate") {
        aValue = a.scheduledDate ? formatDateTime(a.scheduledDate) : "";
        bValue = b.scheduledDate ? formatDateTime(b.scheduledDate) : "";
      } else if (sortColumn === "jobStatus") {
      } else if (sortColumn === "paymentStatus") {
      } else if (sortColumn === "address") {
        aValue = a.address ? formatAddress(a.address.readableAddress) : "";
        bValue = b.address ? formatAddress(b.address.readableAddress) : "";
      } else if (sortColumn === "customerName") {
        aValue = `${a.customerFirst} ${a.customerLast}`;
        bValue = `${b.customerFirst} ${b.customerLast}`;
      } else if (sortColumn === "customerPhone") {
        aValue = a.customerPhone || "";
        bValue = b.customerPhone || "";
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  };

  const handleFilterOptionChange = (event) => {
    setSearchText("");
    setFilterOption(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredJobs = () => {
    if (!Array.isArray(jobs)) {
      return [];
    }

    const lowerSearchText = searchText.toLowerCase();

    return jobs.filter((job) => {
      const fieldValue =
        filterOption === "address"
          ? job.address.readableAddress
          : job[filterOption];
      let fieldValueString = fieldValue ? fieldValue.toString() : "";

      if (filterOption === "scheduledDate") {
        fieldValueString = formatDateTime(fieldValue);
      } else if (filterOption === "address") {
        fieldValueString = formatAddress(fieldValue);
      } else if (filterOption === "customerName") {
        fieldValueString = `${job.customerFirst} ${job.customerLast}`;
      } else if (filterOption === "customerPhone") {
        fieldValueString = fieldValue || "";
      }

      const includesSearchText = fieldValueString
        .toLowerCase()
        .includes(lowerSearchText);

      return includesSearchText;
    });
  };

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  return (
    <>
      <VendorNavbar />
      <div style={{ margin: "40px" }}>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 2, userSelect: "none" }}
        >
          {customer ? `Jobs for ${determineName(customer)}` : null}
        </Typography>
        <Typography
          variant="h6"
          component="div"
          sx={{ marginBottom: 2, userSelect: "none" }}
        >
          Click job to see more information
        </Typography>

        <div style={{ marginBottom: "16px" }}>
          <TextField
            select
            label="Filter Option"
            variant="outlined"
            value={filterOption}
            onChange={handleFilterOptionChange}
            sx={{ marginRight: "16px" }}
          >
            <MenuItem value="scheduledDate">Scheduled Date</MenuItem>
            <MenuItem value="totalPrice">Payment Amount</MenuItem>
            <MenuItem value="jobStatus">Job Status</MenuItem>
            <MenuItem value="paymentStatus">Payment Status</MenuItem>
            <MenuItem value="address">Address</MenuItem>
            <MenuItem value="customerPhone">Customer Phone</MenuItem>
          </TextField>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </div>

        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("scheduledDate")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    Scheduled Date
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("totalPrice")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    Payment Amount
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("jobStatus")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    Job Status
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("paymentStatus")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    Payment Status
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("address")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    Address
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("customerPhone")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    Customer Phone
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobsLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                filteredJobs().map((job) => (
                  <ClickableTableRow
                    key={job._id}
                    onClick={() => goToJob(job._id)}
                  >
                    <TableCell>
                      {job.scheduledDate ? (
                        <Typography sx={{ fontSize: "18px" }}>
                          {formatDateTime(job.scheduledDate)}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: "18px" }}>
                          Not Scheduled
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        $ {job.totalPrice?.toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {mapJobStatus(job.jobStatus)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {mapJobStatus(job.paymentStatus)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatAddress(job.address.readableAddress)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {customer
                          ? formatPhoneNumber(customer.phoneNumber)
                          : null}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default AllCustomerJobs;
