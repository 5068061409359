import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typography } from "@mui/material";

const DateTimePicker = ({ updateSelectedDate, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(true);

  const handleChange = (date) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  const customStyles = {
    selected: {
      backgroundColor: "black",
      color: "white",
    },
  };

  const CustomInput = ({ value, onClick }) => (
    <Typography variant="h5" component="div" onClick={onClick}>
      {value}
    </Typography>
  );

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h5"
        component="div"
        sx={{
          marginBottom: "10px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Schedule Job
      </Typography>
      <DatePicker
        selected={selectedDate}
        onChange={handleChange}
        showTimeSelect
        timeFormat="hh:mm aa"
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText="Select date and time"
        popperPlacement="bottom-end"
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [160, 20],
            },
          },
        ]}
        open={isCalendarOpen}
        onFocus={() => setIsCalendarOpen(true)}
        customInput={<CustomInput />}
        style={customStyles}
        minDate={new Date()}
      />
    </div>
  );
};

export default DateTimePicker;
