import React from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import NewQuote from "../../../components/vendorComponents/NewQuote";
import Box from "@mui/material/Box";

function QuotesMobile() {
  return (
    <div>
      <VendorNavbar />
      <Box p={2}>
        <NewQuote />
      </Box>
    </div>
  );
}

export default QuotesMobile;
