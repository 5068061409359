import React from "react";
import CommonNavbar from "../../components/commonComponents/CommonNavbar";

function NotFound() {
  return (
    <div>
      <CommonNavbar />
      <div>NotFound</div>
    </div>
  );
}

export default NotFound;
