import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../commonComponents/LoadingIcon";

const JobsPerTicket = ({ jobsPerTicket, perTicketLoading }) => {
  const [isLoading, setIsLoading] = useState(perTicketLoading);

  useEffect(() => {
    if (jobsPerTicket !== undefined || jobsPerTicket === 0) {
      setIsLoading(false);
    }
    if (perTicketLoading !== undefined) {
      setIsLoading(false);
    }
  }, [jobsPerTicket, perTicketLoading]);

  return (
    <Card
      className="analyticsCard"
      style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography variant="h4" component="div" sx={{ marginBottom: 1, userSelect: "none" }}>
        Services per Ticket
        </Typography>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Typography variant="h6" color="text.secondary" sx={{userSelect: "none"}}>
            Your average ticket has {jobsPerTicket.toFixed(2)} services
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default JobsPerTicket;
