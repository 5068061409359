import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import VendorNavbar from '../../components/vendorComponents/VendorNavbar';
import { Admin } from '../../utils/httpUtils';
import { Grid, Typography } from '@mui/material';
import BillingAndPayment from '../../components/adminComponents/BillingAndPayment';

function Vendor() {
    const { vendorId } = useParams();
    const [vendor, setVendor] = useState([])

    useEffect(() => {
        getVendor()
    }, [vendorId])


    const getVendor = () => {
        const data = {
            vendorId
        }
        Admin().getJson('getVendor', data)
        .then(response => {
            setVendor(response.data.vendor);
        })
    }

    const displayStatus = () => {
        if (vendor.status === "ACTIVE") {
            return (
                <span style={{color: "white", backgroundColor: "green", marginLeft: "20px", border: "1px solid green", borderRadius: "10px", padding: "5px", paddingLeft: "10px", paddingRight: "10px"}}>Active</span>
            )
        }
        if (vendor.status === "INACTIVE") {
            return (
                <span style={{color: "white", backgroundColor: "red", marginLeft: "20px", border: "1px solid red", borderRadius: "10px", padding: "5px", paddingLeft: "10px", paddingRight: "10px"}}>Inactive</span>
            )
        }
    }


  return (
    <>
    <VendorNavbar/>
    {vendor && (
        <div style={{ marginLeft: "20px"}}>
        <Typography variant="h3" sx={{ marginBottom: "10px"}}>{vendor.companyName} {displayStatus()}</Typography>
        {console.log("vendor: ", vendor)}
        <Typography variant="h6" sx={{ marginBottom: "50px"}}>vendor._id: {vendor._id}</Typography>
        <Grid container>
            <Grid item xs={3}>
<BillingAndPayment vendorId={vendorId}/>

            </Grid>
        </Grid>
        
        </div>


    ) }
    </>
  )
}

export default Vendor