import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Card,
  CardContent,
} from "@mui/material";

const SentEmails = ({ sentEmails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  // const goToCustomer = (customerId) => {
  //   navigate(`/customers/${customerId}`);
  // };

  const determineTitle = (email) => {
    if (email.emailTitle) {
      return email.emailTitle;
    }
    if (email.quoteEmail) {
      return "Sent Quotes";
    }
  };

  return (
    <Card style={{ width: "100%", border: "2px solid #ccc" }}>
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Sent Emails
        </Typography>
        <TableContainer
          component={Paper}
          style={{ marginBottom: "16px", userSelect: "none" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontSize: "26px" }}
                  >
                    Email Type
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontSize: "26px" }}
                  >
                    Customer
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ fontSize: "26px" }}
                  >
                    Sent Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                sentEmails?.map((email) => (
                  <ClickableTableRow
                    key={email._id}
                    //   onClick={() => goToCustomer(service.customerId)}
                  >
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {determineTitle(email)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {email.customerEmail}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatDateTime(email.sent)}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default SentEmails;
