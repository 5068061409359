import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from '@mui/material/styles';

const QuoteCard = (props) => {
  const { quote, handleOpenModal, isSelected } = props;
  const theme = useTheme();

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleOpenModal}
      style={{
        cursor: "pointer",
        padding: "2px",
        borderRadius: "8px",
        backgroundColor: isSelected ? theme.palette.greenButton.main : "white",
        color: isSelected ? "white" : "black" ,
        transition: "box-shadow 0.3s, backgroundColor 0.3s", // Include border in the transition
        border: isSelected ? `1px solid ${theme.palette.greenButton.main}` : "1px solid rgba(39, 47, 61, 0.2)", // Apply border when isSelected
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={4}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              userSelect: "none",
            }}
          >
            {quote?.title} ${quote?.price}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuoteCard;
