import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { Property, Vendor, Customer } from "../../../utils/httpUtils";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import AddSingleQuoteMobile from "../../../components/vendorComponents/mobile/AddSingleQuoteMobile";
import AddQuoteSetMobile from "../../../components/vendorComponents/mobile/AddQuoteSetMobile";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/system";
import CouponModalMobile from "../../../modals/CouponModalMobile";
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
} from "@mui/material";
import CurrentQuotesAtAddressMobile from "../../../components/vendorComponents/mobile/CurrentQuotesAtAddressMobile";

function AddressMobile() {
  const { pid } = useParams();
  const [quotes, setQuotes] = useState();
  const [property, setProperty] = useState();
  const [jobs, setJobs] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState();
  const [quoteCategories, setQuoteCategories] = useState();
  const [savedQuotes, setSavedQuotes] = useState();
  const [quoteSets, setQuoteSets] = useState();
  const [customers, setCustomers] = useState([]);
  const [quoteType, setQuoteType] = useState("single");
  const [displayCouponModal, setDisplayCouponModal] = useState(false);

  const theme = useTheme();

  const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
  });

  useEffect(() => {
    getPropertyData();
  }, [pid]);

  useEffect(() => {
    getVendor();
  }, []);

  useEffect(() => {
    if (property) {
      setIsLoading(false);
    }
  }, [property]);

  useEffect(() => {
    getCustomersAtAddress();
  }, []);

  const getCustomersAtAddress = () => {
    const data = {
      propertyId: pid,
    };
    Customer()
      .getJson("getCustomersAtAddress", data)
      .then((response) => {
        setCustomers(response.data.customers);
      });
  };

  const getPropertyData = () => {
    const data = {
      propertyId: pid,
    };
    Property()
      .getJson("getPropertyForVendor", data)
      .then((response) => {
        setProperty(response.data.property);
        setQuotes(response.data.quotes);
        setJobs(response.data.jobs);
      });
  };

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
        setSavedQuotes(response.data.savedQuotes);
        setQuoteCategories(response.data.quoteCategories);
        setQuoteSets(response.data.quoteSet);
      });
  };

  const updateProperty = async () => {
    getPropertyData();
  };

  const updateVendor = () => {
    getVendor();
  };

  const handleQuoteTypeChange = (event, newQuoteType) => {
    setQuoteType(newQuoteType);
  };

  const handleCloseCouponModal = () => {
    setDisplayCouponModal(false);
  };

  const handleOpenCouponModal = () => {
    setDisplayCouponModal(true);
  };

  return (
    <div>
      <VendorNavbar />
      <CouponModalMobile
        open={displayCouponModal}
        onClose={handleCloseCouponModal}
        handleUpdateCoupons={updateProperty}
      />
      <div id="vendorDashboard" style={{ marginTop: "-50px" }}>
        {isLoading ? (
          <div style={{ marginTop: "10px", marginBottom: "45px" }}>
            <LoadingIcon />
          </div>
        ) : (
          <Typography
            variant="h4"
            component="div"
            sx={{ marginBottom: "30px" }}
          >
            {property?.address.number} {property?.address.street}, {" "}
            {property?.address.city}
          </Typography>
        )}
        <Grid container spacing={2} direction="column">
          {quotes && quotes.length > 0 && (
            <Grid item>
              <CurrentQuotesAtAddressMobile
                quotes={quotes}
                propertyId={property?._id}
                updateProperty={updateProperty}
                getCustomersAtAddress={getCustomersAtAddress}
                handleOpenCouponModal={handleOpenCouponModal}
              />
            </Grid>
          )}
          <Grid item>
            <ToggleButtonGroup
              value={quoteType}
              exclusive
              onChange={handleQuoteTypeChange}
            >
              <StyledToggleButton value="single" aria-label="Single Quote">
                Single Quote
              </StyledToggleButton>
              <StyledToggleButton value="collection" aria-label="Collection">
                Collection
              </StyledToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            {quoteType === "single" ? (
              <AddSingleQuoteMobile
                savedQuotes={savedQuotes}
                quoteCategories={quoteCategories}
                propertyId={property?._id}
                updateProperty={updateProperty}
                updateVendor={updateVendor}
              />
            ) : (
              <AddQuoteSetMobile
                quoteSets={quoteSets}
                quoteCategories={quoteCategories}
                updateProperty={updateProperty}
                updateVendor={updateVendor}
                property={property}
              />
            )}
          </Grid>
          <Grid item></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AddressMobile;
