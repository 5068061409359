import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingIcon from '../commonComponents/LoadingIcon';

const AverageTicket = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.average) {
      setIsLoading(false)
    }
    if (props.average === undefined) {
      setIsLoading(false)
    }
  }, [props.average])

  return (
    <Card style={{ width: '100%', border: '1px solid #ccc', borderRadius: "10px" }}>
      <CardContent>
        <Typography variant="h4" component="div" sx={{ marginBottom: 1, userSelect: "none" }}>
          Average Ticket Price
        </Typography>
        {isLoading ? 
        <LoadingIcon />
      
      :
        <Typography variant="h6" color="text.secondary" sx={{userSelect: "none"}}>
          {props.average === undefined ? "You do not have an average ticket price" : 
          `Your average ticket price is $${props.average.toFixed(2)}`
          }
        </Typography>
      }
      </CardContent>
    </Card>
  );
};

export default AverageTicket;
