import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

// Desktop
import JobDesktop from "./desktop/JobDesktop";
// Mobile
import JobMobile from "./mobile/JobMobile";

function Job() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return <>{isMobile ? <JobMobile /> : <JobDesktop />}</>;
}

export default Job;
