import React, { useEffect, useState } from "react";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { useParams } from "react-router-dom";
import { Service as ServiceRoute } from "../../utils/httpUtils";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import ScheduleJobModal from "../../modals/ScheduleJobModal";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import RecurringServiceModal from "../../modals/RecurringServiceModal";
import CancelRecurringModal from "../../modals/CancelRecurringModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  styled,
  Checkbox,
} from "@mui/material";

function Service() {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showScheduleJobModal, setShowScheduleJobModal] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [customer, setCustomer] = useState();
  const [service, setService] = useState(null)
  const [property, setProperty] = useState(null)
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [quoteIds, setQuoteIds] = useState([]);

  const ClickableAddress = styled("div")({
    cursor: "pointer",
    "&:hover": {
      color: "tomato",
    },
  });

  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    collectQuoteIds()
  }, [services])

const collectQuoteIds = () => {
    if (services.length > 0) {
        const ids = services?.map(service => service?.quoteId)
        // const ids = services?.map(service => console.log(service.quoteId))
        setQuoteIds(ids)
    }
}


  const getService = () => {
    const data = {
      serviceId
    };
    ServiceRoute()
      .getJson("getServiceForVendor", data)
      .then((response) => {
        setService(response.data.service);
        setProperty(response.data.property);
        setCustomer(response.data.customer);
        setServices([response.data.service]);
      });
  };

  const formatDate = (date) => {
    const localDateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    const localDate = new Date(date).toLocaleString(
      undefined,
      localDateOptions
    );

    return localDate;
  };

  const scheduleJob = () => {
    setShowScheduleJobModal(true);
  };

  const handleCheckboxChange = (service) => {

    // if (service.recurring?.isRecurring) {
    //   setSelectedService(service);
    //   setShowCancelRecurring(true);
    // } else {
    //   setSelectedService(service);
    //   setShowRecurringServiceModal(true);
    // }
  };

  const handleCloseModal = () => {
    setShowScheduleJobModal(false);
    setShowRecurringServiceModal(false);
  };

const handleUpdate = (jobId) => {
      navigate(`/job/${jobId}`);
  };

  const updateStatus = (value) => {
    // const data = {
    //   jobId: id,
    //   jobStatus: value,
    // };
    // JobRoute()
    //   .postJson("updateJobStatus", data)
    //   .then((response) => {
    //     getJob();
    //   });
  };

  const cancelJob = () => {
    console.log("cancel job");
  };

  const goToAddress = () => {
    navigate(`/address/${propertyId}`);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  return (
    <div id="job">
      <VendorNavbar />
      <ScheduleJobModal
        open={showScheduleJobModal}
        onClose={handleCloseModal}
        handleUpdate={handleUpdate}
        customer={customer}
        services={services}
        propertyId={property?._id}
        quoteIds={quoteIds}
      />
      <RecurringServiceModal
        open={showRecurringServiceModal}
        onClose={handleCloseRecurringModal}
        service={service}
        handleUpdate={handleUpdate}
        propertyId={propertyId}
        customerId={customer?._id}
        scheduledDate={job?.scheduledDate}
      />
      <CancelRecurringModal
        open={showCancelRecurring}
        onClose={handleCloseCancelRecurringModal}
        service={service}
        handleUpdate={handleUpdate}
      />

      {service ? (
        <div style={{ width: "70%", margin: "0 auto" }}>
          <Card
            style={{
              width: "100%",
              border: "1px solid #ccc",
              marginTop: "30px",
              textAlign: "left",
              paddingLeft: "30px",
            }}
          >
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                sx={{ marginBottom: "28px", textDecoration: "underline" }}
              >
                Service Details
              </Typography>

              <ClickableAddress onClick={goToAddress}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    marginBottom: "28px",
                    color: "tomato",
                    textDecoration: "underline",
                  }}
                >
                  {property?.address.number} {property?.address.street}, {property?.address.city}
                </Typography>
              </ClickableAddress>

              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: "28px" }}
              >
                Customer Name: {customer?.firstName} {customer?.lastName}
              </Typography>

              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: "28px" }}
              >
                Customer Email: {customer?.email}
              </Typography>

              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: "28px" }}
              >
                Customer Phone: {formatPhoneNumber(customer.phoneNumber)}
              </Typography>

              {/* <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: "28px" }}
              >
                Scheduled Date:{" "}
                {job?.scheduledDate
                  ? formatDate(job.scheduledDate)
                  : "Not Scheduled"}
              </Typography> */}

              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: "20px" }}
              >
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ textDecoration: "underline", marginBottom: "20px" }}
                >
                  Service:
                </Typography>

                <TableContainer component={Card}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Recurring</TableCell>
                        <TableCell>Frequency</TableCell>
                        <TableCell>Last Performed</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={service._id}>
                          <TableCell>{service.serviceTitle}</TableCell>
                          <TableCell>
                            ${service.servicePrice.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={!!service.recurring?.isRecurring}
                              onChange={() => handleCheckboxChange(service)}
                            />
                          </TableCell>
                          <TableCell>
                            {service.recurring?.recurringFrequency ? `${service.recurring.recurringFrequency} ${service.recurring.recurringUnit}` : "-"}
                          </TableCell>
                          <TableCell>
                            {service.recurring?.lastPerformed ? formatDate(service.recurring.lastPerformed) : "-"}
                          </TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>

              <Typography
                variant="h5"
                component="div"
                sx={{ marginTop: "20px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={scheduleJob}
                  style={{ marginRight: "20px" }}
                >
                  Schedule Service
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus("COMPLETED")}
                  style={{ marginRight: "20px" }}
                >
                  Mark as Completed
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus("CANCELLED")}
                  style={{ marginRight: "20px" }}
                >
                  Cancel Job
                </Button> */}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div style={{ marginTop: "300px" }}>
          <LoadingIcon />
        </div>
      )}
    </div>
  );
}

export default Service;
