import React, { useEffect, useState } from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { useParams } from "react-router-dom";
import {
  Customer,
  Service,
  Request,
  Email,
  Vendor,
  Invoice,
} from "../../../utils/httpUtils";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import EditCustomerModal from "../../../modals/EditCustomerModal";
import AddAddressModal from "../../../modals/AddAddressModal";
import Box from "@mui/material/Box";
import RecurringServiceModal from "../../../modals/RecurringServiceModal";
import CancelRecurringModal from "../../../modals/CancelRecurringModal";
import CreateRecurringServiceModal from "../../../modals/CreateRecurringServiceModal";
import CreateJobModal from "../../../modals/CreateJobModal";
import { formatDate, formatDateTime, formatStatus } from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Grid,
  Select,
  styled,
} from "@mui/material";
import { GreenButton } from "../../../components/reusableComponents/Buttons";
import CustomCheckbox from "../../../components/reusableComponents/Customcheckbox";
import {
  CardHeader,
  TableHeader,
} from "../../../components/reusableComponents/Typography";

function CustomerDesktop() {
  const { id } = useParams();
  const [propertyId, setPropertyId] = useState("");
  const [customer, setCustomer] = useState(null);
  const [properties, setProperties] = useState([]);
  const [displayEditCustomerModal, setDisplayEditCustomerModal] =
    useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [showCreateRecurring, setShowCreateRecurring] = useState(false);
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [displayAddAddressModal, setDisplayAddAddressModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [requests, setRequests] = useState([]);
  const [emails, setEmails] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getCustomerProperties();
  }, [id]);

  useEffect(() => {
    getRecurringServices();
    getRequests();
    getEmails();
    getJobs();
    getInvoicesForCustomer();
  }, [id]);

  const getEmails = () => {
    const data = {
      customerId: id,
    };
    Email()
      .getJson("getEmailsForCustomer", data)
      .then((response) => {
        setEmails(response.data.emails);
      });
  };

  const getJobs = () => {
    const data = {
      customerId: id,
    };
    Vendor()
      .getJson("getAllJobsForCustomer", data)
      .then((response) => {
        if (response?.response?.status === 200) {
          setJobs(response.data.jobs);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  const getRecurringServices = () => {
    const data = {
      customerId: id,
    };
    Service()
      .getJson("getCustomerRecurringServices", data)
      .then((response) => {
        setServices(response.data.recurringServices);
      });
  };

  const getRequests = () => {
    const data = {
      customerId: id,
    };
    Request()
      .getJson("getRequests", data)
      .then((response) => {
        setRequests(response.data.requests);
      });
  };

  const getCustomerProperties = () => {
    const data = {
      id,
    };
    Customer()
      .getJson("getCustomerProperties", data)
      .then((response) => {
        setCustomer(response.data.customer);
        setProperties(response.data.properties);
        setPropertyId(response.data.properties[0]?._id || "");
      });
  };

  const getInvoicesForCustomer = () => {
    const data = {
      customerId: id,
    };
    Invoice()
      .getJson("getInvoicesForCustomer", data)
      .then((response) => {
        if (response.response.status === 200) {
          setInvoices(response.data.invoices);
        }
      });
  };

  const goToProperty = (propertyId) => {
    navigate(`/address/${propertyId}`);
  };

  const handleUpdate = () => {
    getCustomerProperties();
  };

  const formatPhoneNumber = (value) => {
    if (!value) {
      return;
    }
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const formatAddress = (fullAddress) => {
    if (!fullAddress) {
      return "";
    }
    const parts = fullAddress.split(",");
    const formattedParts = parts
      .slice(0, parts.length - 2)
      .concat(parts[parts.length - 2].split(" ").slice(0, -1))
      .join(",")
      .replace(/,+/g, ",")
      .replace(/,(\S)/g, ", $1");

    return formattedParts.trim();
  };

  const goToAddress = () => {
    if (propertyId) {
      goToProperty(propertyId);
    }
  };

  const goToCustomerJobs = () => {
    navigate(`/customers/${id}/jobs`);
  };

  const closeEditCustomerModal = () => {
    setDisplayEditCustomerModal(false);
  };

  const openEditCustomerModal = () => {
    setDisplayEditCustomerModal(true);
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const handleOpenCreateRecurringModal = () => {
    setShowCreateRecurring(true);
  };

  const handleCloseCreateRecurringModal = () => {
    setShowCreateRecurring(false);
  };

  const handleCheckboxChange = (service) => {
    if (service.recurring?.isRecurring) {
      setSelectedService(service);
      setShowCancelRecurring(true);
    } else {
      setSelectedService(service);
      setShowRecurringServiceModal(true);
    }
  };

  const handleOpenCreateJobModal = () => {
    setShowCreateJobModal(true);
  };

  const handleCloseCreateJobModal = () => {
    setShowCreateJobModal(false);
  };

  const determineEmailTitle = (email) => {
    if (email.quoteEmail) {
      return "Sent Quotes";
    }
    if (email.emailTitle) {
      return email.emailTitle;
    } else {
      return "No title found";
    }
  };

  const closeAddAddressModal = () => {
    setDisplayAddAddressModal(false);
  };

  const handleAddAddress = () => {
    const isNewProperty = !properties.some(
      (property) => property._id === selectedPropertyId
    );

    if (isNewProperty) {
      const data = {
        customerId: customer._id,
        propertyId: selectedPropertyId,
      };
      Customer()
        .postJson("addPropertyToCustomer", data)
        .then((response) => {
          if (response.response.status === 200) {
            getCustomerProperties();
          }
        });
      const newProperty = {
        _id: selectedPropertyId,
        address: {
          readableAddress: selectedProperty.address.readableAddress,
        },
      };
      setProperties([
        ...properties,
        {
          _id: selectedPropertyId,
          address: {
            readableAddress: selectedProperty.address.readableAddress,
          },
        },
      ]);

      setPropertyId(formatAddress(newProperty.address.readableAddress));
    } else {
      console.log("Property already registered to this customer");
    }
  };

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const goToInvoice = (invoiceId) => {
    navigate(`/invoice/${invoiceId}`);
  };

  return (
    <div>
      <VendorNavbar />
      <div style={{ marginLeft: "50px", marginRight: "50px" }}>
        <RecurringServiceModal
          open={showRecurringServiceModal}
          onClose={handleCloseRecurringModal}
          service={selectedService}
          handleUpdate={getRecurringServices}
          propertyId={propertyId}
          customerId={customer?._id}
        />
        <CancelRecurringModal
          open={showCancelRecurring}
          onClose={handleCloseCancelRecurringModal}
          service={selectedService}
          handleUpdate={getRecurringServices}
        />
        <CreateRecurringServiceModal
          open={showCreateRecurring}
          onClose={handleCloseCreateRecurringModal}
          handleUpdate={getRecurringServices}
          customerId={customer?._id}
          properties={properties}
          customer={customer}
        />
        <CreateJobModal
          open={showCreateJobModal}
          onClose={handleCloseCreateJobModal}
          handleUpdate={getRecurringServices}
          customer={customer}
          properties={properties}
        />
        <AddAddressModal
          open={displayAddAddressModal}
          onClose={closeAddAddressModal}
          setSelectedPropertyId={setSelectedPropertyId}
          handleAddAddress={handleAddAddress}
          setSelectedProperty={setSelectedProperty}
          // handleUpdate={handleUpdate}
        />
        {customer ? (
          <div style={{ display: "flex" }}>
            {/* Left Column */}

            <div style={{ width: "80%", marginRight: "25px" }}>
              <EditCustomerModal
                open={displayEditCustomerModal}
                setPropertyId={setPropertyId}
                onClose={closeEditCustomerModal}
                handleUpdateCustomer={getCustomerProperties}
                customer={customer}
                properties={properties}
              />
              <div style={{ position: "relative" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                ></Box>
                {customer.isCommercial && (
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{ marginBottom: "24px", userSelect: "none" }}
                  >
                    {customer.commercialAccountName}
                  </Typography>
                )}

                <div
                  style={{
                    marginTop: "30px",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign={"left"}
                    marginBottom={"30px"}
                  >
                    <Grid item>
                      <CardHeader>
                        {properties?.length > 1 ? "Properties" : "Property"}
                      </CardHeader>
                    </Grid>
                    <Grid item>
                      <GreenButton
                        onClick={() => setDisplayAddAddressModal(true)}
                        sx={{ marginLeft: "10px" }}
                        variant="contained"
                        color="primary"
                      >
                        Add Address
                      </GreenButton>
                    </Grid>
                  </Grid>



                  <Grid item xs={12}>
                    {properties?.length > 1 ? (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Select
                            value={propertyId}
                            onChange={(event) => {
                              setPropertyId(event.target.value);
                            }}
                            sx={{
                              minWidth: "450px",
                              fontSize: 20,
                              borderRadius: "10px",
                            }}
                          >
                            {properties?.map((property) => (
                              <MenuItem
                                key={property._id}
                                value={property._id}
                                sx={{ fontSize: 20 }}
                              >
                                {formatAddress(
                                  property?.address?.readableAddress
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item>
                          <GreenButton
                            onClick={goToAddress}
                            sx={{ marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Go to Address
                          </GreenButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Typography sx={{ fontSize: 20 }}>
                            {formatAddress(
                              properties[0]?.address.readableAddress
                            )}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <GreenButton
                            onClick={goToAddress}
                            sx={{ marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Go to Address
                          </GreenButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>


                  
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign={"left"}
                    marginBottom={"30px"}
                  >
                    <Grid item>
                      <CardHeader>Recurring Services</CardHeader>
                    </Grid>
                    <Grid item>
                      <GreenButton
                        onClick={handleOpenCreateRecurringModal}
                        variant="contained"
                        color="primary"
                      >
                        Add Recurring Service
                      </GreenButton>
                    </Grid>
                  </Grid>

                  {services && services.length > 0 && (
                    <TableContainer component={Card}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <TableHeader>Service</TableHeader>
                            </TableCell>
                            <TableCell>
                              <TableHeader>Address</TableHeader>
                            </TableCell>
                            <TableCell>
                              <TableHeader>Price</TableHeader>
                            </TableCell>
                            <TableCell>
                              <TableHeader>Recurring</TableHeader>
                            </TableCell>

                            <TableCell>
                              <TableHeader>Last Performed</TableHeader>
                            </TableCell>
                            <TableCell>
                              <TableHeader>Next Suggested</TableHeader>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {services?.map((service, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service.quoteTitle}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service?.readableAddress
                                    ? formatAddress(service.readableAddress)
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  ${service.quotePrice.toFixed(2)}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Grid
                                  container
                                  alignItems="center"
                                  textAlign={"left"}
                                >
                                  <Grid item sx={{ marginLeft: "-10px" }}>
                                    <CustomCheckbox
                                      checked={!!service.recurring?.isRecurring}
                                      onChange={() =>
                                        handleCheckboxChange(service)
                                      }
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography sx={{ fontSize: "18px" }}>
                                      {service.recurring?.recurringFrequency
                                        ? `${service.recurring.recurringFrequency} ${service.recurring.recurringUnit}`
                                        : "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service.recurring?.lastPerformed
                                    ? formatDate(
                                        service.recurring.lastPerformed
                                      )
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {service.recurring?.recommendedNext
                                    ? formatDate(
                                        service.recurring.recommendedNext
                                      )
                                    : "-"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "1px solid #ccc",
                  }}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign={"left"}
                    marginBottom={"30px"}
                  >
                    <Grid item>
                      <CardHeader>Jobs</CardHeader>
                    </Grid>
                    <Grid item>
                      <GreenButton
                        onClick={handleOpenCreateJobModal}
                        variant="contained"
                        color="primary"
                      >
                        Create Job
                      </GreenButton>
                    </Grid>
                  </Grid>

                  <TableContainer component={Card}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableHeader>Scheduled</TableHeader>
                          </TableCell>
                          <TableCell>
                            <TableHeader>Amount</TableHeader>
                          </TableCell>
                          <TableCell>
                            <TableHeader>Job Status</TableHeader>
                          </TableCell>
                          <TableCell>
                            <TableHeader>Payment</TableHeader>
                          </TableCell>
                          <TableCell>
                            <TableHeader>Address</TableHeader>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobs &&
                          jobs.length > 0 &&
                          jobs?.map((job, index) => (
                            <ClickableTableRow
                              key={index}
                              onClick={() => goToJob(job._id)}
                            >
                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job?.scheduledDate ? formatDateTime(job.scheduledDate) : `Not Scheduled`}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job?.totalPrice ? `$ ${job?.totalPrice}` : null}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job?.jobStatus ? formatStatus(job?.jobStatus) : null}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job?.invoice?.paymentStatus ? formatStatus(job?.invoice?.paymentStatus) : `-`}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography sx={{ fontSize: "18px" }}>
                                  {job?.address?.readableAddress ? formatAddress(job.address.readableAddress) : null}
                                </Typography>
                              </TableCell>

                            </ClickableTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div style={{ width: "20%" }}>
              <GreenButton
                onClick={openEditCustomerModal}
                variant="contained"
                color="primary"
              >
                Edit Customer
              </GreenButton>
              <div
                style={{
                  marginTop: "20px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <CardContent>
                  <CardHeader>Customer Info</CardHeader>
                  <Typography variant="h5">
                    {customer?.firstName} {customer?.lastName}
                  </Typography>
                  <Typography>{customer?.email}</Typography>
                  <Typography>
                    {formatPhoneNumber(customer?.phoneNumber)}
                  </Typography>
                  <Typography>Lead Source: {customer?.leadSource}</Typography>
                </CardContent>
              </div>

              <div
                style={{
                  marginTop: "20px",
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <CardContent>
                  <CardHeader>Billing</CardHeader>
                  {/* <Typography>Balance</Typography> */}
                  <Typography sx={{marginTop: "-20px", fontSize: "20px"}}>Invoices</Typography>
                  {invoices.map((invoice) => {
                    return (
                      <Typography onClick={() => goToInvoice(invoice._id)}>
                        <div onClick={() => goToInvoice(invoice._id)} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>#{invoice.invoiceNumber}</div>
                      </Typography>
                    );
                  })}
                </CardContent>
              </div>
              <Paper
      variant="outlined"
      style={{
        padding: "10px",
        marginTop: "30px",
        height: "250px",
        textAlign: "left",
        borderRadius: "10px",
      }}
    >
      {customer?.notes ? (
        <Typography variant="body1">{customer.notes}</Typography>
      ) : (
        <Typography
          variant="body1"
          style={{ color: "black" }}
        >
          Customer Notes...
        </Typography>
      )}
    </Paper>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: "300px" }}>
            <LoadingIcon />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomerDesktop;
