import React from "react";
import { Service } from "../utils/httpUtils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

const CancelRecurringModal = ({
  open,
  onClose,
  service,
  handleUpdate,
}) => {
  const handleConfirm = () => {
    const data = {
      serviceId: service._id
    };

    Service().postJson('cancelRecurringService', data)
    .then(response => {
      handleUpdate();
      onClose();
    })
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
          Confirm Cancellation
        </Typography>
        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            style={{ margin: "5px" }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CancelRecurringModal;
