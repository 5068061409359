import React, { useState, useContext, useEffect } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import VendorContext from "../../../context/VendorContext";
import { Vendor } from "../../../utils/httpUtils";
import UploadLogoModal from "../../../modals/UploadLogoModal";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { useNavigate } from "react-router-dom";
import VendorAddressAutocomplete from "../../../components/commonComponents/VendorAddressAutocomplete";
import { GreenButton } from "../../../components/reusableComponents/Buttons";

const EditProfileMobile = () => {
  const navigate = useNavigate();
  const { vendor, setVendor } = useContext(VendorContext);
  const [companyName, setCompanyName] = useState(vendor?.companyName || "");
  const [website, setWebsite] = useState(vendor?.website || "");
  const [phoneNumber, setPhoneNumber] = useState(vendor?.phoneNumber || "");
  const [email, setEmail] = useState(vendor?.email || "");
  const [logo, setLogo] = useState(vendor?.logo || "");
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [reviewLink, setReviewLink] = useState(vendor?.reviewLink || "");

  useEffect(() => {
    if (vendor) {
      setCompanyName(vendor.companyName);
      setWebsite(vendor.website);
      setPhoneNumber(vendor.phoneNumber);
      setEmail(vendor.email);
      setLogo(vendor.logo);
      setReviewLink(vendor.reviewLink);
    }
  }, [vendor]);

  useEffect(() => {
    if (!vendor) {
      getVendor();
    }
  });

  const getVendor = async () => {
    try {
      const response = await Vendor().getJson("getVendor");
      setVendor(response.data);
    } catch (error) {
      console.error("Error fetching vendor data", error);
    }
  };

  const handleUpdateProfile = async () => {
    let data = {
      companyName,
      website,
      email,
      phoneNumber,
      reviewLink,
    };
    if (selectedAddress) {
      data = {
        ...data,
        physicalLocation: {
          address: {
            street: {
              number: selectedAddress.street?.number || "",
              street: selectedAddress.street?.street || "",
            },
            city: selectedAddress.city || "",
            state: selectedAddress.state || "",
            readableAddress: selectedAddress.readableAddress || "",
          },
          coordinates: {
            coordinates: selectedAddress.coordinates,
          },
        },
      };
    }

    Vendor()
      .putJson("updateVendor", data)
      .then((response) => {
        if (response.data.token) {
          document.cookie = `token=${response.data.token}; path=/; secure; SameSite=Lax;`;
        }
        setVendor(response.data.vendor);
        navigate("/account/profile");
      });
  };

  const openLogoModal = () => {
    setShowUploadLogoModal(true);
  };

  const closeLogoModal = () => {
    setShowUploadLogoModal(false);
  };

  return (
    <div style={{ paddingBottom: "5px" }}>
      <VendorNavbar />
      <UploadLogoModal
        open={showUploadLogoModal}
        onClose={closeLogoModal}
        currentLogo={logo}
        updateVendor={getVendor}
      />
      {/* <Container
        sx={{
          paddingHorizontal: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      > */}
        <Box
          mb={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {logo ? (
            <>
            <img src={logo} alt="Vendor Logo" style={{ maxWidth: 300 }} />

          <GreenButton variant="contained" onClick={openLogoModal}>
          Change Logo
        </GreenButton>
        </>
          ) : (
            <GreenButton
              variant="contained"
              onClick={openLogoModal}
              sx={{ marginTop: 2 }}
            >
              Upload Logo
            </GreenButton>
          )}

        </Box>

        {vendor ? (
          <>
            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: 0 }}
              >
                Website:
              </Typography>
              <TextField
                variant="outlined"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                sx={{ width: "80%", marginBottom: 2 }}
              />
            </Box>

            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: 0 }}
              >
                Phone:
              </Typography>
              <TextField
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{ width: "40%", marginBottom: 2 }}
              />
            </Box>

            <Box
              mb={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", marginBottom: 0 }}
              >
                Physical Address:
              </Typography>
                <div style={{width: "80%", margin: "auto"}}>
                <VendorAddressAutocomplete
                  onSelectAddress={setSelectedAddress}
                />
                </div>
            </Box>
            </>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}

        <GreenButton
          variant="contained"
          onClick={handleUpdateProfile}
          sx={{ marginTop: 2 }}
        >
          Update Info
        </GreenButton>
    </div>
  );
};

export default EditProfileMobile;
