import React, { useState, useEffect, useContext } from "react";
import { Container, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../utils/httpUtils";
import UserContext from "../../context/UserContext";
import VendorContext from "../../context/VendorContext";
import CommonNavbar from "../../components/commonComponents/CommonNavbar";
import ForgotPasswordModal from "../../modals/ForgotPasswordModal";
import { CustomTextField } from "../../components/reusableComponents/Inputs";
import {
  GreenButton,
  SolidGreenButton,
} from "../../components/reusableComponents/Buttons";

// const commonTextStyle = {
//   fontFamily: "Roboto, sans-serif",
//   fontWeight: 400,
//   letterSpacing: ".1rem",
//   color: "#333",
// };

// const boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
// const border = "1px solid #ddd";

export default function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLoggingIn, setErrorLoggingIn] = useState(null);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { setVendor } = useContext(VendorContext);
  const [plaidJwt, setPlaidJwt] = useState(
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1]
  );

  useEffect(() => {
    evaluateAndRedirect();
  }, [plaidJwt]);

  const evaluateAndRedirect = async () => {
    const isAdmin =
      localStorage.getItem("userRole") === "PLAIDMIN" ? true : false;
    if (plaidJwt) {
      if (isAdmin) {
        navigate("/admin");
      } else {
        navigate("/dashboard");
      }
    }
  };

  const handleLogin = () => {
    const data = {
      email,
      password,
    };

    Auth()
      .postJson("login", data)
      .then((response) => {
        if (response.data?.token) {
          localStorage.setItem("userRole", response.data.user.role);
          document.cookie = `token=${response.data.token}; path=/; secure; SameSite=Lax;`;
          setUser(response.data.user);
          setVendor(response.data.vendor);
          setPlaidJwt(response.data.token);
        } else {
          setErrorLoggingIn(true);
        }
      })
      .catch((err) => setErrorLoggingIn(true));
  };

  const goToCreateAccount = () => {
    navigate("/register");
  };

  const handleOpenForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  return (
    <div>
      <CommonNavbar />
      <ForgotPasswordModal
        open={showForgotPasswordModal}
        onClose={handleCloseForgotPasswordModal}
      />
      <div className={"registerContainer"}>
        <Container
          sx={{
            paddingTop: 5,
            paddingHorizontal: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mb={3}>
            <img
              src={"assets/logo.jpeg"}
              alt="Logo"
              style={{ width: 300, height: 300, marginBottom: 30 }}
            />
          </Box>
          <Box mb={3}>
            <Typography variant="h1" sx={{ fontSize: 70 }}>
              Plaid
            </Typography>
            <Typography variant="h5" sx={{ fontSize: 20 }}>
              Welcome to Plaid
            </Typography>
          </Box>
          {/* <Button onClick={handleOpenForgotPasswordModal} onClose={handleCloseForgotPasswordModal}>Forgot Password</Button> */}
          <form>
            <CustomTextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "40%", marginBottom: 2, marginRight: "20px" }}
              autoCompleteType="email"
              autoCapitalize="none"
              keyboardType="email-address"
            />
            <CustomTextField
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ width: "40%", marginBottom: 2 }}
              autoCompleteType="password"
              textContentType="password"
              type="password"
              autoCapitalize="none"
            />
          </form>
          {errorLoggingIn && (
            <Typography color="error">There was an error logging in</Typography>
          )}
          <GreenButton
            variant="contained"
            onClick={handleLogin}
            sx={{ marginBottom: 2 }}
          >
            Login
          </GreenButton>
          <SolidGreenButton variant="outlined" onClick={goToCreateAccount}>
            Create Account
          </SolidGreenButton>
        </Container>
      </div>
    </div>
  );
}
//testing pipeline
