import React, { useEffect, useState } from "react";
import { Container, Typography, Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import CommonNavbar from "../../components/commonComponents/CommonNavbar";
import { Link } from "react-router-dom";
import { Job } from "../../utils/httpUtils";
import useWindowDimensions from "../../hooks/useWindowDemsions";
import PaymentLarge from "../../components/clientComponents/PaymentLarge";
import PaymentMedium from "../../components/clientComponents/PaymentMedium";
import PaymentSmall from "../../components/clientComponents/PaymentSmall";

function Payment() {
  const { id } = useParams();
  const [job, setJob] = useState({});
  const { height, width } = useWindowDimensions();
  const [size, setSize] = useState("");

  useEffect(() => {
    if (width < 500) {
      setSize("Small");
    } else if (width > 501 && width < 800) {
      setSize("Medium");
    } else if (width > 801) {
      setSize("Large");
    }
  });

  useEffect(() => {
    if (id) {
      const data = {
        jobId: id,
      };
      Job()
        .getJson("getJob", data)
        .then((response) => setJob(response.data));
    }
  }, [id]);

  function renderPaymentComponent() {
    if (size === "Small") {
      return <PaymentSmall job={job} />;
    }
    if (size === "Medium") {
      return <PaymentSmall job={job} />;
    }
    if (size === "Large") {
      return <PaymentSmall job={job} />;
    }
  }

  return <>{renderPaymentComponent()}</>;
}

export default Payment;
