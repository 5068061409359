import React from "react";
import Autocomplete from "../components/commonComponents/AutoComplete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";

const AddAddressModal = ({
  open,
  onClose,
  setSelectedPropertyId,
  setSelectedProperty,
  handleAddAddress,
  handleUpdate
}) => {
  const addAddress = () => {
    handleAddAddress();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ minWidth: "800px" }}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
          Add Address to Customer
        </Typography>
        <Grid>
          <Grid item xs={12} sx={{ minWidth: "500px" }}>
            <Autocomplete
              setSelectedPropertyId={setSelectedPropertyId}
              setSelectedProperty={setSelectedProperty}
            />
          </Grid>
        </Grid>
        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
          <GreenButton
            variant="contained"
            color="primary"
            onClick={addAddress}
            style={{ margin: "5px" }}
          >
            Add Address
          </GreenButton>
          <RedButton
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </RedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddAddressModal;
