import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Utils, Property } from "../../../utils/httpUtils";

const AutocompleteMobile = ({setSelectedPropertyId, setSelectedProperty}) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [completeResponses, setCompleteResponses] = useState();

  useEffect(() => {
    if (inputValue.length >= 3) {
      const data = {
        inputValue
      };
      Utils()
        .postJson("autocomplete", data)
        .then((response) => {
          setCompleteResponses(response.data);
          const arr = response.data?.map((prediction) => {
            return prediction.Text;
          });
          setSuggestions(arr);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleOptionChange = (event, value) => {
    if (value) {
      const selectedCompleteResponse = completeResponses.find(
        (response) => response.Text === value
      );

      if (selectedCompleteResponse) {
        getPlaceByPlaceId(selectedCompleteResponse.PlaceId);
      }
    }
  };

  const getPlaceByPlaceId = (id) => {
    const data = {
      placeId: id,
    };
    Utils()
      .postJson("getPlaceById", data)
      .then((response) => {
        postAddress(response.data.Place);
      });
  };

  const buildAddress = (location) => {
    const address = {
      number: location.AddressNumber,
      apt: "",
      street: location.Street,
      city: location.Municipality,
      state: location.Region,
      country: location.Country,
      timezone: location.Timezone,
      coordinates: location.Geometry.Point,
      readableAddress: location.Label,
    };
    return address;
  };

  // const goToAddress = (propertyId) => {
  //   navigate(`/address/${propertyId}`);
  // };

  const postAddress = (location) => {
    const address = buildAddress(location);
    if (address) {
      Property()
        .postJson("createProperty", address)
        .then((response) => {
          setSelectedPropertyId(response.data._id)
          setSelectedProperty(response.data)
        });
    } else {
      console.log("error building address");
    }
  };

  return (
    <div style={{textAlign: "center"}}>
        <Autocomplete
          options={suggestions}
          getOptionLabel={(option) => option}
          onInputChange={handleInputChange}
          onChange={handleOptionChange}
          sx={{ justifyContent: "center"}}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Address"
              variant="outlined"
            //   fullWidth
            sx={{width: "70vw"}}
              InputProps={{
                ...params.InputProps,
                autoComplete: "off",
              }}
            />
          )}
        />

    </div>
  );
};

export default AutocompleteMobile;
