import React from "react";
import { Box, Button } from "@mui/material";

function BottomNavbar({ onSchedule, onComplete, onPayment, onCancel }) {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#fff",
        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        justifyContent: "space-around",
        // padding: "10px",
        paddingBottom: "5px"
      }}
    >
      <Button variant="contained" color="primary" onClick={onSchedule} style={{borderRadius: "10px"}}>
        Schedule
      </Button>
      <Button variant="contained" color="primary" onClick={onComplete} style={{borderRadius: "10px"}}>
        Completed
      </Button>
      <Button variant="contained" color="primary" onClick={onPayment} style={{borderRadius: "10px"}}>
        Payment
      </Button>
    </Box>
  );
}

export default BottomNavbar;
