import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../../components/reusableComponents/Buttons";
import AutocompleteMobile from "../../components/commonComponents/mobile/AutocompleteMobile";

const AddAddressModalMobile = ({
  open,
  onClose,
  setSelectedPropertyId,
  setSelectedProperty,
  handleAddAddress,
  handleUpdate
}) => {
  const addAddress = () => {
    handleAddAddress();
    onClose();
  };

  return (
    // <Dialog open={open} onClose={onClose} sx={{ minWidth: "800px" }}>
    <Dialog open={open} onClose={onClose} sx={{width: "100vw", margin: "auto"}}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
          Enter Address
        </Typography>
        <Grid>
          <Grid item xs={12}>
            <AutocompleteMobile
              setSelectedPropertyId={setSelectedPropertyId}
              setSelectedProperty={setSelectedProperty}
            />
          </Grid>
        </Grid>
        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
        <RedButton
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </RedButton>
          <GreenButton
            variant="contained"
            color="primary"
            onClick={addAddress}
            style={{ margin: "5px" }}
          >
            Submit
          </GreenButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddAddressModalMobile;
