import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { GreenButton } from "../components/reusableComponents/Buttons";

export default function QuoteDescriptionModal({ open, onClose, quote }) {
  return (
    <Dialog open={open} onClose={onClose} sx={{ textAlign: "center" }}>
      <DialogContent
        sx={{
          maxWidth: 400,
          height: "auto",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="div" sx={{ marginBottom: "10px" }}>
          {quote.title}
        </Typography>
        <Typography variant="h8" component="div" sx={{ marginBottom: "10px" }}>
          {quote.description}
        </Typography>
        <Typography variant="h6" component="div" sx={{ marginBottom: "-10px" }}>
          ${quote.price}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <GreenButton onClick={onClose} variant="contained" color="primary">
          Close
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
}
