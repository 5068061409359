import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

const GreenButton = ({ children, onClick, disabled, sx }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: "white",
        border: `1px solid #ccc`,
        // border: `1px solid ${theme.palette.greenButton.main}`,
        fontFamily: theme.palette.greenButton.fontFamily,
        fontWeight: theme.palette.greenButton.fontWeight,
        fontSize: theme.palette.greenButton.fontSize,
        color: theme.palette.greenButton.main,
        borderRadius: "8px",
        // Conditional styles for disabled state
        ...(disabled && {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[600],
          border: `1px solid ${theme.palette.grey[400]}`,
          pointerEvents: "none",
        }),
        "&:hover": {
          backgroundColor: disabled
            ? theme.palette.grey[300]
            : theme.palette.greenButton.main,
          color: disabled ? theme.palette.grey[600] : "white",
          border: `1px solid ${theme.palette.greenButton.main}`,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

const SolidGreenButton = ({ children, onClick, disabled, sx }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: theme.palette.greenButton.main,
        border: `1px solid ${theme.palette.greenButton.main}`,
        // border: `1px solid ${theme.palette.greenButton.main}`,
        fontFamily: theme.palette.greenButton.fontFamily,
        fontWeight: theme.palette.greenButton.fontWeight,
        fontSize: theme.palette.greenButton.fontSize,
        color: "white",
        borderRadius: "8px",
        // Conditional styles for disabled state
        ...(disabled && {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[600],
          border: `1px solid ${theme.palette.grey[400]}`,
          pointerEvents: "none",
        }),
        "&:hover": {
          backgroundColor: disabled
            ? theme.palette.grey[300]
            : theme.palette.greenButton.dark,
          color: disabled ? theme.palette.grey[600] : "white",
          border: `1px solid ${theme.palette.greenButton.main}`,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

const RedButton = ({ children, onClick, disabled, sx }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        // backgroundColor: theme.palette.red.main,
        backgroundColor: theme.palette.primary.main,
        fontFamily: theme.palette.greenButton.fontFamily,
        fontWeight: theme.palette.greenButton.fontWeight,
        fontSize: theme.palette.greenButton.fontSize,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
          border: `1px solid ${theme.palette.primary.dark}`,
        },
        borderRadius: "8px",
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
export { GreenButton, RedButton, SolidGreenButton };
