import React, { useState } from "react";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";

function Account() {

  const [isLoading] = useState(false);

  // useEffect(() => {
  //   getPropertyData();
  // }, [pid]);

  // useEffect(() => {
  //   getVendor();
  // }, []);

  // useEffect(() => {
  //   if (property) {
  //     setIsLoading(false);
  //   }
  // }, [property]);

  // const getPropertyData = () => {
  //   const data = {
  //     propertyId: pid,
  //   };
  //   Property()
  //     .getJson("getPropertyForVendor", data)
  //     .then((response) => {
  //       setProperty(response.data.property);
  //       setQuotes(response.data.quotes);
  //       setJobs(response.data.jobs);
  //     });
  // };

  // const getVendor = () => {
  //   Vendor()
  //     .getJson("getVendor")
  //     .then((response) => {
  //       setVendor(response.data);
  //       setSavedQuotes(response.data.savedQuotes);
  //       setQuoteCategories(response.data.quoteCategories);
  //       setQuoteSets(response.data.quoteSet);
  //     });
  // };

  // const updateProperty = () => {
  //   console.log("update property in Address.jsx")
  //   getPropertyData();
  // };

  // const updateVendor = () => {
  //   getVendor()
  // }

  return (
    <div>
      <VendorNavbar />
      <div id="accountPage">
        {isLoading ? (
          <div style={{ marginTop: "10px", marginBottom: "45px" }}>
            <LoadingIcon />
          </div>
        ) : (
          <Typography
            variant="h4"
            component="div"
            sx={{ marginBottom: 5, marginTop: 3 }}
          >
            Manage Account
          </Typography>
        )}
        <Grid container columnSpacing={4}>
          <Grid item xs={4}>
            <Grid container direction="column">
              <Grid item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2} direction="column">
            <Grid item>
              </Grid>
              <Grid item>
              </Grid>
              <Grid item>
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Account;
