import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { Vendor } from "../../utils/httpUtils";
import LoadingIcon from "../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  styled,
  CardContent,
} from "@mui/material";

const ExistingEmails = ({ automatedEmails, editEmailModal }) => {
  const [addresses, setAddresses] = useState();
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  const formatAddress = (address) => {

    if (typeof address !== "string") {
      return "";
    }

    const splitAddress = address.split(",");
    const splitZip = splitAddress[2].split("-");
    return `${splitAddress[0]}, ${splitAddress[1]}, ${splitZip[0]}`;
  };

  const handleSearchChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    if (!addresses || addresses?.length === 0) {
      return;
    }

    const filtered = addresses.filter((address) =>
      (address.address.number + " " + address.address.street)
        .toLowerCase()
        .includes(searchText)
    );
    setSearchText(searchText);
    setFilteredAddresses(filtered);
  };

  const goToEmail = (email) => {
    editEmailModal(email)
  };

  return (
    <Card style={{ width: "100%", border: "2px solid #ccc" }}>
      <CardContent>
        <Typography variant="h4" component="div" sx={{ marginBottom: 1, userSelect: "none" }}>
          Automated Emails
        </Typography>
        {/* <Typography variant="h7" component="div" sx={{ marginBottom: 3, userSelect: "none" }}>
          Click to View Email Settings
        </Typography> */}

        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell>Loading...</TableCell>
                </TableRow>
              ) : automatedEmails?.length > 0 ? (
                automatedEmails.map((email) => (
                  <ClickableTableRow key={email._id} onClick={() => goToEmail(email)}>
                    <TableCell sx={{ fontSize: "20px" }}>
                      {email.emailTitle}
                    </TableCell>
                  </ClickableTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No automated emails found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ExistingEmails;