import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/system";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Card,
  CardContent,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import VendorContext from "../context/VendorContext";
import { Vendor, Coupon } from "../utils/httpUtils";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";
import {
  CustomTextField,
  NumberTextField,
} from "../components/reusableComponents/Inputs";

const CouponModal = ({
  open,
  onClose,
  handleUpdateCoupons,
  selectedCoupon,
  setSelectedCoupon,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [minServices, setMinServices] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountUnit, setDiscountUnit] = useState("");
  const [duration, setDuration] = useState("");
  const [durationUnit, setDurationUnit] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [editMode, setEditMode] = useState();
  const [minPurchase, setMinPurchase] = useState("");

  const { vendor, setVendor } = useContext(VendorContext);

  const theme = useTheme();

  const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.green.main,
    },
  });

  useEffect(() => {
    setTitle("");
    setDescription("");
    setMinServices(0);
    setDiscountUnit("");
    setDiscountAmount("");
    setDurationUnit("");
    setDuration("");
    setMinPurchase("");
    if (selectedCoupon) {
      setEditMode(true);
      getCoupon();
    } else {
      setEditMode(false);
    }
  }, [open, selectedCoupon]);

  useEffect(() => {
    if (!vendor) {
      getVendor();
    }
  }, []);

  const getCoupon = () => {
    const data = {
      couponId: selectedCoupon,
    };
    Coupon()
      .getJson("getCoupon", data)
      .then((response) => {
        setTitle(response.data.title);
        setDescription(response.data.description);
        setMinServices(response.data.minServices);
        setMinPurchase(response.data.minPurchase);
        setDiscountUnit(response.data.discountUnit);
        setDiscountAmount(response.data.discountAmount);
        setDurationUnit(response.data.durationUnit);
        setDuration(response.data.duration);
      });
  };

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
      });
  };

  const handleDiscountUnitChange = (event, newDiscountUnit) => {
    if (newDiscountUnit !== "%" && newDiscountUnit !== "$") {
      setDiscountAmount("");
    }
    setDiscountUnit(newDiscountUnit);
  };

  const handleDurationUnitChange = (event, newDurationUnit) => {
    if (newDurationUnit !== "DAYS" && newDurationUnit !== "WEEKS") {
      setDuration("");
    }
    setDurationUnit(newDurationUnit);
  };

  const handleSubmit = () => {
    let data = {
      title,
      description,
      minServices,
      minPurchase,
      discountAmount,
      discountUnit,
      duration,
      durationUnit,
      expirationDate,
    };

    if (editMode) {
      data = {
        ...data,
        couponId: selectedCoupon,
      };
      Coupon()
        .putJson("updateCoupon", data)
        .then((response) => {
          if (response.response.status === 200) {
            handleUpdateCoupons();
            onClose();
          }
        });
    } else {
      Coupon()
        .postJson("createCoupon", data)
        .then((response) => {
          if (response.response.status === 200) {
            handleUpdateCoupons();
            onClose();
          }
        });
    }
  };

  const handleClose = () => {
    setSelectedCoupon(null);
    handleUpdateCoupons();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Card
          style={{
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <CardContent>
            <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
              {editMode ? "Edit Coupon" : "Create Coupon"}
            </Typography>

            <CustomTextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <CustomTextField
              label="Description"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              Minimum number of services needed to be booked for customer to
              redeem coupon
            </Typography>
            <CustomTextField
              label="Min Services"
              variant="outlined"
              type="number"
              fullWidth
              value={minServices}
              onChange={(e) => setMinServices(e.target.value)}
              sx={{ marginBottom: 2, width: "22%" }}
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              Minimum purchase for customer to redeem coupon
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <CustomTextField
                label="Purchase"
                variant="outlined"
                type="number"
                fullWidth
                value={minPurchase}
                onChange={(e) => {
                  const numericInput = e.target.value.replace(/[^0-9.]/g, "");
                  const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(
                    numericInput
                  );
                  if (isValidInput) {
                    setMinPurchase(numericInput);
                  }
                }}
                sx={{ width: "22%" }}
              />
              <div style={{ width: "40px" }}>
                <IconButton>
                  <AttachMoneyIcon />
                </IconButton>
              </div>
            </div>

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              Discount applies to entire ticket (10% off coupon will be applied
              to the entire ticket total)
            </Typography>

            <div
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={discountUnit}
                exclusive
                onChange={handleDiscountUnitChange}
              >
                <StyledToggleButton value="%" aria-label="percentage">
                  Percent (%)
                </StyledToggleButton>
                <StyledToggleButton value="$" aria-label="dollar">
                  Dollars ($)
                </StyledToggleButton>
              </ToggleButtonGroup>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <CustomTextField
                label="Discount"
                variant="outlined"
                type="number"
                fullWidth
                value={discountAmount}
                disabled={
                  discountUnit !== "$" && discountUnit !== "%" ? true : false
                }
                onChange={(e) => {
                  const numericInput = e.target.value.replace(/[^0-9.]/g, "");
                  const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(
                    numericInput
                  );
                  if (isValidInput) {
                    setDiscountAmount(numericInput);
                  }
                }}
                sx={{ width: "22%" }}
              />
              <div style={{ width: "40px" }}>
                {discountUnit === "%" && (
                  <IconButton>
                    <PercentIcon />
                  </IconButton>
                )}
                {discountUnit === "$" && (
                  <IconButton>
                    <AttachMoneyIcon />
                  </IconButton>
                )}
              </div>
            </div>

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              Duration specifies the timeframe customers have to use the coupon,
              starting from the date quotes are sent to them. If left blank,
              this coupon will have no expiration
            </Typography>

            <div
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={durationUnit}
                exclusive
                onChange={handleDurationUnitChange}
              >
                <StyledToggleButton value="DAYS">Days</StyledToggleButton>
                <StyledToggleButton value="WEEKS">Weeks</StyledToggleButton>
              </ToggleButtonGroup>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <CustomTextField
                label="Duration"
                variant="outlined"
                fullWidth
                type="number"
                value={duration}
                disabled={durationUnit !== "DAYS" && durationUnit !== "WEEKS"}
                onChange={(e) => {
                  const numericInput = e.target.value.replace(/[^0-9.]/g, "");
                  const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(
                    numericInput
                  );
                  if (isValidInput) {
                    setDuration(numericInput);
                  }
                }}
                sx={{ width: "22%" }}
              />
              <div style={{ width: "40px" }}>
                {durationUnit === "DAYS" && <IconButton>Days</IconButton>}
                {durationUnit === "WEEKS" && <IconButton>Weeks</IconButton>}
              </div>
            </div>
            <GreenButton
              onClick={handleSubmit}
              disabled={
                discountAmount === "" || title === "" || description === ""
              }
            >
              Submit
            </GreenButton>
            <RedButton sx={{ marginLeft: "10px" }} onClick={handleClose}>
              Cancel
            </RedButton>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default CouponModal;
