import React, { useState } from "react";
import { Auth } from "../utils/httpUtils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
} from "@mui/material";

const ForgotPasswordModal = ({ open, onClose }) => {
  const [email, setEmail] = useState("");

  const handleConfirm = () => {
    const data = {
      email,
    };

    Auth()
      .postJson("forgotPassword", data)
      .then((response) => {
        // onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
          Reset Password
        </Typography>

        <TextField
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          fullWidth
          autoComplete="off"
          sx={{ marginBottom: "10px" }}
        />

        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            style={{ margin: "5px" }}
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordModal;
