import React, { useState } from "react";
import { GreenButton } from "../reusableComponents/Buttons";
import {
  Typography,
  TextField,
  List,
  ListItem,
  Grid,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { Vendor } from "../../utils/httpUtils";
import CustomSwitch from "../reusableComponents/Customswitch";

function LeadSourceSettings({ settings, updateSettings }) {
  const [newLeadSource, setNewLeadSource] = useState("");

  const handleAddLeadSource = () => {
    const updatedLeadSources = [
      ...settings.settings.leadSourceOptions,
      newLeadSource,
    ];
    const data = {
      includeLeadSource: settings.settings.includeLeadSource,
      leadSourceOptions: updatedLeadSources,
    };

    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      })
      .catch((error) => {
        console.error("Error updating lead source:", error);
      });

    setNewLeadSource("");
  };

  const updateIncludeLeadSource = () => {
    const data = {
      includeLeadSource: !settings.settings.includeLeadSource,
    };
    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      });
  };

  const handleDeleteLeadSource = (indexToRemove) => {
    const updatedLeadSources = settings.settings.leadSourceOptions.filter(
      (_, index) => index !== indexToRemove
    );
    const data = {
      includeLeadSource: settings.settings.includeLeadSource,
      leadSourceOptions: updatedLeadSources,
    };

    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      })
      .catch((error) => {
        console.error("Error updating lead source:", error);
      });
  };

  return (
    <div id="vendorDashboard">
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "50px",
        }}
      >
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "20px" }}
            >
              Include "Lead Source" question in customer intake
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <CustomSwitch
              checked={settings?.settings?.includeLeadSource}
              onChange={() => updateIncludeLeadSource()}
            />
          </Grid>

          {/* Second Row */}
          <Grid
            item
            container
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <TextField
              label="Add Lead Source"
              variant="outlined"
              value={newLeadSource}
              style={{ marginRight: "10px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
              onChange={(e) => setNewLeadSource(e.target.value)}
            />
            <GreenButton
              variant="contained"
              onClick={handleAddLeadSource}
              disabled={newLeadSource.length < 1}
              sx={{
                height: "56px",
                width: "90px",
              }}
            >
              Add
            </GreenButton>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            {settings?.settings?.leadSourceOptions && (
              <List dense>
                {settings.settings.leadSourceOptions.map((option, index) => (
                  <ListItem
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ListItemIcon>
                      <IconButton
                        edge="start"
                        aria-label="delete"
                        onClick={() => handleDeleteLeadSource(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemIcon>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{
                        userSelect: "none",
                        fontSize: "20px",
                        color: settings.settings.includeLeadSource
                          ? "black"
                          : "gray",
                        marginLeft: "8px",
                      }}
                    >
                      {option}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default LeadSourceSettings;
