import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import AnalyticsComponent from "../../../components/vendorComponents/AnalyticsComponent";
import UpcomingJobs from "../../../components/vendorComponents/UpcomingJobs";
import RequestedJobs from "../../../components/vendorComponents/RequestedJobs";
import { Vendor, Auth, Job } from "../../../utils/httpUtils";
import UserContext from "../../../context/UserContext";
import VendorContext from "../../../context/VendorContext";
import RecurringJobs from "../../../components/vendorComponents/RecurringJobs";
import UpcomingJobsDesktop from "../../../components/vendorComponents/desktop/UpcomingJobsDesktop";
import RequestedJobsDesktop from "../../../components/vendorComponents/desktop/RequestedJobsDesktop";
import RecurringJobsDesktop from "../../../components/vendorComponents/desktop/RecurringJobsDesktop";

function VendorDashboardDesktop() {
  const [jobs, setJobs] = useState();
  const { user, setUser } = useContext(UserContext);
  const { vendor, setVendor } = useContext(VendorContext);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (!vendor) {
      getVendor();
    }
  }, [vendor]);

  useEffect(() => {
    getAllJobsForVendor();
  }, []);

  const getUser = () => {
    Auth()
      .getJson("getUser")
      .then((response) => {
        if (response?.response?.status === 200) {
          setUser(response.data.data);
        } else {
          setError("error communicating with server");
        }
      });
  };

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        if (response?.response?.status === 200) {
          setVendor(response.data);
        } else {
          setError("error communicating with server");
        }
      });
  };

  const getAllJobsForVendor = () => {
    Job()
      .getJson("getAllJobsForVendor")
      .then((response) => {
        if (response?.response?.status === 200) {
          setJobs(response.data.jobs);
        } else {
          setError("error communicating with server");
        }
      });
  };

  return (
    <div>
      <VendorNavbar />
      <div id="vendorDashboard">
        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Grid container direction="column">
              <Grid item>
                <AnalyticsComponent jobs={jobs} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <UpcomingJobsDesktop jobs={jobs} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "0px" }}>
                <RequestedJobsDesktop />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "0px" }}>
                <RecurringJobsDesktop />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default VendorDashboardDesktop;
