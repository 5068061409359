import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Utils } from "../../utils/httpUtils";
import { CustomTextField } from "../reusableComponents/Inputs";

const VendorAddressAutocomplete = ({ onSelectAddress, selectedAddress, error }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [completeResponses, setCompleteResponses] = useState();


  useEffect(() => {
    if (inputValue && inputValue.length >= 3) {
      const data = {
        inputValue,
      };
      Utils()
        .postJson("autocompleteUnauth", data)
        .then((response) => {
          if (response.data?.length > 0) {
            setCompleteResponses(response.data);
            const arr = response.data?.map((prediction) => {
              return prediction.Text;
            });
            setSuggestions(arr);
          }
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  const handleOptionChange = (event, value) => {
    if (value) {
      const selectedCompleteResponse = completeResponses.find(
        (response) => response.Text === value
      );

      if (selectedCompleteResponse) {
        getPlaceByPlaceId(selectedCompleteResponse.PlaceId);
      }
    }
  };

  const handleFilterOptions = (options, inputValue) => {
    const normalizedInputValue = inputValue.replace(/,/g, '').toLowerCase();
  
    return options.filter((option) => {
      const normalizedOption = option.replace(/,/g, '').toLowerCase();
      return normalizedOption.includes(normalizedInputValue);
    });
  };
  

  const getPlaceByPlaceId = (id) => {
    const data = {
      placeId: id,
    };
    Utils()
      .postJson("getPlaceById", data)
      .then((response) => {
        const fullAddress = buildAddress(response.data.Place);
        onSelectAddress(fullAddress);
      });
  };

  const buildAddress = (location) => {
    const address = {
      number: location.AddressNumber,
      apt: "",
      street: location.Street,
      city: location.Municipality,
      state: location.Region,
      country: location.Country,
      timezone: location.Timezone,
      coordinates: location.Geometry.Point,
      readableAddress: location.Label,
    };
    return address;
  };

  return (
    <Autocomplete
      options={suggestions}
      getOptionLabel={(option) => {
        return option
      }}
      filterOptions={(options, { inputValue }) =>
      handleFilterOptions(options, inputValue)
    }
      onInputChange={handleInputChange}
      onChange={(event, value) => handleOptionChange(event, value)}
      renderInput={(params) => (
        <TextField
        error={error}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
          },
        }}
          {...params}
          label="Location"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
          }}
        />
        )}
        />
        );
};

export default VendorAddressAutocomplete;
