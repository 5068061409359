import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/system";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import { GreenButton, RedButton } from "../components/reusableComponents/Buttons";

const ConfirmBulkEditModal = ({
  open,
  onClose,
  quoteCategories,
  updateQuotes,
  exampleRow,
  edits,
}) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(open);
  const [updatedQuote, setUpdatedQuote] = useState();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    buildNewQuote();
  }, [edits]);

  const buildNewQuote = () => {
    const newQuote = {
      quoteCategory: edits?.quoteCategory
        ? edits?.quoteCategory
        : exampleRow?.quoteCategory,
      title: edits?.title ? edits.title : exampleRow?.title,
      description: edits?.description
        ? edits.description
        : exampleRow?.description,
      price: edits?.price ? calculatePrice() : exampleRow?.price,
    };
    setUpdatedQuote(newQuote);
  };

  const calculatePrice = () => {
    let newPrice = parseFloat(exampleRow.price);
    if (edits.adjustmentType === "+") {
      if (edits.amountType === "%") {
        newPrice += (newPrice * parseFloat(edits.price)) / 100;
      } else if (edits.amountType === "$") {
        newPrice += parseFloat(edits.price);
      }
    } else if (edits.adjustmentType === "-") {
      if (edits.amountType === "%") {
        newPrice -= (newPrice * parseFloat(edits.price)) / 100;
      } else if (edits.amountType === "$") {
        newPrice -= parseFloat(edits.price);
      }
    }
    newPrice = parseFloat(newPrice.toFixed(2));
    return newPrice;
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ width: "100%" }}
      maxWidth="xl"
    >
      <DialogContent>
        {/* <Card
          style={{
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <CardContent> */}
            <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
              Confirm Changes
            </Typography>
            <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
              Below, you will see one of your selected quotes, and what it will
              look like after your proposed edits. Please confirm changes before
              submitting, these can't be undone!
            </Typography>
            <TableContainer
              component={Paper}
              sx={{ width: "auto", margin: "auto", marginTop: "40px", marginBottom: "20px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      sx={{ fontSize: 18, color: theme.palette.primary.main, fontWeight: "700" }}
                    >
                      Quote Category
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 18, color: theme.palette.primary.main, fontWeight: "700" }}
                    >
                      Quote Title
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 18, color: theme.palette.primary.main, fontWeight: "700" }}
                    >
                      Quote Description
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: 18, color: theme.palette.primary.main, fontWeight: "700" }}
                    >
                      Quote Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={exampleRow?.quoteCategory}>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>Existing Quote:</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>{exampleRow?.quoteCategory}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>{exampleRow?.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>{exampleRow?.description}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>${exampleRow?.price}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow key={1}>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>New Quote:</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>{updatedQuote?.quoteCategory}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>{updatedQuote?.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>{updatedQuote?.description}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{fontSize: "20px"}}>${updatedQuote?.price}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <GreenButton
              variant="contained"
              color="primary"
              onClick={updateQuotes}
            >
              Submit Changes
            </GreenButton>
            <RedButton
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{ marginLeft: "10px" }}
            >
              Cancel
            </RedButton>
          {/* </CardContent>
        </Card> */}
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmBulkEditModal;
