import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../commonComponents/LoadingIcon";
import QuoteCard from "../QuoteCard";
import { isValidEmail } from "../../../utils/validationUtils";
import { Quote, Coupon } from "../../../utils/httpUtils";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EditQuoteModal from "../../../modals/EditQuoteModal";
import { GreenButton } from "../../reusableComponents/Buttons";
import { CustomSelect, CustomTextField } from "../../reusableComponents/Inputs";
import { useNavigate } from "react-router-dom";
import TextMessageModalMobile from "../../../modals/mobile/TextMessageModalMobile";

import { isMobile } from "react-device-detect";

function CurrentQuotesAtAddressMobile(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [quotes, setQuotes] = useState(props.quotes);
  const [emailSuccessful, setEmailSuccessful] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [propertyId, setPropertyId] = useState(props.propertyId);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [contactInput, setContactInput] = useState("");
  const [contactMethod, setContactMethod] = useState("");
  const [requestLink, setRequestLink] = useState("");
  const [displayTextModal, setDisplayTextModal] = useState(false);

 
  const handleSendSms = (quoteLink, phone) => {
    const message = `Thank you for requesting quotes from us. Click the link to view your quotes and request services. ${quoteLink}`;
    if (isMobile) {
      // const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const encodedPhoneNumber = encodeURIComponent(phone);
      const smsUrl = `sms:${encodedPhoneNumber}?body=${encodeURIComponent(message)}`;
      window.location.href = smsUrl;
    } else {
      alert("This feature is only available on mobile devices.");
    }
  };
  
 
 
  // const handleSendSms = (quoteLink) => {
  //   const message = `Thank you for requesting quotes from us. Click the link to view your quotes and request services. ${quoteLink}`;
  //   if (isMobile) {
  //     const smsUrl = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
  //     window.location.href = smsUrl;
  //   } else {
  //     alert("This feature is only available on mobile devices.");
  //   }
  // };

  useEffect(() => {
    setQuotes(props.quotes);
    getCoupons();
  }, [props.quotes]);

  useEffect(() => {
    setPropertyId(props.propertyId);
  }, [props.propertyId]);

  useEffect(() => {
    if (quotes) {
      setIsLoading(false);
    }
  });

  const getCoupons = () => {
    Coupon()
      .getJson("getCoupons")
      .then((response) => {
        setCoupons(response.data.coupons);
      });
  };

  const handleToggleSelection = (quoteId) => {
    setSelectedQuotes((prevSelectedQuotes) => {
      if (prevSelectedQuotes.includes(quoteId)) {
        return prevSelectedQuotes.filter((id) => id !== quoteId);
      } else {
        return [...prevSelectedQuotes, quoteId];
      }
    });
  };

  const handleSelectCoupon = (couponId) => {
    if (couponId === "AddNew") {
      return;
    }
    setSelectedCoupons((prevSelectedCoupons) => [
      ...prevSelectedCoupons,
      couponId,
    ]);
  };

  const handleRemoveCoupon = (couponId) => {
    setSelectedCoupons((prevSelectedCoupons) =>
      prevSelectedCoupons.filter((id) => id !== couponId)
    );
  };

  const sendQuotes = () => {
    setEmailSuccessful(null);
    if (contactMethod === "email") {
      const data = {
        email: contactInput,
        quotes,
        propertyId: props.propertyId,
        requestedQuotes: selectedQuotes,
        coupons: selectedCoupons,
      };
      Quote()
        .postJson("emailQuotes", data)
        .then((response) => {
          if (response.response.status === 200) {
            setEmailSuccessful(true);
            props.getCustomersAtAddress();
          } else {
            setEmailSuccessful(false);
          }
        });
    }

    if (contactMethod === "phone") {
      setPhoneNumber(contactInput);
      const data = {
        propertyId: props.propertyId,
        phoneNumber: contactInput,
        requestedQuotes: selectedQuotes,
        coupons: selectedCoupons,
      };
      Quote()
        .postJson("textQuotes", data)
        .then((response) => {
          if (response.data.link) {
            setRequestLink(response.data.link);
            // setDisplayTextModal(true)
            handleSendSms(response.data.link, contactInput);
          }
        });
    }
  };

  const validateEmailOrPhoneNumber = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9]{10,}$/;

    if (emailRegex.test(input)) {
      return "email";
    } else if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "invalid";
    }
  };

  const handleSetEmailOrPhoneNumber = (input) => {
    setContactInput(input);

    const contactType = validateEmailOrPhoneNumber(input);

    if (contactType === "invalid") {
      setContactMethod("invalid");
      return;
    }
    if (contactType === "email") {
      setContactMethod("email");
    }
    if (contactType === "phone") {
      setContactMethod("phone");
    }

    setEmailSuccessful(false);
    setEmailError(false);
    setIsEmailValid(validateEmailOrPhoneNumber(input) === "email");
  };

  const handleOpenModal = (quote) => {
    setSelectedQuote(quote);
  };

  const handleCloseModal = () => {
    setSelectedQuote(null);
  };

  const deleteQuote = (quoteId) => {
    Quote()
      .deleteJson(`/${quoteId}`)
      .then((response) => {
        if (response.response.status === 200) {
          props.updateProperty();
        }
      });
  };

  const closeDisplayTextModal = () => {
    setDisplayTextModal(false);
  };

  return (
    <div
      style={{
        borderTop: "1px solid #ccc",
        borderBottom: "1px solid #ccc",
        paddingBottom: "20px",
      }}
    >
      <TextMessageModalMobile
        open={displayTextModal}
        onClose={closeDisplayTextModal}
        requestLink={requestLink}
      />
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            component="div"
            sx={{ marginBottom: 1, userSelect: "none", marginTop: "10px" }}
          >
            Existing Quotes
          </Typography>
          <Typography
            variant="h7"
            component="div"
            sx={{ marginBottom: 1, userSelect: "none" }}
          >
            Select Customer Requested Quotes
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ marginBottom: 3, userSelect: "none" }}
          >
            Note: All quotes are sent to customers; but these selected quotes
            will show more prominently.
          </Typography>
          <div
            style={{
              display: "flex",
              marginBottom: "8px",
            }}
          ></div>
          {isLoading ? (
            <LoadingIcon />
          ) : quotes?.length > 0 ? (
            quotes.map((quote) => (
              <div
                key={quote._id}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "16px",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "70%" }}>
                  <div
                    onClick={() => handleToggleSelection(quote._id)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <QuoteCard
                      quote={quote}
                      isSelected={selectedQuotes.includes(quote._id)}
                      handleToggleSelection={() =>
                        handleToggleSelection(quote._id)
                      }
                    />
                  </div>
                </div>
                <div>
                  <EditIcon
                    style={{
                      cursor: "pointer",
                      color: theme.palette.grey,
                      marginLeft: "10px",
                    }}
                    onClick={() => handleOpenModal(quote)}
                  />
                  <DeleteIcon
                    onClick={() => deleteQuote(quote._id)}
                    style={{
                      cursor: "pointer",
                      color: theme.palette.grey,
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            ))
          ) : null}
          <Typography
            variant="h5"
            component="div"
            sx={{ marginBottom: 1, userSelect: "none", marginTop: "30px" }}
          >
            Coupons
          </Typography>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomSelect
              value={""}
              variant="outlined"
              onAddNewClick={() => props.handleOpenCouponModal()}
              onChange={(e) => handleSelectCoupon(e.target.value)}
              options={coupons
                .filter((coupon) => !selectedCoupons.includes(coupon._id))
                .map((coupon) => ({
                  value: coupon._id,
                  label: coupon.title,
                }))}
              sx={{ width: "300px", marginBottom: "8px" }}
            />
            {selectedCoupons.length > 0 && (
              <Typography
                variant="body1"
                style={{ marginTop: "8px", userSelect: "none" }}
              >
                Selected Coupons:
              </Typography>
            )}
            {selectedCoupons?.map((couponId) => {
              const selectedCoupon = coupons.find(
                (coupon) => coupon._id === couponId
              );
              return (
                <div
                  key={couponId}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body2"
                    style={{ marginRight: "8px", userSelect: "none" }}
                  >
                    {selectedCoupon?.title}
                  </Typography>
                  <DeleteIcon
                    onClick={() => handleRemoveCoupon(couponId)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              );
            })}
            <CustomTextField
              label="Email/Phone Number"
              value={contactInput}
              onChange={(e) => handleSetEmailOrPhoneNumber(e.target.value)}
              sx={{
                width: "300px",
                marginBottom: "8px",
                marginTop: "40px",
              }}
            />
            {emailSuccessful && (
              <Typography
                variant="h5"
                color="green"
                style={{ marginBottom: "8px" }}
              >
                Email sent successfully
              </Typography>
            )}
            {emailError && (
              <Typography
                variant="h5"
                color="green"
                style={{ marginBottom: "8px" }}
              >
                Error sending email
              </Typography>
            )}
            <GreenButton
              onClick={sendQuotes}
              disabled={contactMethod !== "email" && contactMethod !== "phone"}
            >
              Send Quotes
            </GreenButton>
          </div>
        </Grid>
      </Grid>

      {selectedQuote && (
        <EditQuoteModal
          open={!!selectedQuote}
          onClose={handleCloseModal}
          quote={selectedQuote}
          updateProperty={props.updateProperty}
        />
      )}
    </div>
  );
}

export default CurrentQuotesAtAddressMobile;
