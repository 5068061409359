import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import NewCategoryModal from "../../../modals/NewCategoryModal";
import CreateQuoteSetModal from "../../../modals/CreateQuoteSetModal";
import { Quote } from "../../../utils/httpUtils";
import { GreenButton } from "../../reusableComponents/Buttons";
import {
  CustomSelect,
  CustomSelectWithLabel,
  CustomTextField,
} from "../../reusableComponents/Inputs";

const AddQuoteSetMobile = (props) => {
  const [quoteCategories, setQuoteCategories] = useState([]);
  const [quoteSets, setQuoteSets] = useState();
  const [selectedQuoteSet, setSelectedQuoteSet] = useState(null);
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const [displayCreateQuoteSetModal, setDisplayCreateQuoteSetModal] =
    useState(false);
  const [property, setProperty] = useState(props.property);

  useEffect(() => {
    setQuoteSets(props.quoteSets);
    setQuoteCategories(props.quoteCategories);
  }, [props.quoteSets, props.quoteCategories]);

  useEffect(() => {
    setProperty(props.property);
  }, [props.property]);

  const handleCategoryModalOpen = () => {
    setDisplayCategoryModal(true);
  };

  const handleCategoryModalClose = () => {
    setDisplayCategoryModal(false);
  };

  const handleQuoteSetModalOpen = () => {
    setDisplayCreateQuoteSetModal(true);
  };

  const handleQuoteSetModalClose = () => {
    setDisplayCreateQuoteSetModal(false);
  };

  const handleQuoteSetSelect = (selectedTitle) => {
    const selectedQuoteSet = quoteSets.find(
      (quoteSet) => quoteSet.quoteSetTitle === selectedTitle
    );
    setSelectedQuoteSet(selectedQuoteSet);
  };

  const handleQuoteChange = (index, field, value) => {
    setSelectedQuoteSet((prevQuoteSet) => {
      const updatedQuoteSet = { ...prevQuoteSet };

      if (!updatedQuoteSet.quoteSet) {
        updatedQuoteSet.quoteSet = [];
      }

      const updatedQuote = { ...(updatedQuoteSet.quoteSet[index] || {}) };

      if (field === "quoteCategory" && value === "AddNew") {
        updatedQuote[field] = "";
      } else {
        updatedQuote[field] = value;
      }

      updatedQuoteSet.quoteSet[index] = updatedQuote;

      return { ...updatedQuoteSet };
    });
  };

  function addQuoteSet() {
    const data = {
      quoteSet: selectedQuoteSet,
      propertyId: property._id,
    };
    Quote()
      .postJson("addQuoteSet", data)
      .then((response) => {
        if (response.error) {
          console.log("error: ", response.error);
        } else {
          props.updateProperty();
        }
      });
  }

  const isQuoteSetValid = () => {
    if (!selectedQuoteSet) return false;

    const quotes = selectedQuoteSet.quoteSet || [];
    for (const quote of quotes) {
      if (
        quote.quoteCategory === "" ||
        quote.quoteCategory === "AddNew" ||
        quote.quoteTitle === "" ||
        quote.quotePrice === "" ||
        quote.quoteDescription === ""
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Typography
          variant="h4"
          component="div"
          sx={{
            textAlign: "center",
            marginTop: 1,
            marginBottom: 2,
            userSelect: "none",
          }}
        >
          Add Quote Collection
        </Typography>
        <Box mb={2} textAlign="center">
          <Typography
            variant="body1"
            component="div"
            sx={{
              textAlign: "center",
              userSelect: "none",
            }}
          >
            Select Quote Collection
          </Typography>
          <CustomSelect
            value={selectedQuoteSet?.quoteSetTitle || ""}
            onChange={(e) => handleQuoteSetSelect(e.target.value)}
            options={quoteSets?.map((quoteSet) => ({
              label: quoteSet.quoteSetTitle,
              value: quoteSet.quoteSetTitle,
            }))}
            variant="outlined"
            fullWidth
            onAddNewClick={handleQuoteSetModalOpen}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {selectedQuoteSet
              ? selectedQuoteSet.quoteSet.map((quote, index) => (
                  <div
                    key={quote._id || index}
                    style={{
                      borderBottom: "1px solid black",
                      paddingBottom: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Grid container spacing={2} sx={{ marginTop: "20px" }}>
                      <Grid item xs={5}>
                        <CustomSelectWithLabel
                          label="Quote Category"
                          value={quote.quoteCategory}
                          onChange={(e) =>
                            handleQuoteChange(
                              index,
                              "quoteCategory",
                              e.target.value
                            )
                          }
                          options={
                            quoteCategories?.length > 0
                              ? quoteCategories.map((category) => ({
                                  label: category,
                                  value: category,
                                }))
                              : []
                          }
                          fullWidth
                          onAddNewClick={handleCategoryModalOpen}
                          sx={{ marginBottom: 2 }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomTextField
                          label="Title"
                          variant="outlined"
                          fullWidth
                          value={quote.quoteTitle}
                          onChange={(e) =>
                            handleQuoteChange(
                              index,
                              "quoteTitle",
                              e.target.value
                            )
                          }
                          sx={{ marginBottom: 2 }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CustomTextField
                          label="Price"
                          variant="outlined"
                          fullWidth
                          value={quote.quotePrice}
                          onChange={(e) =>
                            handleQuoteChange(
                              index,
                              "quotePrice",
                              e.target.value
                            )
                          }
                          sx={{ marginBottom: 2 }}
                        />
                      </Grid>
                    </Grid>
                    <CustomTextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      value={quote.quoteDescription}
                      onChange={(e) =>
                        handleQuoteChange(
                          index,
                          "quoteDescription",
                          e.target.value
                        )
                      }
                      sx={{ marginBottom: 2 }}
                    />
                  </div>
                ))
              : null}
          </Grid>
        </Grid>
        <div style={{ marginTop: "30px" }}>
          {selectedQuoteSet && (
            <GreenButton
              onClick={addQuoteSet}
              variant="contained"
              color="primary"
              disabled={!isQuoteSetValid()}
            >
              Add Collection
            </GreenButton>
          )}
        </div>
      </Grid>
      <NewCategoryModal
        open={displayCategoryModal}
        onClose={handleCategoryModalClose}
        updateVendor={props.updateVendor}
      />
      <CreateQuoteSetModal
        open={displayCreateQuoteSetModal}
        onClose={handleQuoteSetModalClose}
        updateVendor={props.updateVendor}
        displayCategoryModal={displayCategoryModal}
        openCategoryModal={handleCategoryModalOpen}
        closeCategoryModal={handleCategoryModalClose}
      />
    </Grid>
  );
};

export default AddQuoteSetMobile;
