import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CustomCheckbox from "../reusableComponents/Customcheckbox";

const QuoteCard = ({ quote, onSelect, selectedQuotes }) => {
  const isSelected = selectedQuotes.includes(quote._id);

  const handleSelect = () => {
    onSelect();
  };

  const handleCheckboxChange = (event) => {
    event.preventDefault();
  };

  const determinePrice = (quote) => {
    if (quote.couponPrice === quote.price || !quote.couponPrice) {
      return (
        <Typography variant="subtitle1" color="primary">
          <span style={{ fontSize: 20 }}>{`Price: $${quote.price}`}</span>
        </Typography>
      );
    } else {
      return (
        <div>
          <Typography variant="subtitle1">
            <span
              style={{ textDecoration: "line-through", fontSize: 20 }}
            >{`Price: $${quote?.price?.toFixed(2)}`}</span>
            <span
              style={{ fontWeight: 700, fontSize: 20, color: "green" }}
            >{` Discounted Price: $${quote?.couponPrice?.toFixed(2)}`}</span>
          </Typography>
        </div>
      );
    }
  };

  return (
    <Card
      className={`quoteCard ${isSelected ? "notSelected" : "notSelected"}`}
      onClick={handleSelect}
      style={{
        cursor: "pointer",
        userSelect: "none",
        position: "relative",
        borderRadius: "10px",
        border: "1px solid #ccc",
      }}
    >
      <CustomCheckbox
        checked={isSelected}
        onChange={handleCheckboxChange}
        inputProps={{ "aria-label": "Select Quote" }}
        style={{
          position: "absolute",
          left: "2%",
          top: "35%",
          transform: "translateY(-50%)",
          marginRight: "35px",
          transform: "scale(1.5)"
        }}
        // sx={{ transform: "scale(1.5)" }}
      />
      <CardContent style={{ textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          {quote.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          paragraph
          sx={{ maxWidth: "80%", margin: "auto", fontSize: "18px" }}
        >
          {quote.description}
        </Typography>
        <Typography variant="subtitle1" color="primary">
          {determinePrice(quote)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default QuoteCard;
