import React, { useContext, useEffect } from "react";
// import { Container, Typography, Button, Box } from "@mui/material";
import VendorContext from "../../context/VendorContext";
import { Vendor } from "../../utils/httpUtils";

// import { mapEnumToLabel } from "../../utils/utils";
// import UserContext from "../../context/UserContext";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { useNavigate } from 'react-router-dom';

const AccountHelp = () => {
  const navigate = useNavigate();
  const { vendor, setVendor } = useContext(VendorContext);

  useEffect(() => {
    if (!vendor) {
      fetchData();
    }    
  });

  const fetchData = async () => {
    try {
      const response = await Vendor().getJson("getVendor");
      setVendor(response.data);
    } catch (error) {
      console.error("Error fetching vendor data", error);
    }
  };

  return (
    <div>
      <VendorNavbar />
     <div>Account Help</div>
     <div>During beta-testing, if you run into any trouble, have any questions, comments, concerns, complaints, complements, or recommendations, please don't hesitate to reach out to us directly at (919) 579 - 2532 or email at kevin@plaidquotes.com</div>

    </div>
  );
};

export default AccountHelp;
