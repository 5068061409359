import React, { useState, useEffect } from "react";
import { Vendor } from "../../utils/httpUtils";
import useMediaQuery from "@mui/material/useMediaQuery";

// Desktop
import CustomersDesktop from "./desktop/CustomersDesktop";
// Mobile
import CustomersMobile from "./mobile/CustomersMobile";

function Customers() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [leadSources, setLeadSources] = useState([]);
  const [insights, setInsights] = useState(null);

  useEffect(() => {
    getCustomerInsights();
  }, []);

  const getCustomerInsights = () => {
    Vendor()
      .getJson("getCustomerInsights")
      .then((response) => {
        if (response.response.status === 200) {
          setInsights(response.data);
        }
      });
  };

  return (
    <>
      {isMobile ? (
        <CustomersMobile insights={insights} />
      ) : (
        <CustomersDesktop insights={insights} />
      )}
    </>
  );
}

export default Customers;
