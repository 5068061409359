import React, { useState, useEffect, useContext } from "react";
import { Container, Typography, Button, TextField, Box } from "@mui/material";
import { Vendor } from "../../utils/httpUtils";
import CommonNavbar from "../../components/commonComponents/CommonNavbar";
import { useNavigate } from "react-router-dom";
import VendorAddressAutoComplete from "../../components/commonComponents/VendorAddressAutocomplete";
import {
  isValidEmail,
  validateEmptyFields,
  validatePassword,
} from "../../utils/validationUtils";
import UserContext from "../../context/UserContext";
import VendorContext from "../../context/VendorContext";
import { CustomTextField } from "../../components/reusableComponents/Inputs";
import { GreenButton } from "../../components/reusableComponents/Buttons";

const Register = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSelected, setIsSelected] = useState([]);
  const [error, setError] = useState([]);
  const { setToken, setUser } = useContext(UserContext);
  const { setVendor } = useContext(VendorContext);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [locationError, setLocationError] = useState(false);
  const [plaidJwt, setPlaidJwt] = useState(
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("token="))
      ?.split("=")[1]
  );
  const [created, setCreated] = useState(false);

  useEffect(() => {
    if (created) {
      evaluateAndRedirect();
    }
  }, [created]);

  const evaluateAndRedirect = () => {
    navigate("/");
  };

  const register = async () => {
    const inputs = {
      firstName,
      lastName,
      companyName,
      website,
      phoneNumber,
      email,
      password,
      selectedAddress
    };
    const emptyFields = validateEmptyFields(inputs);

    if (emptyFields.length > 0) {
      if (emptyFields.includes("selectedAddress")) {
        setLocationError(true);
      }
      setIsSelected(emptyFields);
      setError(["Please fill in all fields."]);
      return;
    }

    if (!isValidEmail(email)) {
      setIsSelected(["email"]);
      setError(["Invalid email"]);
      return;
    }

    if (!validatePassword(password)) {
      setIsSelected(["password"]);
      setError(["Password must be at least 8 characters long."]);
      return;
    }

    if (!selectedAddress) {
      setLocationError(true);
      return;
    }
    const physicalLocation = {
      address: {
        street: {
          number: selectedAddress.number,
          street: selectedAddress.street,
          unit: "",
        },
        city: selectedAddress.city,
        state: selectedAddress.state,
        country: selectedAddress.country,
        readableAddress: selectedAddress.readableAddress,
      },
      coordinates: {
        coordinates: selectedAddress.coordinates,
      },
    };

    const data = {
      companyName,
      firstName,
      lastName,
      password,
      website,
      email,
      phoneNumber,
      physicalLocation,
    };

    Vendor()
      .postJson("createVendor", data)
      .then((response) => {
        if (response.response.data.errors) {
          handleError(response.response.data.errors);
          return;
        }
        if (response.data?.token) {
          setCreated(true);
          // document.cookie = `token=${response.data.token}; path=/; secure; SameSite=Lax;`;
          // setPlaidJwt(response.data.token);
          // setUser(response.data.user);
          // setVendor(response.data.vendor);
        }
      });
  };

  const handleError = (errors) => {
    const errorMessages = errors.map((error) => error.msg);
    const errorFields = errors.map((error) => error.field);
    setError(errorMessages);
    setIsSelected(errorFields);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  return (
    <div>
      <CommonNavbar />
      <div className={"createAccountContainer"}>
        <Container
          sx={{
            paddingTop: 5,
            paddingHorizontal: 16,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mb={3}>
            <img
              src={"assets/logo.jpeg"}
              alt="Logo"
              style={{ width: 300, height: 300 }}
            />
          </Box>

          {/* First Name */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("firstName")}
            />
          </Box>

          {/* Last Name */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("lastName")}
            />
          </Box>

          {/* Company Name */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Company Name"
              variant="outlined"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("companyName")}
            />
          </Box>

          {/* Website */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Website"
              variant="outlined"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              error={isSelected.includes("website")}
              sx={{ width: "40%" }}
            />
          </Box>

          {/* Phone Number */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Phone Number"
              variant="outlined"
              value={phoneNumber}
              onChange={(e) =>
                setPhoneNumber(formatPhoneNumber(e.target.value))
              }
              sx={{ width: "40%" }}
              error={isSelected.includes("phoneNumber")}
            />
          </Box>

          <Box mb={2} sx={{ width: "40%" }}>
            <VendorAddressAutoComplete
              onSelectAddress={setSelectedAddress}
              error={locationError}
            />
          </Box>

          {/* Email */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("email")}
            />
          </Box>

          {/* Password */}
          <Box mb={2} sx={{ width: "100%" }}>
            <CustomTextField
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ width: "40%" }}
              error={isSelected.includes("password")}
            />
          </Box>

          <GreenButton
            variant="contained"
            onClick={register}
            sx={{ marginBottom: 2 }}
          >
            Create Account
          </GreenButton>

          <Box sx={{ marginTop: 2 }}>
            {error.length > 0 &&
              error.map((err, index) => (
                <Typography key={index} color="error" sx={{ fontSize: 14 }}>
                  {err}
                </Typography>
              ))}
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default Register;
