import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
// Desktop
import VendorDashboardDesktop from "./desktop/VendorDashboardDesktop";

// Mobile
import VendorDashboardMobile from "./mobile/VendorDashboardMobile";

function VendorDashboard() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <>{isMobile ? <VendorDashboardMobile /> : <VendorDashboardDesktop />}</>
  );
}

export default VendorDashboard;
