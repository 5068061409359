import React, { useEffect, useState } from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { useParams } from "react-router-dom";
import {
  Customer,
  Service,
  Request,
  Email,
  Vendor,
  Invoice,
} from "../../../utils/httpUtils";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import EditCustomerModalMobile from "../../../modals/mobile/EditCustomerModalMobile";
import Box from "@mui/material/Box";
import RecurringServiceModal from "../../../modals/RecurringServiceModal";
import CancelRecurringModal from "../../../modals/CancelRecurringModal";
import CreateRecurringServiceModal from "../../../modals/CreateRecurringServiceModal";
import CreateJobModal from "../../../modals/CreateJobModal";
import {
  formatDate,
  formatDateTime,
  determineName,
  formatStatus,
  formatDateStrict,
} from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Grid,
  Select,
  Button,
  styled,
} from "@mui/material";
import { GreenButton } from "../../../components/reusableComponents/Buttons";
import CustomCheckbox from "../../../components/reusableComponents/Customcheckbox";

function CustomerMobile() {
  const { id } = useParams();
  const [propertyId, setPropertyId] = useState("");
  const [customer, setCustomer] = useState(null);
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayEditCustomerModal, setDisplayEditCustomerModal] =
    useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [showCreateRecurring, setShowCreateRecurring] = useState(false);
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [emails, setEmails] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getCustomerProperties();
  }, [id]);

  useEffect(() => {
    getRecurringServices();
    getRequests();
    getEmails();
    getJobs();
    getInvoicesForCustomer();
  }, [id]);

  useEffect(() => {
    if (customer) {
      setIsLoading(false);
    }
  }, [customer]);

  const getInvoicesForCustomer = () => {
    const data = {
      customerId: id,
    };
    Invoice()
      .getJson("getInvoicesForCustomer", data)
      .then((response) => {
        if (response.response.status === 200) {
          setInvoices(response.data.invoices);
        }
      });
  };

  const getEmails = () => {
    const data = {
      customerId: id,
    };
    Email()
      .getJson("getEmailsForCustomer", data)
      .then((response) => {
        setEmails(response.data.emails);
      });
  };

  const getJobs = () => {
    const data = {
      customerId: id,
    };
    Vendor()
      .getJson("getAllJobsForCustomer", data)
      .then((response) => {
        if (response?.response?.status === 200) {
          setJobs(response.data.jobs);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  const getRecurringServices = () => {
    const data = {
      customerId: id,
    };
    Service()
      .getJson("getCustomerRecurringServices", data)
      .then((response) => {
        setServices(response.data.recurringServices);
      });
  };

  const getRequests = () => {
    const data = {
      customerId: id,
    };
    Request()
      .getJson("getRequests", data)
      .then((response) => {
        setRequests(response.data.requests);
      });
  };

  const getCustomerProperties = () => {
    const data = {
      id,
    };
    Customer()
      .getJson("getCustomerProperties", data)
      .then((response) => {
        setCustomer(response.data.customer);
        setProperties(response.data.properties);
        setPropertyId(response.data.properties[0]?._id || "");
      });
  };

  const goToProperty = (propertyId) => {
    navigate(`/address/${propertyId}`);
  };

  const handleUpdate = () => {
    getCustomerProperties();
  };

  const formatPhoneNumber = (value) => {
    if (!value) {
      return;
    }
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const formatAddress = (fullAddress) => {
    if (!fullAddress) {
      return "";
    }
    const parts = fullAddress.split(",");
    const formattedParts = parts
      .slice(0, parts.length - 2)
      .concat(parts[parts.length - 2].split(" ").slice(0, -1))
      .join(",")
      .replace(/,+/g, ",")
      .replace(/,(\S)/g, ", $1");

    return formattedParts.trim();
  };

  const goToAddress = () => {
    if (propertyId) {
      goToProperty(propertyId);
    }
  };

  const goToCustomerJobs = () => {
    navigate(`/customers/${id}/jobs`);
  };

  const closeEditCustomerModal = () => {
    setDisplayEditCustomerModal(false);
  };

  const openEditCustomerModal = () => {
    setDisplayEditCustomerModal(true);
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const handleOpenCreateRecurringModal = () => {
    setShowCreateRecurring(true);
  };

  const handleCloseCreateRecurringModal = () => {
    setShowCreateRecurring(false);
  };

  const handleCheckboxChange = (service) => {
    if (service.recurring?.isRecurring) {
      setSelectedService(service);
      setShowCancelRecurring(true);
    } else {
      setSelectedService(service);
      setShowRecurringServiceModal(true);
    }
  };

  const handleOpenCreateJobModal = () => {
    setShowCreateJobModal(true);
  };

  const handleCloseCreateJobModal = () => {
    setShowCreateJobModal(false);
  };

  const determineEmailTitle = (email) => {
    if (email.quoteEmail) {
      return "Sent Quotes";
    }
    if (email.emailTitle) {
      return email.emailTitle;
    } else {
      return "No title found";
    }
  };

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const goToInvoice = (invoiceId) => {
    navigate(`/invoice/${invoiceId}`);
  };

  return (
    <div>
      <VendorNavbar />
      <RecurringServiceModal
        open={showRecurringServiceModal}
        onClose={handleCloseRecurringModal}
        service={selectedService}
        handleUpdate={getRecurringServices}
        propertyId={propertyId}
        customerId={customer?._id}
      />
      <CancelRecurringModal
        open={showCancelRecurring}
        onClose={handleCloseCancelRecurringModal}
        service={selectedService}
        handleUpdate={getRecurringServices}
      />
      <CreateRecurringServiceModal
        open={showCreateRecurring}
        onClose={handleCloseCreateRecurringModal}
        handleUpdate={getRecurringServices}
        customerId={customer?._id}
        properties={properties}
        customer={customer}
      />
      <CreateJobModal
        open={showCreateJobModal}
        onClose={handleCloseCreateJobModal}
        handleUpdate={getRecurringServices}
        customer={customer}
        properties={properties}
      />
      <EditCustomerModalMobile
        open={displayEditCustomerModal}
        setPropertyId={setPropertyId}
        onClose={closeEditCustomerModal}
        handleUpdateCustomer={getCustomerProperties}
        customer={customer}
        properties={properties}
      />

      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ padding: "5px", marginTop: "-50px" }}>
              <CardContent>
                <Grid container spacing={2}>
                  {/* Customer Information */}
                  <Grid item xs={8} sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: "18px" }}>
                      {determineName(customer)}
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {customer?.email?.length > 0
                        ? customer.email
                        : "No email"}
                    </Typography>
                    <Typography sx={{ fontSize: "18px" }}>
                      {customer.phoneNumber?.length > 0
                        ? formatPhoneNumber(customer?.phoneNumber)
                        : "No phone number"}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sm={4} sx={{ textAlign: "right" }}>
                    <GreenButton onClick={openEditCustomerModal}>
                      Edit
                    </GreenButton>
                  </Grid>

                  {/* Properties */}
                  <Grid item xs={12}>
                    {properties?.length > 1 ? (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={9} sm={9} sx={{ textAlign: "left" }}>
                          <Select
                            value={propertyId}
                            onChange={(event) => {
                              setPropertyId(event.target.value);
                            }}
                            sx={{
                              width: "100%",
                              fontSize: 18,
                              borderRadius: "10px",
                            }}
                          >
                            {properties?.map((property) => (
                              <MenuItem
                                key={property._id}
                                value={property._id}
                                sx={{ fontSize: 18 }}
                              >
                                {formatAddress(
                                  property?.address?.readableAddress
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <Grid item xs={3} sm={3} sx={{ textAlign: "right" }}>
                          <GreenButton
                            onClick={goToAddress}
                            variant="contained"
                            color="primary"
                          >
                            Go
                          </GreenButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={8} textAlign="left">
                          <Typography sx={{ fontSize: 18 }}>
                            {formatAddress(
                              properties[0]?.address.readableAddress
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign="right">
                          <GreenButton
                            onClick={goToAddress}
                            sx={{ marginLeft: "10px" }}
                            variant="contained"
                            color="primary"
                          >
                            Go
                          </GreenButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/* Jobs */}
            <Typography sx={{ fontSize: "24px", marginTop: "10px" }}>
              Jobs
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: "18px" }}>Scheduled</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Amount</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Job Status</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Payment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobs?.map((job, index) => (
                    <ClickableTableRow
                      key={index}
                      onClick={() => goToJob(job._id)}
                    >
                      <TableCell sx={{ fontSize: "18px" }}>
                        {job?.scheduledDate
                          ? formatDateTime(job.scheduledDate)
                          : `Not Scheduled`}
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px" }}>
                        {job?.totalPrice ? `$ ${job?.totalPrice}` : null}
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px" }}>
                        {job?.jobStatus ? formatStatus(job?.jobStatus) : null}
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px" }}>
                        {job?.invoice?.paymentStatus
                          ? formatStatus(job?.invoice?.paymentStatus)
                          : `-`}
                      </TableCell>
                    </ClickableTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Invoices */}
            <Typography sx={{ fontSize: "24px", marginTop: "10px" }}>
              Invoices
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: "18px" }}>Number</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Date</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Amount</TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoices?.map((invoice, index) => (
                    <ClickableTableRow
                      key={index}
                      onClick={() => goToInvoice(invoice._id)}
                    >
                      <TableCell sx={{ fontSize: "18px" }}>
                        {invoice.invoiceNumber}
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px" }}>
                        {invoice.dateCompleted
                          ? formatDateStrict(invoice.dateCompleted)
                          : "-"}
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px" }}>
                        {invoice.totalPrice ? `$ ${invoice.totalPrice}` : "-"}
                      </TableCell>
                      <TableCell sx={{ fontSize: "18px" }}>
                        {invoice.paymentStatus
                          ? formatStatus(invoice.paymentStatus)
                          : "-"}
                      </TableCell>
                    </ClickableTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default CustomerMobile;
