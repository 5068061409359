import React, { useState, useEffect } from "react";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import { Vendor } from "../../utils/httpUtils";
import EditPresets from "../../components/vendorComponents/EditPresets";
import {
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableBody,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function ManageAccount() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(false);
  const [vendor, setVendor] = useState();

  useEffect(() => {
    getVendor();
  }, []);

  const getVendor = () => {
    setIsLoading(true);
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
        setIsLoading(false);
      });
  };

  const updateVendor = () => {
    getVendor();
  };

  return (
    <div>
      <VendorNavbar />
      <div id="accountPage" style={{userSelect: "none"}}>
        {isLoading ? (
          <div style={{ marginTop: "10px", marginBottom: "45px" }}>
            <LoadingIcon />
          </div>
        ) : (
          <Typography
            variant="h4"
            component="div"
            sx={{ marginBottom: 5, marginTop: 3 }}
          >
            Manage Account
          </Typography>
        )}

        {isMobile ? (
          // Mobile View
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5" component="div">
                          Account Status
                        </Typography>
                        <Typography variant="h5" component="div">
                          {vendor?.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5" component="div">
                          Current Billing Cycle
                        </Typography>
                        <Typography variant="h5" component="div">
                          N/A
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5" component="div">
                          Next Payment Due
                        </Typography>
                        <Typography variant="h5" component="div">
                          N/A
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5" component="div">
                          AutoPay
                        </Typography>
                        <Typography variant="h5" component="div">
                          N/A
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          // Desktop View
          <div style={{}}>
          <TableContainer>
            <Grid container columnSpacing={0}>
              <Grid item xs={3}>
                <Grid
                  container
                  direction="column"
                  sx={{ textAlign: "left", marginLeft: "80px" }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            Account Status:
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            Current Billing Cycle:
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            Next Payment Due:
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            AutoPay:
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Grid container direction="column" sx={{ textAlign: "left" }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            {vendor?.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            N/A
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            N/A
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h5" component="div">
                            N/A
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          </TableContainer>
          {/* <EditPresets /> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageAccount;
