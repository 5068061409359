import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const pages = [];

function CommonNavbar() {
  const [setAnchorElNav] = React.useState(null);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Plaid text always visible */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: "flex",
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              fontSize: "24px",
            }}
          >
            Plaid
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {/* Login link always visible */}
          <Button
            onClick={handleCloseNavMenu}
            sx={{
              my: 2,
              color: "white",
              display: { xs: "block", md: "block" },
            }}
          >
            <Link
              to={`/${pages[0]}`}
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              {pages[0]}
            </Link>
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default CommonNavbar;
