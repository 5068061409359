import React from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { mapJobStatus } from "../../utils/utils";

const commonTextStyle = {
  fontFamily: "Roboto, sans-serif",
  fontWeight: 400,
  letterSpacing: ".1rem",
  color: "#333",
};

const boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
const border = "1px solid #ddd";

const jobStatuses = ["Requested", "Scheduled", "Completed"];

const JobLarge = ({ job, vendor }) => {
  const isStepComplete = (step) => {
    return jobStatuses.indexOf(mapJobStatus(job?.jobStatus)) >= step;
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // const determineSalePrice = (price) => {
  //   console.log("price: ", price)
  //   if (job.coupon.discountUnit === "$") {
  //     return price -= job.coupon.discountAmount;
  //   } else if (job.coupon.discountUnit === "%") {
  //     const discountAmount = (job.coupon.discountAmount / 100) * price;
  //     return price -= discountAmount;
  //   }
  // }

  return (
    <div className={"quotesLarge"}>
      <Container
        sx={{
          bgcolor: "#e0eae659",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          boxShadow,
          border,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Grid container spacing={2}>
          {/* Vendor Info */}
          {vendor && (
            <Grid
              item
              xs={12}
              sx={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
                <Grid
                  container
                  direction={isSmallScreen ? "column-reverse" : "row"}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    item
                    className={"vendorInfoLogo"}
                    order={isSmallScreen ? 2 : 1}
                  >
                    <img
                      src={vendor.logo}
                      className={"vendorLogo"}
                      alt="Vendor Logo"
                    />
                  </Grid>
                  <Grid
                    item
                    className={"vendorInfo"}
                    order={isSmallScreen ? 1 : 2}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        ...commonTextStyle,
                        fontSize: `clamp(14px, 3vw, 18px)`,
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {vendor.companyName}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        ...commonTextStyle,
                        fontSize: `clamp(14px, 3vw, 18px)`,
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {vendor.email}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        ...commonTextStyle,
                        fontSize: `clamp(14px, 3vw, 18px)`,
                        textAlign: "center",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {vendor.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* Job Info */}
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
              {/* Progress Tracking Bar */}
              <Stepper alternativeLabel>
                {jobStatuses.map((label, index) => (
                  <Step key={index} completed={isStepComplete(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {/* Job Info Content */}
              <Typography
                variant="body1"
                sx={{ ...commonTextStyle, fontSize: 18, mb: 2 }}
              >
                Address: {job?.address?.readableAddress}
              </Typography>
              <Typography
                variant="body1"
                sx={{ ...commonTextStyle, fontSize: 18, mb: 2 }}
              >
                Job Status: {mapJobStatus(job?.jobStatus)}
              </Typography>

              {job?.jobStatus != "REQUESTED" ? (
                <Typography
                  variant="body1"
                  sx={{ ...commonTextStyle, fontSize: 18, mb: 2 }}
                >
                  Scheduled for: {job?.scheduledDate}
                </Typography>
              ) : null}

              <Typography
                variant="body1"
                sx={{
                  ...commonTextStyle,
                  fontSize: 18,
                  mb: 2,
                  borderBottom: "1px solid black",
                  mx: "auto",
                  pb: 1,
                  textAlign: "center",
                }}
              >
                Included Services:
              </Typography>

              {job?.services.map((service, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  sx={{ ...commonTextStyle, fontSize: 18, mb: 2 }}
                >
                  {`${
                    service?.title
                  }: $${service?.priceAtTimeOfBooking?.toFixed(2)}`}
                </Typography>
              ))}

              {job?.tax && job?.tax !== 0 && (
                <Typography
                  variant="body1"
                  sx={{ ...commonTextStyle, fontSize: 18, mb: 2 }}
                >
                  {`Taxes: $${vendor?.taxRate.toFixed(2)}`}
                </Typography>
              )}

              <Typography
                variant="body1"
                sx={{ ...commonTextStyle, fontSize: 18, mb: 6 }}
              >
                {`Total due: $${job?.totalPrice.toFixed(2)}`}
              </Typography>
              {job?.jobStatus === "COMPLETED" && (
                <Typography
                  variant="body1"
                  sx={{ ...commonTextStyle, fontSize: 18, mb: 2 }}
                >
                  Your job is complete! Thank you so much for booking services
                  with us. If you're happy with the result, please leave us a
                  review at:
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default JobLarge;
