const mapEnumToLabel = (enumValue) => {
  switch (enumValue) {
    case "INTERIOR_SERVICES":
      return "Interior Services";
    case "EXTERIOR_SERVICES":
      return "Exterior Services";
    case "ELECTRICAL":
      return "Electrical";
    case "PLUMBING":
      return "Plumbing";
    case "HVAC":
      return "HVAC";
    case "PEST_CONTROL":
      return "Pest Control";
    case "PRESSURE_WASHING":
      return "Pressure Washing";
    case "HANDYMAN":
      return "Handyman";
    case "INTERIOR_CLEANING":
      return "Interior Cleaning";
    case "CARPET_CLEANING":
      return "Carpet Cleaning";
    case "LAWN_MAINTENANCE":
      return "Lawn Maintenance";
    case "WINDOW_CLEANING":
      return "Window Cleaning";
    case "GUTTER_CLEANING":
      return "Gutter Cleaning";
    case "POOL_MAINTENANCE":
      return "Pool Maintenance";
    case "GARAGE_DOORS":
      return "Garage Door Service";
    case "FLOORING":
      return "Flooring";
    case "PAINTING_INTERIOR":
      return "Interior Painting";
    case "PAINTING_EXTERIOR":
      return "Exterior Painting";
    case "ENVIRONMENTAL":
      return "Environmental";
    default:
      return enumValue;
  }
};
exports.mapEnumToLabel = mapEnumToLabel;

const mapEnumToSpecialist = (enumValue) => {
  switch (enumValue) {
    case "INTERIOR_SERVICES":
      return "Interior Services";
    case "EXTERIOR_SERVICES":
      return "Exterior Services";
    case "ELECTRICAL":
      return "Electrician";
    case "PLUMBING":
      return "Plumber";
    case "HVAC":
      return "HVAC Specialist";
    case "PEST_CONTROL":
      return "Pest Control Specialist";
    case "PRESSURE_WASHING":
      return "Pressure Washer";
    case "HANDYMAN":
      return "Handyman";
    case "INTERIOR_CLEANING":
      return "Interior Cleaner";
    case "CARPET_CLEANING":
      return "Carpet Cleaner";
    case "LAWN_MAINTENANCE":
      return "Lawn Maintenance Specialist";
    case "WINDOW_CLEANING":
      return "Window Cleaner";
    case "GUTTER_CLEANING":
      return "Gutter Cleaner";
    case "POOL_MAINTENANCE":
      return "Pool Maintenance Specialist";
    case "GARAGE_DOORS":
      return "Garage Door Service Specialist";
    case "FLOORING":
      return "Flooring Specialist";
    case "PAINTING_INTERIOR":
      return "Interior Painter";
    case "PAINTING_EXTERIOR":
      return "Exterior Painter";
    case "ENVIRONMENTAL":
      return "Environmental Specialist";
    default:
      return enumValue;
  }
};
exports.mapEnumToSpecialist = mapEnumToSpecialist;

const mapJobStatus = (enumValue) => {
  switch (enumValue) {
    case "REQUESTED":
      return "Requested";
    case "ACCEPTED":
      return "Accepted";
    case "SCHEDULED":
      return "Scheduled";
    case "COMPLETED":
      return "Completed";
    case "CANCELLED":
      return "Cancelled";
    case "DUE":
      return "Due";
    default:
      return enumValue;
  }
};
exports.mapJobStatus = mapJobStatus;

const formatDateStrict = (dateString) => {
  const localDateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const localDate = new Date(dateString).toLocaleString(
    undefined,
    localDateOptions
  );
  return localDate;
};
exports.formatDateStrict = formatDateStrict;

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };

  if (date.toDateString() === today.toDateString()) {
    return `Today at ${date.toLocaleTimeString(undefined, timeOptions)}`;
  } else if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday at ${date.toLocaleTimeString(undefined, timeOptions)}`;
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return `Tomorrow at ${date.toLocaleTimeString(undefined, timeOptions)}`;
  } else {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const datePart = date.toLocaleDateString(undefined, options);
    const timePart = date.toLocaleTimeString(undefined, timeOptions);
    return `${datePart} at ${timePart}`;
  }
};
exports.formatDateTime = formatDateTime;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return "Tomorrow";
  } else {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }
};
exports.formatDate = formatDate;

const formatAddress = (address) => {
  if (typeof address !== "string") {
    return "";
  }

  const splitAddress = address.split(",");
  const splitZip = splitAddress[2].split("-");
  return `${splitAddress[0]}, ${splitAddress[1]}, ${splitZip[0]}`;
};
exports.formatAddress = formatAddress;

const formatAddressMobile = (address) => {
  if (typeof address !== "string") {
    return "";
  }

  const splitAddress = address.split(",");
  const splitZip = splitAddress[2].split("-");
  return `${splitAddress[0]}, ${splitAddress[1]}, ${splitZip[0]}`;
};
exports.formatAddressMobile = formatAddressMobile;

const formatAddressNoZip = (address) => {
  if (typeof address !== "string") {
    return "";
  }

  const splitAddress = address.split(",");
  const state = splitAddress[2].replace(/[\d-]/g, "");
  return `${splitAddress[0]}, ${splitAddress[1]}, ${state}`;
};
exports.formatAddressNoZip = formatAddressNoZip;

const formatAddressNoZipMobile = (address) => {
  if (typeof address !== "string") {
    return "";
  }
  const splitAddress = address.split(",");
  const state = splitAddress[2].replace(/[\d-]/g, "");
  return [splitAddress[0], splitAddress[1], state]
  // return `${splitAddress[0]}, ${splitAddress[1]}, ${state}`;
};
exports.formatAddressNoZipMobile = formatAddressNoZipMobile;

const determineName = (customer) => {
  let value = "";

  if (!customer) {
    return "N/A";

  }
  if (customer?.isCommercial) {
    if (customer.commercialAccountName.length > 0) {
      value = customer.commercialAccountName;
    } else {
      if (customer.firstName?.length > 0 && customer.lastName?.length > 0) {
        value = `${customer.firstName} ${customer.lastName}`;
      } else {
        value = "N/A";
      }
    }
  } else {
    if (customer.firstName?.length > 0 && customer.lastName?.length > 0) {
      value = `${customer.firstName} ${customer.lastName}`;
    } else {
      value = "N/A";
    }
  }
  if (value.length === 0) {
    value = "N/A";
  }
  return value;
};
exports.determineName = determineName;

const formatPhoneNumber = (value) => {
  if (!value) {
    return;
  }
  const phoneNumberDigits = value.replace(/\D/g, "");
  const formattedPhoneNumber = phoneNumberDigits.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "($1) $2-$3"
  );
  return formattedPhoneNumber;
};
exports.formatPhoneNumber = formatPhoneNumber;

const formatStatus = (status) => {
  if (!status || status.length === 0) {
    return "N/A"
  }
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};
exports.formatStatus = formatStatus;
