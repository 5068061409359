import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import CouponModal from "../../modals/CouponModal";
import { Coupon } from "../../utils/httpUtils";

import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  styled,
  Button,
} from "@mui/material";

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("expirationDate");
  const [couponsLoading, setCouponsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("title");
  const [displayCouponModal, setDisplayCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getCoupons();
  }, []);

  const getCoupons = async () => {
    Coupon()
      .getJson("getCoupons")
      .then((response) => {
        setCoupons(response.data.coupons);
        setCouponsLoading(false);
      });
  };

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const handleFilterOptionChange = (event) => {
    setSearchText("");
    setFilterOption(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredCoupons = () => {
    if (!Array.isArray(coupons)) {
      return [];
    }

    const lowerSearchText = searchText.toLowerCase();

    return coupons.filter((coupon) => {
      const fieldValue = coupon[filterOption];
      let fieldValueString = fieldValue ? fieldValue.toString() : "";

      const includesSearchText = fieldValueString
        .toLowerCase()
        .includes(lowerSearchText);

      return includesSearchText;
    });
  };

  const goToCoupon = (couponId) => {
    setSelectedCoupon(couponId);
    openNewCouponModal();
  };

  const createNewCouponModal = () => {
    setSelectedCoupon(null);
    openNewCouponModal();
  };

  const openNewCouponModal = () => {
    setDisplayCouponModal(true);
  };

  const handleCloseCouponModal = () => {
    setDisplayCouponModal(false);
  };

  const handleUpdateCoupons = () => {
    getCoupons();
  };

  const formatDuration = (duration, durationUnit) => {
    let formattedDurationUnit = durationUnit.charAt(0).toUpperCase() + durationUnit.slice(1).toLowerCase();
    if (duration === 1) {

      formattedDurationUnit = formattedDurationUnit.slice(0, -1);
    }
    return `${duration} ${formattedDurationUnit}`
  }

  return (
    <>
      <VendorNavbar />
      <CouponModal
        open={displayCouponModal}
        onClose={handleCloseCouponModal}
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        handleUpdateCoupons={handleUpdateCoupons}
      />
      <div style={{ margin: "40px" }}>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 2, userSelect: "none" }}
        >
          Coupons
        </Typography>
        <div style={{ marginBottom: "16px" }}>
          <TextField
            select
            label="Filter Option"
            variant="outlined"
            value={filterOption}
            onChange={handleFilterOptionChange}
            sx={{ marginRight: "16px", width: "200px" }}
          >
            <MenuItem value="title">Title</MenuItem>
            <MenuItem value="description">Description</MenuItem>
            <MenuItem value="minServices">Minimum Services</MenuItem>
            <MenuItem value="discount">Discount</MenuItem>
            <MenuItem value="duration">Duration</MenuItem>
            {/* <MenuItem value="expirationDate">Expiration Date</MenuItem> */}
          </TextField>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </div>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={createNewCouponModal}
            style={{ marginLeft: "0px" }}
          >
            Create New Coupon
          </Button>
        </div>

        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("title")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Title
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("description")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Description
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("minServices")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Minimum Services
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("discount")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Discount
                  </Typography>
                </TableCell>
                <TableCell onClick={() => handleSort("duration")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Duration
                  </Typography>
                </TableCell>
                {/* <TableCell onClick={() => handleSort("expiration")}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "tomato",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    Expiration
                  </Typography>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {couponsLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                filteredCoupons().map((coupon) => (
                  <ClickableTableRow
                    key={coupon._id}
                    onClick={() => goToCoupon(coupon._id)}
                  >
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.minServices}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {`${coupon.discountAmount} ${coupon.discountUnit}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatDuration(coupon.duration, coupon.durationUnit)}
                        {/* {`${coupon.duration} ${coupon.durationUnit}`} */}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {coupon.expirationDate}
                      </Typography>
                    </TableCell> */}
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Coupons;
