import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ExistingQuotesByAddressComponent from "./ExistingQuotesByAddressComponent";

function ExistingQuotesByAddress() {
  return (
    <div style={{marginBottom: "30px"}}>
      <Card style={{ width: "100%", height: "100%", border: "1px solid #ccc", borderRadius: "10px" }}>
        <CardContent>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <ExistingQuotesByAddressComponent />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default ExistingQuotesByAddress