import React, { useEffect, useState } from 'react'
import { Admin } from '../../utils/httpUtils';
import { Typography } from '@mui/material';
import { CardHeader } from '../reusableComponents/Typography';

function BillingAndPayment({vendorId}) {

    const [payments, setPayments] = useState([]);
    const [vendor, setVendor] = useState(null);

    useEffect(() => {
        getVendorBilling()
    }, [vendorId])

    const getVendorBilling = () => {
        const data = {
            vendorId
        }
        Admin().getJson('getVendorBilling', data)
        .then(response => {
            if (response.response.status === 200) {
                setPayments(response.data.subscriptionPayments);
                setVendor(response.data.vendor);
            }
        })
    }

  return (
    <div style={{ border: "1px solid #ccc", borderRadius: "10px"}}>
        <div>
            <CardHeader>Billing and Payment</CardHeader>
            {vendor && (
                <>
                <Typography>Billing Cycle: {vendor?.billingCycle ? vendor.billingCycle : "none"}</Typography>
                <Typography>AutoPay: {vendor?.autoPay ? vendor.autoPay : "N/A"}</Typography>
                </>

            )}
            {payments && (
                <>
                    {payments.map((payment) => {
                        return (
                        <>
                            <Typography>PaymentDate: {payment.paymentDate}</Typography>
                            <Typography>Amount: {payment.paymentAmount}</Typography>
                            <Typography>UserId: {payment.userId}</Typography>
                        </>

                        )
                    })}
                </>
            )}
        </div>
        
    </div>
  )
}

export default BillingAndPayment;