import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { Customer } from "../../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import AddNewCustomerModal from "../../../modals/AddNewCustomerModal";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { theme } from "../../../App";
import { GreenButton } from "../../../components/reusableComponents/Buttons";
import { determineName, formatPhoneNumber } from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  styled,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const ClickableTableRow = styled(TableRow)({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});

const CustomersMobile = () => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("customerName");
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("customerName");
  const [displayNewCustomerModal, setDisplayNewCustomerModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
  }, []);

  const getCustomers = () => {
    Customer()
      .getJson("getCustomers")
      .then((response) => {
        if (response?.response?.status === 200) {
          setCustomers(response.data.customers);
          setIsLoading(false);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const sortedCustomers = () => {
    const filtered = filteredCustomers();
    return [...filtered].sort((a, b) => {
      let aValue;
      let bValue;

      switch (sortColumn) {
        case "customerName":
          aValue = `${a.customerFirst} ${a.customerLast}`;
          bValue = `${b.customerFirst} ${b.customerLast}`;
          break;
        case "customerEmail":
          aValue = a.email;
          bValue = b.email;
          break;
        case "customerPhone":
          aValue = a.phoneNumber;
          bValue = b.phoneNumber;
          break;
        case "customerStatus":
          aValue = a.isActive;
          bValue = b.isActive;
          break;
        default:
          aValue = "";
          bValue = "";
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  };

  const handleFilterOptionChange = (event) => {
    setSearchText("");
    setFilterOption(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const openNewCustomerModal = () => {
    setDisplayNewCustomerModal(true);
  };

  const closeNewCustomerModal = () => {
    setDisplayNewCustomerModal(false);
  };

  const filteredCustomers = () => {
    const lowerSearchText = searchText.toLowerCase();

    return customers.filter((customer) => {
      let fieldValueString = "";

      if (filterOption === "customerName") {
        fieldValueString = `${customer.firstName} ${customer.lastName}`;
      } else if (filterOption === "customerPhone") {
        fieldValueString = customer.phoneNumber;
      } else if (filterOption === "customerEmail") {
        fieldValueString = customer.email || "";
      } else if (filterOption === "customerStatus") {
        fieldValueString = determineStatus(customer);
      }

      const includesSearchText = fieldValueString
        .toLowerCase()
        .includes(lowerSearchText);

      return includesSearchText;
    });
  };

  const determineStatus = (customer) => {
    if (customer.hasJobs) {
      return "Active";
    } else if (customer.DurationSinceRequest !== null) {
      return customer.DurationSinceRequest <= 7
        ? "Less than 1 week since quotes sent"
        : "More than 1 week since quotes sent";
    } else {
      return "";
    }
  };

  const determineToolTipText = (customer) => {
    if (customer.hasJobs) {
      return "Customer has scheduled or completed jobs";
    } else if (customer.DurationSinceRequest !== null) {
      return customer.DurationSinceRequest <= 7
        ? "Less than 7 days since quotes were sent"
        : "More than 7 days since quotes were sent";
    } else {
      return "This customer has not been sent quotes and has no booked/completed jobs";
    }
  };

  const goToCustomer = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  const getSortIcon = (column) => {
    const sortIconStyle = { color: theme.palette.greenButton.main };
    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowUp style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      ) : (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowDown style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      );
    }
    return null;
  };

  const SortIconWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "15px",
    color: "inherit",
  });

  return (
    <>
      <VendorNavbar />
      <AddNewCustomerModal
        open={displayNewCustomerModal}
        onClose={closeNewCustomerModal}
        updateVendor={getCustomers}
      />
      <div
        style={{
          margin: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 2, userSelect: "none" }}
        >
          Customers mobile
        </Typography> */}
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
          }}
        >
          <div style={{ marginRight: "16px" }}>
            <TextField
              select
              label="Filter Option"
              variant="outlined"
              value={filterOption}
              onChange={handleFilterOptionChange}
              sx={{ minWidth: "200px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
            >
              <MenuItem value="customerName">Customer Name</MenuItem>
              <MenuItem value="customerPhone">Phone Number</MenuItem>
              <MenuItem value="customerEmail">Email</MenuItem>
              <MenuItem value="customerStatus">Status</MenuItem>
            </TextField>
          </div>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
            disabled={filterOption === ""}
            InputProps={{ sx: { borderRadius: "8px" } }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
          }}
        >
          <GreenButton
            onClick={openNewCustomerModal}
            style={{ alignSelf: "flex-start" }}
          >
            Create New Customer
          </GreenButton>
        </div>

        <TableContainer component={Paper} style={{ marginTop: "16px" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ userSelect: "none", width: "100%" }}>
                <TableCell
                  onClick={() => handleSort("customerName")}
                  sx={{ width: "25%" }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    Name {getSortIcon("customerName")}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("customerEmail")}
                  sx={{ width: "25%" }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    Email {getSortIcon("customerEmail")}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("customerPhone")}
                  sx={{ width: "25%" }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    Customer Phone {getSortIcon("customerPhone")}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("customerStatus")}
                  sx={{ width: "25%" }}
                >
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    Status {getSortIcon("customerStatus")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                customers?.length > 0 &&
                sortedCustomers().map((customer) => (
                  <ClickableTableRow
                    key={customer._id}
                    onClick={() => goToCustomer(customer._id)}
                  >
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={determineToolTipText(customer)} arrow>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: 8,
                            }}
                            onMouseEnter={(event) =>
                              event.currentTarget.dispatchEvent(
                                new Event("mouseenter")
                              )
                            }
                            onMouseLeave={(event) =>
                              event.currentTarget.dispatchEvent(
                                new Event("mouseleave")
                              )
                            }
                          >
                            <div
                              style={{
                                width: 10,
                                height: 10,
                                backgroundColor: customer.hasJobs
                                  ? "green"
                                  : customer.DurationSinceRequest !== null
                                  ? customer.DurationSinceRequest <= 7
                                    ? "orange"
                                    : "red"
                                  : "transparent", // Blank dot if DurationSinceRequest is null
                                borderRadius: "50%",
                                marginRight: 8,
                              }}
                            ></div>
                            <Typography sx={{ fontSize: "18px" }}>
                              {determineName(customer)}
                            </Typography>
                          </div>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {customer?.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatPhoneNumber(customer.phoneNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {customer.hasJobs ? (
                        <Typography sx={{ fontSize: "18px" }}>
                          Active
                        </Typography>
                      ) : determineStatus(customer) !== "" ? (
                        <Typography sx={{ fontSize: "18px" }}>
                          {determineStatus(customer)}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: "18px" }}>-</Typography>
                      )}
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default CustomersMobile;
