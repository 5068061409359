import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import ExistingEmails from "../../components/vendorComponents/ExistingEmails";
import EmailModal from "../../modals/EmailModal";
import { Email } from "../../utils/httpUtils";
import SentEmails from "../../components/vendorComponents/SentEmails";

function Emails() {
  const [automatedEmails, setAutomatedEmails] = useState();
  const [displayEmailModal, setDisplayEmailModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [sentEmails, setSentEmails] = useState([]);

  useEffect(() => {
    getEmails();
    getSentEmails();
  }, []);

  const getEmails = () => {
    Email()
      .getJson("getAutomatedEmails")
      .then((response) => {
        setAutomatedEmails(response.data.automatedEmails);
      });
  };

  const getSentEmails = () => {
    Email()
      .getJson("getAllEmailsForVendor")
      .then((response) => {
        setSentEmails(response.data.emails);
      });
  };

  const editEmailModal = (email) => {
    setSelectedEmail(email);
    setDisplayEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setDisplayEmailModal(false);
  };

  const openNewEmailModal = () => {
    setSelectedEmail(null);
    setDisplayEmailModal(true);
  };

  return (
    <div>
      <VendorNavbar />
      <EmailModal
        open={displayEmailModal}
        onClose={handleCloseEmailModal}
        selectedEmail={selectedEmail}
        handleUpdateEmails={getEmails}
      />
      <div id="vendorDashboard">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openNewEmailModal()}
          style={{ float: "left" }}
        >
          New Automated Email
        </Button>
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={4}>
            <ExistingEmails
              automatedEmails={automatedEmails}
              setSelectedEmail={setSelectedEmail}
              editEmailModal={editEmailModal}
            />
          </Grid>

          {/* Right Column */}
          <Grid item xs={8}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <SentEmails sentEmails={sentEmails} />
              </Grid>
              <Grid item>{/* <QuoteStatistics /> */}</Grid>
              <Grid item>{/* <EditPresets /> */}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Emails;
