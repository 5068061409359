import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

// Desktop
import AddressDesktop from "./desktop/AddressDesktop";
// Mobile
import AddressMobile from "./mobile/AddressMobile";

function Address() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return <>{isMobile ? <AddressMobile /> : <AddressDesktop />}</>;
}

export default Address;
