import React, { useEffect, useState } from "react";
import { Container, Typography } from "@mui/material";
import QuoteCardSmall from "../../components/clientComponents/QuoteCardSmall";
import JobCreatedModal from "../../modals/JobCreatedModal";
import { formatDate } from "../../utils/utils";
import CustomCheckbox from "../../components/reusableComponents/Customcheckbox";
import { GreenButton } from "../../components/reusableComponents/Buttons";

export default function QuotesSmall({
  selectedQuotes,
  onSelectQuote,
  requestServices,
  error,
  success,
  job,
  vendor,
  property,
  allQuotes,
  coupons,
  appliedCoupons,
  dollarDiscount,
}) {
  const [displayModal, setDisplayModal] = useState(false);
  const [requestedQuotes, setRequestedQuotes] = useState([]);
  const [unrequestedQuotes, setUnrequestedQuotes] = useState([]);

  useEffect(() => {
    let requested = [];
    let unrequested = [];
    if (allQuotes) {
      allQuotes.forEach((quote) => {
        if (quote.requested) {
          requested.push(quote);
        } else if (!quote.requested) {
          unrequested.push(quote);
        }
      });
      setRequestedQuotes(requested);
      setUnrequestedQuotes(unrequested);
    }
  }, [allQuotes]);

  useEffect(() => {
    if (success) {
      openModal();
    }
  }, [job]);

  const closeModal = () => {
    setDisplayModal(false);
  };

  const openModal = () => {
    setDisplayModal(true);
  };

  const isCouponApplied = (coupon) => {
    return appliedCoupons.some(
      (appliedCoupon) => appliedCoupon._id === coupon._id
    );
  };

  return (
    <div className={"quotesLarge"}>
      <Container
        sx={{
          bgcolor: "white",
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {vendor && (
          <div className={"vendorLogoContainer"} style={{ marginTop: "20px" }}>
            <img src={vendor.logo} className={"vendorLogo"} alt="Vendor Logo" />
          </div>
        )}

        <Container
          sx={{
            flexDirection: "column",
            fontWeight: 300,
            letterSpacing: ".1rem",
            color: "inherit",
            textDecoration: "none",
            fontSize: 19,
            padding: 3,
            justifyContent: "center",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          {vendor && (
            <div className={"vendorInfoContainer"}>
              <div className={"vendorInfo"}>
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      variant: "h6",
                      textAlign: "center",
                      fontWeight: 300,
                      letterSpacing: ".05rem",
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: 18,
                    }}
                  >
                    {vendor.companyName}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      variant: "h6",
                      textAlign: "center",
                      fontWeight: 300,
                      letterSpacing: ".05rem",
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: 18,
                    }}
                  >
                    {vendor.website}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      variant: "h6",
                      textAlign: "center",
                      fontWeight: 300,
                      letterSpacing: ".05rem",
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: 18,
                    }}
                  >
                    {vendor.email}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      variant: "h6",
                      textAlign: "center",
                      fontWeight: 300,
                      letterSpacing: ".1rem",
                      color: "inherit",
                      textDecoration: "none",
                      fontSize: 18,
                    }}
                  >
                    {vendor.phoneNumber}
                  </Typography>
                </div>
              </div>
            </div>
          )}
        </Container>

        {error && <div className={"createJobError"}>Error: {error}</div>}
        {success && <div className={"jobCreationSuccess"}>{success}</div>}
        {displayModal && (
          <JobCreatedModal
            onClose={closeModal}
            open={displayModal}
            vendor={vendor}
            job={job}
          />
        )}

        <Typography
          variant="body1"
          sx={{
            variant: "h6",
            textAlign: "center",
            fontWeight: 300,
            letterSpacing: ".1rem",
            color: "inherit",
            textDecoration: "none",
            fontSize: 26,
            marginBottom: "20px",
            userSelect: "none",
          }}
        >
          Quotes for {property?.address.number} {property?.address.street}
        </Typography>
        <Typography
          // variant="body3"
          sx={{
            textAlign: "center",
            // fontWeight: 300,
            letterSpacing: ".1rem",
            color: "inherit",
            textDecoration: "none",
            fontSize: 22,
            marginBottom: "20px",
            userSelect: "none",
          }}
        >
          Add services to your job
        </Typography>

        {requestedQuotes?.length > 0 && (
          <div style={{width: "100%"}}>
            <Typography
              variant="h5"
              sx={{
                variant: "h6",
                textAlign: "center",
                fontWeight: 300,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                fontSize: 24,
                marginBottom: "10px",
                width: "90vw",
                userSelect: "none",
              }}
            >
              {requestedQuotes?.length === 1
                ? "Here is the quote you requested"
                : "Here are the quotes you requested:"}
            </Typography>
            {requestedQuotes.map((quote) => (
              <div
                key={quote._id}
                className={"quoteCardContainer"}
                style={{ width: "100%" }}
              >
                <QuoteCardSmall
                  quote={quote}
                  isSelected={selectedQuotes.includes(quote._id)}
                  onSelect={() => onSelectQuote(quote._id)}
                  selectedQuotes={selectedQuotes}
                />
              </div>
            ))}
          </div>
        )}

        {/* Display unrequested quotes if there are any */}
        {unrequestedQuotes?.length > 0 &&
          (requestedQuotes?.length > 0 ? (
            <div style={{ width: "100%" }}>
              <Typography
                variant="h5"
                sx={{
                  variant: "h6",
                  textAlign: "center",
                  fontWeight: 300,
                  letterSpacing: ".1rem",
                  color: "inherit",
                  textDecoration: "none",
                  fontSize: 24,
                  marginTop: "20px",
                  marginBottom: "10px",
                  width: "90vw",
                  userSelect: "none",
                }}
              >
                Explore additional services for your property below
              </Typography>
              {unrequestedQuotes.map((quote) => (
                <div key={quote._id} className={"quoteCardContainer"}>
                  <QuoteCardSmall
                    quote={quote}
                    isSelected={selectedQuotes.includes(quote._id)}
                    onSelect={() => onSelectQuote(quote._id)}
                    selectedQuotes={selectedQuotes}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {unrequestedQuotes.map((quote) => (
                <div key={quote._id} className={"quoteCardContainer"}>
                  <QuoteCardSmall
                    quote={quote}
                    isSelected={selectedQuotes.includes(quote._id)}
                    onSelect={() => onSelectQuote(quote._id)}
                    selectedQuotes={selectedQuotes}
                  />
                </div>
              ))}
            </div>
          ))}

        {/* Coupons */}
        {coupons && coupons.length > 0 && (
          <div>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                userSelect: "none",
                fontWeight: 300,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                fontSize: 24,
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              Coupons
            </Typography>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                userSelect: "none",
                fontWeight: 300,
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                fontSize: 16,
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              (Coupons are automatically applied)
            </Typography>

            {coupons.map((coupon) => (
              <div
                key={coupon._id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomCheckbox checked={isCouponApplied(coupon)} disabled />
                <div>
                  <Typography>
                    {coupon.description}.{" "}
                    {coupon.expiryDate
                      ? `Offer valid until ${formatDate(coupon.expiryDate)}.`
                      : null}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={"requestServicesButton"}>
          <GreenButton
            variant="contained"
            color="primary"
            onClick={requestServices}
            sx={{ marginTop: 2, marginBottom: 2 }}
            disabled={selectedQuotes?.length === 0}
          >
            Book services
          </GreenButton>
        </div>
      </Container>
    </div>
  );
}
