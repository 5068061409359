import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

// Desktop
import EditProfileDesktop from "./desktop/EditProfileDesktop";
// Mobile
import EditProfileMobile from "./mobile/EditProfileMobile";

function Customers() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return <>{isMobile ? <EditProfileMobile /> : <EditProfileDesktop />}</>;
}

export default Customers;
