import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import { TableHeader } from "../reusableComponents/Typography";

const RecentJobsAtAddress = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState(props.jobs);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    setJobs(props.jobs);
  }, [props.jobs]);

  useEffect(() => {
    if (jobs) {
      setIsLoading(false);
    }
  });

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date
      .toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/,/g, "");
    return formattedDate;
  };

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const isRecentJob = (job) => {
    const jobScheduledDate = new Date(job.scheduledDate);
    const today = new Date();

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(today.getDate() - 7);

    return jobScheduledDate >= oneWeekAgo || jobScheduledDate <= today;
  };

  const determineName = (customer) => {
    let value = "";
    if (customer.isCommercial) {
      if (customer.commercialAccountName.length > 0) {
        return (value = customer.commercialAccountName);
      } else {
        value = `${customer.firstName} ${customer.lastName}`;
      }
    } else {
      value = `${customer.firstName} ${customer.lastName}`;
    }
    if (value.length === 0) {
      value = "No name on this account";
    }
    return value;
  };

  const formatEnum = (str) => {
    if (!str) return "";
    const lowercased = str.toLowerCase();
    return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
  };

  return (
    <Card
      style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Recent/Upcoming Jobs
        </Typography>
        {/* <Typography
          variant="h7"
          component="div"
          sx={{ marginBottom: 3, userSelect: "none" }}
        >
          Click to View Job
        </Typography> */}
        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableHeader>Scheduled</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Payment Amount</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Job Status</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Payment Status</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Customer Name</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Customer Phone</TableHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                jobs?.map((job) => {
                  if (isRecentJob(job) || job.jobStatus === "REQUESTED") {
                    return (
                      <ClickableTableRow
                        key={job._id}
                        onClick={() => goToJob(job._id)}
                      >
                        <TableCell>
                          {job.scheduledDate ? (
                            <Typography sx={{ fontSize: "18px" }}>
                              {formatDate(job.scheduledDate)}
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: "18px" }}>
                              Not Scheduled
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            ${job.totalPrice?.toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {formatEnum(job.jobStatus)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {formatEnum(job.paymentStatus)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {determineName(job.customer)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontSize: "18px" }}>
                            {formatPhoneNumber(job.customer.phoneNumber)}
                          </Typography>
                        </TableCell>
                      </ClickableTableRow>
                    );
                  }
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default RecentJobsAtAddress;
