import React, { useContext, useEffect, useState } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import VendorContext from "../../context/VendorContext";
import { Vendor } from "../../utils/httpUtils";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { useNavigate } from "react-router-dom";
import ResetPasswordModal from "../../modals/ResetPasswordModal";
import { GreenButton } from "../../components/reusableComponents/Buttons";
import UploadLogoModal from "../../modals/UploadLogoModal";


const Profile = () => {
  const navigate = useNavigate();
  const { vendor, setVendor } = useContext(VendorContext);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [showUploadLogoModal, setShowUploadLogoModal] = useState(false);

  useEffect(() => {
    if (!vendor) {
      fetchData();
    }
  }, [vendor]);

  const fetchData = async () => {
    try {
      const response = await Vendor().getJson("getVendor");
      setVendor(response.data);
    } catch (error) {
      console.error("Error fetching vendor data", error);
    }
  };

  const handleOpenPasswordReset = () => {
    setShowPasswordReset(true);
  };

  const handleClosePasswordReset = () => {
    setShowPasswordReset(false);
  };


  return (
    <div>
      <VendorNavbar />
      <ResetPasswordModal
        open={showPasswordReset}
        onClose={handleClosePasswordReset}
      />
      <UploadLogoModal
        open={showUploadLogoModal}
        onClose={() => setShowUploadLogoModal(false)}
        updateVendor={fetchData}
      />
      <Container
        sx={{
          paddingTop: 5,
          paddingHorizontal: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          userSelect: "none",
        }}
      >
        <Box mb={0}>
          {vendor?.logo ? (
            <img
              src={vendor.logo}
              alt="Vendor Logo"
              style={{ width: 300, height: "auto" }}
            />
          ) : (
            <GreenButton onClick={() => setShowUploadLogoModal(true)} sx={{marginBottom: "30px"}}>Upload Logo</GreenButton>
          )}
        </Box>
        <Box sx={{ alignItems: "center" }}>
          <Typography variant="h4" sx={{ fontSize: "40px", marginBottom: 1 }}>
            {vendor?.companyName}
          </Typography>

          <Typography variant="h6" sx={{ marginBottom: 6 }}>
            {vendor?.website}
          </Typography>

          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {vendor?.email}
          </Typography>

          <Typography variant="h6" sx={{ marginBottom: 4 }}>
            {vendor?.phoneNumber}
          </Typography>

          {/* <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Services Offered:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {vendor?.services
              ?.map((category) => mapEnumToLabel(category))
              .join(", ")}
          </Typography> */}

          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {vendor?.physicalLocation?.address?.readableAddress ||
              "No Address Available"}
          </Typography>

          {/* <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {vendor?.reviewLink ? vendor.reviewLink : "No Review Link"}
          </Typography> */}
{/* 
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Heard about us options
          </Typography>

          {vendor?.heardAboutUsOptions?.length === 0 && (
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
            No options for "heard about us"
          </Typography>
          )} */}

          {/* {vendor?.heardAboutUsOptions && vendor.heardAboutUsOptions.map(option => {
            return (
            <Typography key={option} variant="h6" sx={{ marginBottom: 2 }}>
            {option}
          </Typography> */}
            {/* )
          })} */}

          {/* <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
            Service Radius:
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {vendor?.serviceRadius} Miles
          </Typography> */}
        </Box>

        <GreenButton
          variant="contained"
          onClick={() => navigate("/account/editprofile")}
          sx={{ marginTop: 2, marginBottom: 5 }}
        >
          Edit Profile
        </GreenButton>
        <GreenButton
          variant="contained"
          onClick={handleOpenPasswordReset}
          sx={{ marginTop: 2, marginBottom: 5 }}
        >
          Change Password
        </GreenButton>
      </Container>
    </div>
  );
};

export default Profile;
