import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

// Desktop
import CustomerDesktop from "./desktop/CustomerDesktop";
// Mobile
import CustomerMobile from "./mobile/CustomerMobile";

function Customers() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return <>{isMobile ? <CustomerMobile /> : <CustomerDesktop />}</>;
}

export default Customers;
