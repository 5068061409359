import React, { useState, useEffect, useContext } from "react";
import { Vendor } from "../utils/httpUtils";
import { TextField } from "@mui/material";
import VendorContext from "../context/VendorContext";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";

const NewCategoryModal = ({ open, onClose, updateVendor }) => {
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { setVendor } = useContext(VendorContext);

  useEffect(() => {
    if (open) {
      setCategory("");
      setError("");
      setSuccess(false);
    }
  }, [open]);

  const handleAddCategory = () => {
    setError("");
    setSuccess(false);
    const data = {
      category,
    };
    Vendor()
      .postJson("addQuoteCategory", data)
      .then((response) => {
        if (response.response.data.error) {
          setError(response.response.data.error);
          return;
        }
        setError("");
        setSuccess(true);
        updateVendor();
        setVendor(response.data);
        setTimeout(() => {
          onClose();
        }, 2000);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Quote Category</DialogTitle>
      <DialogContent>
        <TextField
          label="Quote Category"
          variant="outlined"
          fullWidth
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          sx={{ marginBottom: 2, marginTop: 2 }}
        />
        {success && (
          <div>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "center", color: "green" }}
            >
              Category Successfully Saved
            </Typography>
          </div>
        )}
        {error.length > 0 && (
          <div>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "center", color: "red" }}
            >
              Error: {error}
            </Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <GreenButton
          onClick={handleAddCategory}
          disabled={category.length === 0}
        >
          Save
        </GreenButton>

        <RedButton onClick={onClose}>Close</RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewCategoryModal;
