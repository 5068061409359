import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingIcon from "../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { GreenButton } from "../reusableComponents/Buttons";
import { CustomSelectNoAdd } from "../reusableComponents/Inputs";

function CustomersAtAddress({ customers }) {
  const [isLoading, setIsLoading] = useState(false);
  const [customersState, setCustomersState] = useState(customers);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setCustomersState(customers);
    setSelectedCustomer(customers[0] ? customers[0] : null);
  }, [customers]);

  const handleSelectCustomer = (event) => {
    const selectedCustomerId = event.target.value;
    const selectedCustomer = customers.find(
      (customer) => customer._id === selectedCustomerId
    );
    setSelectedCustomer(selectedCustomer);
  };

  const goToCustomer = () => {
    if (selectedCustomer) {
      const customerId = selectedCustomer._id;
      navigate(`/customers/${customerId}`);
    }
  };

  const determineDisplayName = (customer) => {
    if (customer.isCommercial) {
      if (customer.commercialAccountName) {
        return customer.commercialAccountName;
      } else if (customer.firstName && customer.lastName) {
        return `${customer.firstName} ${customer.lastName}`;
      } else {
        return `${customer.email}`;
      }
    } else {
      if (customer.firstName && customer.lastName) {
        return `${customer.firstName} ${customer.lastName}`;
      } else {
        return `${customer.email}`;
      }
    }
  };

  return (
    <div>
      <Card style={{ width: "100%", height: "100%", border: "1px solid #ccc", borderRadius: "10px" }}>
        <CardContent>
          <Grid container rowSpacing={2} sx={{userSelect: "none"}}>
            <Grid item xs={12}>
              <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
                Customers/Accounts At This Address
              </Typography>
              {isLoading ? (
                <LoadingIcon />
              ) : customersState?.length === 0 ? (
                <Typography variant="body1" component="div">
                  No customers at this address
                </Typography>
              ) : customersState?.length === 1 ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      marginRight: "30px",
                      fontSize: "20px",
                      marginLeft: "30px",
                    }}
                  >
                    {determineDisplayName(customersState[0])}
                  </Typography>
                  <div style={{ marginLeft: "auto" }}>
                    <GreenButton onClick={goToCustomer}>
                    View Customer
                    </GreenButton>
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CustomSelectNoAdd
                  value={selectedCustomer ? selectedCustomer._id : ""}
                  onChange={handleSelectCustomer}
                  options={customersState?.map((customer) => ({
                    label: determineDisplayName(customer),
                    value: customer._id
                  }))}
                  
                  sx={{width: "63%", maxWidth: "63%"}}
                  
                  />
                  {/* <Select
                    value={selectedCustomer ? selectedCustomer._id : ""}
                    onChange={handleSelectCustomer}
                    style={{ width: "280px", marginRight: "16px" }}
                    renderValue={() => {
                      return selectedCustomer
                        ? determineDisplayName(selectedCustomer)
                        : "";
                    }}
                  >
                    {customersState?.map((customer) => (
                      <MenuItem key={customer._id} value={customer._id}>
                        {determineDisplayName(customer)}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <div style={{ marginLeft: "auto" }}>
                    <GreenButton onClick={goToCustomer}>
                      View Customer
                    </GreenButton>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default CustomersAtAddress;
