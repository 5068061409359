import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../commonComponents/LoadingIcon";
import QuoteCard from "./QuoteCard";
import { isValidEmail } from "../../utils/validationUtils";
import { Quote, Coupon } from "../../utils/httpUtils";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";

import EditQuoteModal from "../../modals/EditQuoteModal";
import EditIcon from "@mui/icons-material/Edit";
import { GreenButton } from "../reusableComponents/Buttons";
import { CustomSelect, CustomTextField } from "../reusableComponents/Inputs";
import { useNavigate } from "react-router-dom";

function CurrentQuotesAtAddress(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [quotes, setQuotes] = useState(props.quotes);
  const [email, setEmail] = useState("");
  const [emailSuccessful, setEmailSuccessful] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [propertyId, setPropertyId] = useState(props.propertyId);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    setQuotes(props.quotes);
    getCoupons();
  }, [props.quotes]);

  useEffect(() => {
    setPropertyId(props.propertyId);
  }, [props.propertyId]);

  useEffect(() => {
    if (quotes) {
      setIsLoading(false);
    }
  });

  const getCoupons = () => {
    Coupon()
      .getJson("getCoupons")
      .then((response) => {
        setCoupons(response.data.coupons);
      });
  };

  const handleToggleSelection = (quoteId) => {
    setSelectedQuotes((prevSelectedQuotes) => {
      if (prevSelectedQuotes.includes(quoteId)) {
        return prevSelectedQuotes.filter((id) => id !== quoteId);
      } else {
        return [...prevSelectedQuotes, quoteId];
      }
    });
  };

  const handleSelectCoupon = (couponId) => {
    setSelectedCoupons((prevSelectedCoupons) => [
      ...prevSelectedCoupons,
      couponId,
    ]);
  };

  const handleRemoveCoupon = (couponId) => {
    setSelectedCoupons((prevSelectedCoupons) =>
      prevSelectedCoupons.filter((id) => id !== couponId)
    );
  };

  const sendQuotes = () => {
    setEmailSuccessful();
    if (!isValidEmail(email)) {
      console.log("invalid email");
    }
    const data = {
      email,
      quotes,
      propertyId: props.propertyId,
      requestedQuotes: selectedQuotes,
      coupons: selectedCoupons,
    };
    Quote()
      .postJson("emailQuotes", data)
      .then((response) => {
        if (response.response.status === 200) {
          setEmailSuccessful(true);
          props.getCustomersAtAddress();
        } else {
          setEmailSuccessful(false);
        }
      });
  };

  const handleSetEmail = (email) => {
    setEmailSuccessful(false);
    setEmailError(false);
    setEmail(email);
    setIsEmailValid(validateEmail(email));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleOpenModal = (quote) => {
    setSelectedQuote(quote);
  };

  const handleCloseModal = () => {
    setSelectedQuote(null);
  };

  const deleteQuote = (quoteId) => {
    Quote()
      .deleteJson(`/${quoteId}`)
      .then((response) => {
        if (response.response.status === 200) {
          props.updateProperty();
        }
      });
  };

  return (
    <div>
      <Card
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="div"
                sx={{ marginBottom: 1, userSelect: "none" }}
              >
                Existing Quotes
              </Typography>
              <Typography
                variant="h7"
                component="div"
                sx={{ marginBottom: 1, userSelect: "none" }}
              >
                Select Customer Requested Quotes
              </Typography>
              <Typography
                variant="subtitle2"
                component="div"
                sx={{ marginBottom: 3, userSelect: "none" }}
              >
                Note: All quotes are sent to customers; but these selected
                quotes will show more prominently.
              </Typography>
              <div
                style={{
                  display: "flex",
                  borderBottom: "1px solid #ccc",
                  marginBottom: "8px",
                }}
              ></div>
              {isLoading ? (
                <LoadingIcon />
              ) : quotes?.length > 0 ? (
                quotes.map((quote) => (
                  <div
                    key={quote._id}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "16px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "70%" }}>
                      <div
                        onClick={() => handleToggleSelection(quote._id)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <QuoteCard
                          quote={quote}
                          isSelected={selectedQuotes.includes(quote._id)}
                          handleToggleSelection={() =>
                            handleToggleSelection(quote._id)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <EditIcon
                        style={{
                          cursor: "pointer",
                          color: theme.palette.grey,
                          marginLeft: "10px",
                        }}
                        onClick={() => handleOpenModal(quote)}
                      />
                      <DeleteIcon
                        onClick={() => deleteQuote(quote._id)}
                        style={{
                          cursor: "pointer",
                          color: theme.palette.grey,
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : null}
              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: 1, userSelect: "none", marginTop: "30px" }}
              >
                Coupons
              </Typography>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CustomSelect
                  value={""}
                  variant="outlined"
                  onAddNewClick={() => navigate(`/settings/coupons`)}
                  onChange={(e) => handleSelectCoupon(e.target.value)}
                  options={coupons
                    .filter((coupon) => !selectedCoupons.includes(coupon._id))
                    .map((coupon) => ({
                      value: coupon._id,
                      label: coupon.title,
                    }))}
                  sx={{ width: "300px", marginBottom: "8px" }}
                />
                {selectedCoupons.length > 0 && (
                  <Typography
                    variant="body1"
                    style={{ marginTop: "8px", userSelect: "none" }}
                  >
                    Selected Coupons:
                  </Typography>
                )}
                {selectedCoupons.map((couponId) => {
                  const selectedCoupon = coupons.find(
                    (coupon) => coupon._id === couponId
                  );
                  return (
                    <div
                      key={couponId}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body2"
                        style={{ marginRight: "8px", userSelect: "none" }}
                      >
                        {selectedCoupon.title}
                      </Typography>
                      <DeleteIcon
                        onClick={() => handleRemoveCoupon(couponId)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  );
                })}
                <CustomTextField
                  label="Email"
                  value={email}
                  onChange={(e) => handleSetEmail(e.target.value)}
                  sx={{
                    width: "300px",
                    marginBottom: "8px",
                    marginTop: "40px",
                  }}
                />
                {emailSuccessful && (
                  <Typography
                    variant="h5"
                    color="green"
                    style={{ marginBottom: "8px" }}
                  >
                    Email sent successfully
                  </Typography>
                )}
                {emailError && (
                  <Typography
                    variant="h5"
                    color="green"
                    style={{ marginBottom: "8px" }}
                  >
                    Error sending email
                  </Typography>
                )}
                <GreenButton
                  onClick={sendQuotes}
                  disabled={!isEmailValid || quotes.length === 0}
                >
                  Email Quotes
                </GreenButton>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {selectedQuote && (
        <EditQuoteModal
          open={!!selectedQuote}
          onClose={handleCloseModal}
          quote={selectedQuote}
          updateProperty={props.updateProperty}
        />
      )}
    </div>
  );
}

export default CurrentQuotesAtAddress;
