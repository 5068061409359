import React, { useState, useEffect } from "react";
import { Service } from "../utils/httpUtils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

const RecurringServiceModal = ({
  open,
  onClose,
  service,
  handleUpdate,
  scheduledDate,
}) => {
  const [recurringNumber, setRecurringNumber] = useState(
    service?.recurringFrequency || 6
  );
  const [recurringUnit, setRecurringUnit] = useState(
    service?.recurringUnit || "months"
  );

  useEffect(() => {
    if (service) {
      setRecurringNumber(service.recurringNumber || "");
      setRecurringUnit(service.recurringUnit || "weeks");
    }
  }, [service]);

  const handleSave = () => {
    const data = {
      recurring: true,
      recurringFrequency: recurringNumber,
      recurringUnit,
      serviceId: service._id,
      scheduledDate,
    };
    Service()
      .postJson("/addRecurringService", data)
      .then((response) => {
        if (response.response.status === 200) {
          handleUpdate();
          onClose();
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
          Create Recurring Service
        </Typography>
        <div>
          <Typography
            variant="body1"
            sx={{ marginRight: "10px", fontSize: "20px", marginBottom: "10px" }}
          >
            Repeat every:
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            value={recurringNumber}
            onChange={(e) => setRecurringNumber(e.target.value)}
            type="number"
            sx={{ marginBottom: 2, maxWidth: "80px" }}
          />
          <Select
            value={recurringUnit}
            onChange={(e) => setRecurringUnit(e.target.value)}
            sx={{ maxWidth: "100px", marginLeft: "10px" }}
          >
            <MenuItem value="weeks">Weeks</MenuItem>
            <MenuItem value="months">Months</MenuItem>
          </Select>
        </div>
        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{ margin: "5px" }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default RecurringServiceModal;
