import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { GreenButton } from "../components/reusableComponents/Buttons";

const JobCreatedModal = ({ open, onClose, vendor, job }) => {
  const baseUrl = process.env.REACT_APP_LINK_BASE_URL;
  const vendorLink = `${baseUrl}/track/${job}`;
  const items = [
    `${vendor.companyName} has been notified that you have requested these services.`,
    "Be on the lookout for a call or email from them to schedule your requested job.",
    "Track your job status here (we'll email you this link as well)",
    `Thank you for booking with ${vendor.companyName}!`,
  ];
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Job Successfully Created!</DialogTitle>
      <DialogContent>
        <List component="ol">
          {items.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  index === 2 ? (
                    <Link
                      href={vendorLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item}
                    </Link>
                  ) : (
                    item
                  )
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <GreenButton onClick={onClose} variant="contained" color="primary">
          Close
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default JobCreatedModal;
