import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import { TableHeader } from "../reusableComponents/Typography";
import { formatDate } from "../../utils/utils";

const JobHistoryAtAddress = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState(props.jobs);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    setJobs(props.jobs);
  }, [props.jobs]);

  useEffect(() => {
    if (jobs) {
      setIsLoading(false);
    }
  });

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  const formatEnum = (str) => {
    if (!str) return "";
    const lowercased = str.toLowerCase();
    return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        border: "1px solid #ccc",
        borderRadius: "10px",
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Job History{" "}
          {jobs && jobs.length > 0
            ? `at ${jobs[0].address.number} ${jobs[0].address.street}`
            : null}
        </Typography>
        {/* <Typography
          variant="h7"
          component="div"
          sx={{ marginBottom: 3, userSelect: "none" }}
        >
          Click to View Job
        </Typography> */}

        <TableContainer
          component={Paper}
          style={{ marginBottom: "16px", userSelect: "none" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableHeader>Scheduled Date</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Payment Amount</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Job Status</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Payment Status</TableHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : jobs?.length > 0 ? (
                jobs.map((job) => {
                  if (
                    job.jobStatus === "COMPLETED" ||
                    job.jobStatus === "CANCELLED"
                  ) {
                    return (
                      <ClickableTableRow
                        key={job._id}
                        onClick={() => goToJob(job._id)}
                      >
                        <TableCell>
                          {job?.scheduledDate ? (
                            <Typography
                              variant="subtitle1"
                              component="div"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {formatDate(job.scheduledDate)}
                            </Typography>
                          ) : (
                            <Typography
                              variant="subtitle1"
                              component="div"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              Not Scheduled
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            $ {job.totalPrice?.toFixed(2)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {formatEnum(job.jobStatus)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {formatEnum(job.paymentStatus)}
                          </Typography>
                        </TableCell>
                      </ClickableTableRow>
                    );
                  }
                })
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default JobHistoryAtAddress;
