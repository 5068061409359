import React, { useState } from "react";
import { GreenButton } from "../reusableComponents/Buttons";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  Grid,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { Vendor } from "../../utils/httpUtils";
import CustomSwitch from "../reusableComponents/Customswitch";

function ReviewSettings({ settings, updateSettings }) {
  const theme = useTheme();
  const [reviewLink, setReviewLink] = useState(
    settings?.settings?.reviewLink || null
  );
  const [updatingToggle, setUpdatingToggle] = useState(false);

  const handleUpdateReviewLink = () => {
    const data = {
      includeReviewLink: settings.settings.includeReviewLink,
      reviewLink: reviewLink,
    };

    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      })
      .catch((error) => {
        console.error("Error updating lead source:", error);
      });
  };

  const updateIncludeReviewLink = () => {
    const data = {
      includeReviewLink: !settings.settings.includeReviewLink,
    };
    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      });
  };

  const handleDeleteReviewLink = () => {
    const data = {
      reviewLink: "",
    };

    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      })
      .catch((error) => {
        console.error("Error updating lead source:", error);
      });
  };

  return (
    <div id="vendorDashboard">
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "50px",
        }}
      >
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "20px" }}
            >
              Include review link in customer tracking email
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <CustomSwitch
              checked={settings?.settings?.includeReviewLink}
              onChange={() => updateIncludeReviewLink()}
              disabled={updatingToggle}
            />
          </Grid>

          {/* Second Row */}
          <Grid
            item
            container
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <TextField
              label={
                settings.settings?.reviewLink
                  ? "Update Review Link"
                  : "Add Review Link"
              }
              variant="outlined"
              value={reviewLink}
              style={{ marginRight: "10px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
              onChange={(e) => setReviewLink(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleUpdateReviewLink}
              disabled={reviewLink && reviewLink?.length < 1}
              sx={{
                backgroundColor: theme.palette.greenButton.main,
                fontFamily: theme.palette.greenButton.fontFamily,
                fontWeight: theme.palette.greenButton.fontWeight,
                fontSize: theme.palette.greenButton.fontSize,
                height: "56px",
                width: "90px",
                "&:hover": {
                  backgroundColor: theme.palette.greenButton.dark,
                },
                borderRadius: "8px",
              }}
            >
              {settings?.settings?.reviewLink ? "Update" : "Add"}
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            {settings?.settings?.reviewLink?.length > 0 && (
              <List dense>
                <ListItem
                  key={"reviewLink"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ListItemIcon>
                    <IconButton
                      edge="start"
                      aria-label="delete"
                      onClick={handleDeleteReviewLink}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      userSelect: "none",
                      fontSize: "20px",
                      color: settings?.settings?.includeReviewLink
                        ? "black"
                        : "gray",
                      marginLeft: "8px",
                    }}
                  >
                    {settings?.settings?.reviewLink}
                  </Typography>
                </ListItem>
              </List>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ReviewSettings;
