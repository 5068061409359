import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LoadingIcon from "../commonComponents/LoadingIcon";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Vendor } from "../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import { GreenButton } from "../reusableComponents/Buttons";

const QuoteStastics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getQuoteStastics();
  }, []);

  const getQuoteStastics = () => {
    Vendor()
      .getJson("getQuoteStastics")
      .then((response) => {
        setStats(response.data);
        setIsLoading(false);
      });
  };

  const goToManageQuotes = () => {
    navigate(`/quotes/manage`);
  };

  return (
    <Card style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px"}}>
      <CardContent>
        <Typography variant="h4" component="div" sx={{ marginBottom: 2, userSelect: "none" }}>
          Quote Statistics
        </Typography>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="70%"
            margin="auto"

          >
            <Grid container spacing={2} sx={{userSelect: "none"}}>
              <Grid item xs={6} sx={{textAlign: "left"}}>
                <Typography variant="h6" component="div">
                  Total Quotes:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "right"}}>
                <Typography variant="h6" color="text.secondary">
                  {stats?.totalQuotes}
                </Typography>
              </Grid>

              <Grid item xs={6} sx={{textAlign: "left"}}>
                <Typography variant="h6" component="div">
                  Total Properties Quoted:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "right"}}>
                <Typography variant="h6" color="text.secondary">
                  {stats?.totalProperties}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "left"}}>
                <Typography variant="h6" component="div">
                  Quotes per Property:
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{textAlign: "right"}}>
                <Typography variant="h6" color="text.secondary">
                  {stats?.totalQuotes
                    ? (stats?.totalQuotes / stats?.totalProperties).toFixed(2)
                    : "Add quotes to view this stastic"}
                </Typography>
              </Grid>
            </Grid>
            <div style={{ marginTop: "20px" }}>
              <GreenButton onClick={goToManageQuotes}>
              Manage Quotes
              </GreenButton>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={goToManageQuotes}
              >
                Manage Quotes
              </Button> */}
            </div>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default QuoteStastics;
