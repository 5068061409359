import React, { useState, useEffect } from "react";
import { Vendor } from "../../utils/httpUtils";
import NewCategoryModal from "../../modals/NewCategoryModal";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  CustomSelect,
  CustomSelectWithLabel,
  CustomTextField,
} from "../reusableComponents/Inputs";
import { GreenButton, RedButton } from "../reusableComponents/Buttons";
import { Card, CardContent, Typography, Grid } from "@mui/material";

const QuoteSettings = () => {
  const [quoteSets, setQuoteSets] = useState([]);
  const [selectedQuoteSet, setSelectedQuoteSet] = useState(null);
  const [savedQuotes, setSavedQuotes] = useState([]);
  const [quoteCategories, setQuoteCategories] = useState([]);
  const [editedQuoteSetTitle, setEditedQuoteSetTitle] = useState("");
  const [selectedSavedQuote, setSelectedSavedQuote] = useState(null);
  const [isQuoteSetSelected, setIsQuoteSetSelected] = useState(false);
  const [isSavedQuoteSelected, setIsSavedQuoteSelected] = useState(false);
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const [isNewPreset, setIsNewPreset] = useState(null);

  useEffect(() => {
    getVendor();
  }, []);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setSavedQuotes(response.data.savedQuotes);
        setQuoteCategories(response.data.quoteCategories);
        setQuoteSets(response.data.quoteSet);
      });
  };

  const handleQuoteSetSelect = (selectedId) => {
    if (selectedId === "AddNew") {
      setIsNewPreset(true);
      const newQuoteSet = {
        quoteSetTitle: "",
        quoteSet: [
          {
            quoteCategory: "",
            quoteTitle: "",
            quotePrice: "",
            quoteDescription: "",
          },
        ],
      };
      setSelectedQuoteSet(newQuoteSet);
      setIsQuoteSetSelected(true);
    } else {
      setIsNewPreset(false);
      const selectedQuoteSet = quoteSets.find(
        (quoteSet) => quoteSet._id === selectedId
      );
      setSelectedQuoteSet(selectedQuoteSet);
      setEditedQuoteSetTitle(selectedQuoteSet?.quoteSetTitle || "");
      setIsQuoteSetSelected(true);
      setSelectedSavedQuote(null);
    }
  };

  const handleQuoteSetTitleChange = (event) => {
    setEditedQuoteSetTitle(event.target.value);
    setSelectedQuoteSet((prevQuoteSet) => ({
      ...prevQuoteSet,
      quoteSetTitle: event.target.value,
    }));
  };

  const handleQuoteChange = (index, field, value) => {
    setSelectedQuoteSet((prevQuoteSet) => {
      const updatedQuoteSet = { ...prevQuoteSet };

      if (!updatedQuoteSet.quoteSet) {
        updatedQuoteSet.quoteSet = [];
      }

      const updatedQuote = { ...(updatedQuoteSet.quoteSet[index] || {}) };
      updatedQuote[field] = value;
      updatedQuoteSet.quoteSet[index] = updatedQuote;

      return { ...updatedQuoteSet };
    });
  };

  function updateQuoteSet() {
    const data = {
      id: selectedQuoteSet._id,
      quotesetTitle: editedQuoteSetTitle,
      quoteSet: selectedQuoteSet.quoteSet,
    };
    Vendor()
      .putJson("updateQuoteSet", data)
      .then((response) => {
        getVendor();
      });
  }

  const deleteQuoteSet = () => {
    const data = {
      id: selectedQuoteSet._id,
    };
    Vendor()
      .deleteJson("deleteQuoteSet", data)
      .then((response) => {
        if (response.response.status === 200) {
          setSelectedQuoteSet(null);
          getVendor();
        }
      });
  };

  const createNewQuoteSet = () => {
    const data = {
      quotesetTitle: selectedQuoteSet.quoteSetTitle,
      quoteSet: selectedQuoteSet.quoteSet,
    };
    Vendor()
      .postJson("createQuoteSet", data)
      .then((response) => {
        if (response.response.status === 200) {
          getVendor();
        }
      });
  };

  const deleteSavedQuote = () => {
    const data = {
      quoteId: selectedSavedQuote._id,
    };
    Vendor()
      .deleteJson("deleteSavedQuote", data)
      .then((response) => {
        if (response.response.status === 200) {
          setSelectedSavedQuote(null);
          getVendor();
        }
      });
  };

  const updateSavedQuote = () => {
    const data = {
      quoteId: selectedSavedQuote._id,
      savedQuoteTitle: selectedSavedQuote.savedQuoteTitle,
      quoteCategory: selectedSavedQuote.quoteCategory,
      title: selectedSavedQuote.title,
      description: selectedSavedQuote.description,
      price: selectedSavedQuote.price,
    };
    Vendor()
      .postJson("updateSavedQuote", data)
      .then((response) => {
        getVendor();
      });
  };

  const createNewSavedQuote = () => {
    const data = {
      savedQuoteTitle: selectedSavedQuote.savedQuoteTitle,
      quoteCategory: selectedSavedQuote.quoteCategory,
      title: selectedSavedQuote.title,
      description: selectedSavedQuote.description,
      price: selectedSavedQuote.price,
    };
    Vendor()
      .postJson("createSavedQuote", data)
      .then((response) => {
        if (response.response.status === 200) {
          setSelectedSavedQuote(null);
          getVendor();
        }
      });
  };

  const handleAddQuote = () => {
    setSelectedQuoteSet((prevQuoteSet) => {
      const updatedQuoteSet = { ...prevQuoteSet };
      if (!updatedQuoteSet.quoteSet) {
        updatedQuoteSet.quoteSet = [];
      }
      updatedQuoteSet.quoteSet.push({
        quoteCategory: "",
        quoteTitle: "",
        quotePrice: "",
        quoteDescription: "",
      });
      return { ...updatedQuoteSet };
    });
  };

  const handleRemoveQuote = (indexToRemove) => {
    setSelectedQuoteSet((prevQuoteSet) => {
      const updatedQuoteSet = { ...prevQuoteSet };
      updatedQuoteSet.quoteSet = updatedQuoteSet.quoteSet.filter(
        (_, index) => index !== indexToRemove
      );
      return { ...updatedQuoteSet };
    });
  };

  const handleSavedQuoteSelect = (selectedId) => {
    if (selectedId === "AddNew") {
      setIsNewPreset(true);
      setSelectedSavedQuote({
        savedQuoteTitle: "",
        title: "",
        description: "",
        price: "",
        quoteCategory: "",
      });
      setIsQuoteSetSelected(false);
      setSelectedQuoteSet(null);
      setIsSavedQuoteSelected(true);
    } else {
      setIsNewPreset(false);
      const selectedSavedQuote = savedQuotes.find(
        (quote) => quote._id === selectedId
      );

      setSelectedSavedQuote(selectedSavedQuote);
      setIsSavedQuoteSelected(true);
      setIsQuoteSetSelected(false);
      setSelectedQuoteSet(null);
    }
  };

  const handleCategoryModalOpen = () => {
    setDisplayCategoryModal(true);
  };

  const handleCategoryModalClose = () => {
    setDisplayCategoryModal(false);
  };

  const isQuoteSetValid = () => {
    if (!selectedQuoteSet) return false;

    const quotes = selectedQuoteSet.quoteSet || [];
    for (const quote of quotes) {
      if (
        quote.quoteCategory === "" ||
        quote.quoteCategory === "AddNew" ||
        quote.quoteTitle === "" ||
        quote.quotePrice === "" ||
        quote.quoteDescription === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const isSavedQuoteValid = () => {
    if (!selectedSavedQuote) return false;
    if (
      selectedSavedQuote.savedQuoteTitle === "" ||
      selectedSavedQuote.title === "" ||
      selectedSavedQuote.description === "" ||
      selectedSavedQuote.price === "" ||
      selectedSavedQuote.quoteCategory === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Card
      style={{
        width: "100%",
      }}
    >
      <NewCategoryModal
        open={displayCategoryModal}
        onClose={handleCategoryModalClose}
        updateVendor={getVendor}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  textAlign: "center",
                  marginTop: 1,
                  marginBottom: "10px",
                  height: "2rem",
                  userSelect: "none",
                }}
              >
                Select Quote Collection
              </Typography>
              <CustomSelect
                value={selectedQuoteSet?._id || ""}
                onChange={(e) => handleQuoteSetSelect(e.target.value)}
                options={quoteSets?.map((quoteSet) => ({
                  label: quoteSet.quoteSetTitle,
                  value: quoteSet._id,
                }))}
                variant="outlined"
                fullWidth
                onAddNewClick={handleQuoteSetSelect}
              />
            </div>
            <div>
              <Typography
                variant="body1"
                component="div"
                sx={{
                  textAlign: "center",
                  marginTop: 1,
                  height: "2rem",
                  marginBottom: "10px",
                  userSelect: "none",
                  marginTop: "30px",
                }}
              >
                Select Saved Quote
              </Typography>
              <CustomSelect
                value={selectedSavedQuote?._id || ""}
                onChange={(e) => handleSavedQuoteSelect(e.target.value)}
                options={savedQuotes?.map((quote) => ({
                  label: quote.savedQuoteTitle,
                  value: quote._id,
                }))}
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                onAddNewClick={handleSavedQuoteSelect}
              />
            </div>
          </Grid>
          <Grid item xs={9}>
            {(isQuoteSetSelected && selectedQuoteSet) || selectedSavedQuote ? (
              <>
                {isQuoteSetSelected && selectedQuoteSet && (
                  <>
                    <CustomTextField
                      label="Quote Collection Title"
                      variant="outlined"
                      fullWidth
                      value={editedQuoteSetTitle}
                      onChange={handleQuoteSetTitleChange}
                      sx={{ marginBottom: 2 }}
                    />
                    {selectedQuoteSet.quoteSet.map((quote, index) => (
                      <div key={index}>
                        <Card
                          sx={{
                            width: "100%",
                            height: "100%",
                            border: "1px solid #ccc",
                            marginBottom: "20px",
                            borderRadius: "10px",
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={5}>
                                <CustomSelectWithLabel
                                  label="Quote Category"
                                  value={quote.quoteCategory}
                                  onChange={(e) =>
                                    handleQuoteChange(
                                      index,
                                      "quoteCategory",
                                      e.target.value
                                    )
                                  }
                                  options={
                                    quoteCategories?.length > 0
                                      ? quoteCategories.map((category) => ({
                                          label: category,
                                          value: category,
                                        }))
                                      : []
                                  }
                                  fullWidth
                                  onAddNewClick={handleCategoryModalOpen}
                                  sx={{ marginBottom: 2 }}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <CustomTextField
                                  label="Title"
                                  variant="outlined"
                                  fullWidth
                                  value={quote.quoteTitle}
                                  onChange={(e) =>
                                    handleQuoteChange(
                                      index,
                                      "quoteTitle",
                                      e.target.value
                                    )
                                  }
                                  sx={{ marginBottom: 2 }}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <CustomTextField
                                  label="Price"
                                  variant="outlined"
                                  fullWidth
                                  value={quote.quotePrice}
                                  onChange={(event) => {
                                    const numericInput =
                                      event.target.value.replace(
                                        /[^0-9.]/g,
                                        ""
                                      );
                                    const isValidInput =
                                      /^(\d+)?(\.\d{0,2})?$/.test(numericInput);
                                    if (isValidInput) {
                                      handleQuoteChange(
                                        index,
                                        "quotePrice",
                                        numericInput
                                      );
                                    }
                                  }}
                                  sx={{ marginBottom: 2 }}
                                />
                              </Grid>
                            </Grid>
                            <CustomTextField
                              label="Description"
                              variant="outlined"
                              fullWidth
                              value={quote.quoteDescription}
                              onChange={(e) =>
                                handleQuoteChange(
                                  index,
                                  "quoteDescription",
                                  e.target.value
                                )
                              }
                              sx={{ marginBottom: 2 }}
                            />
                          </CardContent>
                          <div
                            style={{ textAlign: "left", marginLeft: "15px" }}
                          >
                            <DeleteIcon
                              onClick={() => handleRemoveQuote(index)}
                              style={{
                                fontSize: "28px",
                                marginBottom: "10px",
                                color: "red",
                              }}
                            />
                          </div>
                        </Card>
                      </div>
                    ))}
                    <div style={{ textAlign: "left" }}>
                      <AddIcon
                        onClick={handleAddQuote}
                        style={{ color: "green", fontSize: 50 }}
                      />
                    </div>
                  </>
                )}
                {!isQuoteSetSelected && selectedSavedQuote && (
                  <>
                    <CustomTextField
                      label="Saved Quote Title"
                      variant="outlined"
                      fullWidth
                      value={selectedSavedQuote.savedQuoteTitle}
                      onChange={(e) =>
                        setSelectedSavedQuote((prevQuote) => ({
                          ...prevQuote,
                          savedQuoteTitle: e.target.value,
                        }))
                      }
                      sx={{ marginBottom: 2 }}
                    />
                    <Card
                      style={{
                        width: "100%",
                        border: "1px solid #ccc",
                        marginBottom: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={5}>
                            <CustomSelectWithLabel
                              label="Quote Category"
                              value={selectedSavedQuote.quoteCategory}
                              onChange={(e) =>
                                setSelectedSavedQuote((prevQuote) => ({
                                  ...prevQuote,
                                  quoteCategory: e.target.value,
                                }))
                              }
                              options={
                                quoteCategories?.length > 0
                                  ? quoteCategories.map((category) => ({
                                      label: category,
                                      value: category,
                                    }))
                                  : []
                              }
                              fullWidth
                              onAddNewClick={handleCategoryModalOpen}
                              sx={{ marginBottom: 2 }}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomTextField
                              label="Title"
                              variant="outlined"
                              fullWidth
                              value={selectedSavedQuote.title}
                              onChange={(e) =>
                                setSelectedSavedQuote((prevQuote) => ({
                                  ...prevQuote,
                                  title: e.target.value,
                                }))
                              }
                              sx={{ marginBottom: 2 }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CustomTextField
                              label="Price"
                              variant="outlined"
                              fullWidth
                              value={selectedSavedQuote.price}
                              onChange={(e) => {
                                const input = e.target.value;
                                const numericInput = input.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                const decimalParts = numericInput.split(".");
                                if (/^\d*\.?\d{0,2}$/.test(numericInput)) {
                                  const formattedValue =
                                    decimalParts.length > 1
                                      ? `${
                                          decimalParts[0]
                                        }.${decimalParts[1].slice(0, 2)}`
                                      : decimalParts[0];

                                  setSelectedSavedQuote((prevQuote) => ({
                                    ...prevQuote,
                                    price: formattedValue,
                                  }));
                                }
                              }}
                              sx={{ marginBottom: 2 }}
                            />
                          </Grid>
                        </Grid>
                        <CustomTextField
                          label="Description"
                          variant="outlined"
                          fullWidth
                          value={selectedSavedQuote.description}
                          onChange={(e) =>
                            setSelectedSavedQuote((prevQuote) => ({
                              ...prevQuote,
                              description: e.target.value,
                            }))
                          }
                          sx={{ marginBottom: 2 }}
                        />
                      </CardContent>
                    </Card>
                  </>
                )}
              </>
            ) : null}
          </Grid>
        </Grid>
        <div style={{ marginTop: "30px" }}>
          {isQuoteSetSelected &&
            selectedQuoteSet &&
            (isNewPreset ? (
              <div>
                <GreenButton
                  onClick={createNewQuoteSet}
                  disabled={!isQuoteSetValid()}
                >
                  Submit
                </GreenButton>
              </div>
            ) : (
              <div>
                <GreenButton
                  onClick={updateQuoteSet}
                  disabled={!isQuoteSetValid()}
                  sx={{ marginRight: "10px" }}
                >
                  Update Quote Collection
                </GreenButton>
                <RedButton onClick={deleteQuoteSet}>
                  Delete Quote Collection
                </RedButton>
              </div>
            ))}
          {isSavedQuoteSelected &&
            selectedSavedQuote &&
            (isNewPreset ? (
              <GreenButton
                onClick={createNewSavedQuote}
                variant="contained"
                color="primary"
                disabled={!isSavedQuoteValid()}
              >
                Submit
              </GreenButton>
            ) : (
              <div>
                <GreenButton
                  onClick={updateSavedQuote}
                  variant="contained"
                  color="primary"
                  disabled={!isSavedQuoteValid()}
                  sx={{ marginRight: "10px" }}
                >
                  Update Saved Quote
                </GreenButton>
                <RedButton onClick={deleteSavedQuote}>
                  Delete Saved Quote
                </RedButton>
              </div>
            ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default QuoteSettings;

//line 669
