import React, { useState, useEffect } from "react";
import { Customer } from "../../utils/httpUtils";
// import AddAddressModalMobile from "../AddAddressModalMobile";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import CustomCheckbox from "../../components/reusableComponents/Customcheckbox";
import {
  CustomSelectNoAdd,
  CustomTextField,
} from "../../components/reusableComponents/Inputs";
import {
  GreenButton,
  RedButton,
} from "../../components/reusableComponents/Buttons";
import AddAddressModalMobile from "./AddAddressModalMobile";

const EditCustomerModalMobile = ({
  open,
  onClose,
  customer,
  handleUpdateCustomer,
  properties,
}) => {
  const [firstName, setFirstName] = useState(customer?.firstName || "");
  const [lastName, setLastName] = useState(customer?.lastName || "");
  const [email, setEmail] = useState(customer?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(customer?.phoneNumber || "");
  const [isCommercial, setIsCommercial] = useState(
    customer?.isCommercial || false
  );
  const [commercialAccountName, setCommercialAccountName] = useState(
    customer?.commercialAccountName || ""
  );
  const [notes, setNotes] = useState(customer?.notes);
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyId, setPropertyId] = useState(
    formatAddress(properties[0]?.address?.readableAddress) || ""
  );
  const [displayAddAddressModal, setDisplayAddAddressModal] = useState(false);
  const [stateProperties, setStateProperties] = useState(properties || []);

  useEffect(() => {}, [selectedPropertyId]);

  useEffect(() => {
    setStateProperties(properties);
  }, [properties]);

  function handleisCommercialChange(event) {
    setIsCommercial(event.target.checked);

    if (!event.target.checked) {
      setCommercialAccountName("");
    }
  }

  const addToProperties = () => {
    Customer.getJson("getProperties");
  };

  function formatAddress(fullAddress) {
    if (!fullAddress) {
      return "";
    }
    const parts = fullAddress.split(",");
    const formattedParts = parts
      .slice(0, parts.length - 2)
      .concat(parts[parts.length - 2].split(" ").slice(0, -1))
      .join(",")
      .replace(/,+/g, ",")
      .replace(/,(\S)/g, ", $1");

    return formattedParts.trim();
  }

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const openAddAddressModal = () => {
    setDisplayAddAddressModal(true);
  };

  const closeAddAddressModal = () => {
    setDisplayAddAddressModal(false);
  };

  const handleAddAddress = () => {
    const isNewProperty = !stateProperties.some(
      (property) => property._id === selectedPropertyId
    );

    if (isNewProperty) {
      const data = {
        customerId: customer._id,
        propertyId: selectedPropertyId,
      };
      Customer()
        .postJson("addPropertyToCustomer", data)
        .then((response) => {
          if (response.response.status === 200) {
            updateCustomer(false);
          }
        });
      const newProperty = {
        _id: selectedPropertyId,
        address: {
          readableAddress: selectedProperty.address.readableAddress,
        },
      };
      setStateProperties([
        ...stateProperties,
        {
          _id: selectedPropertyId,
          address: {
            readableAddress: selectedProperty.address.readableAddress,
          },
        },
      ]);

      setPropertyId(formatAddress(newProperty.address.readableAddress));
    } else {
      console.log("Property already registered to this customer");
    }
  };

  const removeSelectedAddress = () => {
    const updatedProperties = stateProperties.filter(
      (property) =>
        formatAddress(property.address.readableAddress) !== propertyId
    );

    setStateProperties(updatedProperties);
    if (updatedProperties.length > 0) {
      setPropertyId(
        formatAddress(updatedProperties[0].address.readableAddress)
      );
    }
  };

  const removeProperty = (propertyId) => {
    const data = {
      customerId: customer._id,
      propertyId,
    };

    Customer()
      .postJson("deletePropertyFromCustomer", data)
      .then((response) => {
        if (response.response.status === 200) {
          const updatedProperties = stateProperties.filter(
            (property) => property._id !== propertyId
          );

          setStateProperties(updatedProperties);
          if (updatedProperties.length > 0) {
            setPropertyId(
              formatAddress(updatedProperties[0].address.readableAddress)
            );
          }
          updateCustomer(false);
        }
      });
  };

  const updateCustomer = (shouldClose = true) => {
    const updatedPropertyIds = stateProperties.map((property) => property._id);
    const data = {
      customerId: customer._id,
      updateData: {
        firstName,
        lastName,
        email,
        phoneNumber,
        // properties: updatedPropertyIds,
        isCommercial,
        commercialAccountName,
        notes,
      },
    };

    Customer()
      .postJson("updateCustomer", data)
      .then((response) => {
        handleUpdateCustomer();
        if (shouldClose) {
          onClose();
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <AddAddressModalMobile
        open={displayAddAddressModal}
        onClose={closeAddAddressModal}
        setSelectedPropertyId={setSelectedPropertyId}
        handleAddAddress={handleAddAddress}
        setSelectedProperty={setSelectedProperty}
      />
      <DialogTitle sx={{ userSelect: "none" }}>Edit Customer</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <CustomTextField
              label="First Name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Last Name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Phone Number"
              value={formatPhoneNumber(phoneNumber)}
              onChange={(event) =>
                setPhoneNumber(formatPhoneNumber(event.target.value))
              }
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12}>
            {stateProperties?.length > 1 ? (
              <Grid container spacing={2}>
                {stateProperties.map((property, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container alignItems="center">
                      <Grid item xs={1.5}>
                        <DeleteIcon
                          onClick={() => removeProperty(property._id)}
                          style={{
                            fontSize: "40px",
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                      <Grid item xs={10.5}>
                        <Typography
                          sx={{
                            fontSize: 16,
                            marginLeft: "10px",
                            wordWrap: "break-word",
                            flexGrow: 1,
                          }}
                        >
                          {formatAddress(property.address.readableAddress)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <AddIcon
                        onClick={openAddAddressModal}
                        style={{
                          fontSize: "40px",
                          cursor: "pointer",
                          color: "#4f8f2f",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography sx={{ fontSize: 20 }}>
                          {formatAddress(
                            stateProperties[0]?.address.readableAddress
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <DeleteIcon
                              onClick={removeSelectedAddress}
                              style={{
                                fontSize: "40px",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <AddIcon
                      onClick={openAddAddressModal}
                      style={{
                        fontSize: "40px",
                        cursor: "pointer",
                        color: "#4f8f2f",
                        marginLeft: "-5px",
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isCommercial}
                  onChange={handleisCommercialChange}
                />
              }
              label="Commercial Client"
              sx={{ marginBottom: "10px" }}
            />
            {isCommercial && (
              <CustomTextField
                label="Account Name"
                autoComplete="off"
                value={commercialAccountName}
                onChange={(event) =>
                  setCommercialAccountName(event.target.value)
                }
                fullWidth
                sx={{ marginBottom: "10px" }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <RedButton onClick={onClose} variant="contained" color="primary">
          Close
        </RedButton>
        <GreenButton
          onClick={() => {
            updateCustomer();
          }}
          variant="contained"
          color="primary"
        >
          Submit
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditCustomerModalMobile;

// Below is code to make the address dropdown, I think I want the addresses to be their own rows instead, with their own deleteIcon and an addicon at the bottom of the list
{
  /* <Grid container spacing={2} alignItems="center">
<Grid item xs={12}>
  <CustomSelectNoAdd
    value={propertyId}
    onChange={(event) => {
      setPropertyId(event.target.value);
    }}
    options={stateProperties?.map((property) => ({
      label: formatAddress(property.address.readableAddress),
      value: formatAddress(property.address.readableAddress),
    }))}
    variant="outlined"
    fullWidth
  />
</Grid>
<Grid item xs={1}>
  <DeleteIcon
    onClick={removeSelectedAddress}
    style={{ fontSize: "40px", cursor: "pointer" }}
  />
</Grid>
<Grid item xs={1}>
  <AddIcon
    onClick={openAddAddressModal}
    style={{
      fontSize: "40px",
      cursor: "pointer",
      color: "#4f8f2f",
    }}
  />
</Grid>
</Grid> */
}
