import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { Vendor } from "../../utils/httpUtils";
import SideNavbar from "../../components/vendorComponents/SideNavbar";
import EmailSettings from "../../components/vendorComponents/EmailSettings";
import LeadSourceSettings from "../../components/vendorComponents/LeadSourceSettings";
import CouponSettings from "../../components/vendorComponents/CouponSettings";
import ReviewSettings from "../../components/vendorComponents/ReviewSettings";

import { useParams } from "react-router-dom";
import QuoteSettings from "../../components/vendorComponents/QuoteSettings";
import PaymentSettings from "../../components/vendorComponents/PaymentSettings";

function Settings() {
  const { selectedOption } = useParams();
  const [error, setError] = useState("");
  const [settings, setSettings] = useState({});
  const options = [
    "Lead Source",
    "Coupons",
    "Email Settings",
    "Review Settings",
    "Quote Settings",
    "Payment Settings",
  ];

  useEffect(() => {
    getVendorSettings();
  }, []);

  const getVendorSettings = () => {
    Vendor()
      .getJson("getVendorSettings")
      .then((response) => {
        if (response?.response?.status === 200) {
          setSettings(response.data.settings);
        } else {
          setError("error communicating with server");
        }
      });
  };

  const renderComponent = () => {
    switch (selectedOption) {
      case "lead-source":
        return (
          <LeadSourceSettings
            settings={settings}
            updateSettings={getVendorSettings}
          />
        );

      case "email-settings":
        return <EmailSettings settings={settings} />;

      case "coupons":
        return <CouponSettings />;

      case "review-settings":
        return (
          <ReviewSettings
            settings={settings}
            updateSettings={getVendorSettings}
          />
        );
      case "payment-settings":
        return (
          <PaymentSettings
            settings={settings}
            updateSettings={getVendorSettings}
          />
        );
      case "quote-settings":
        return (
          <QuoteSettings
          // settings={settings}
          // updateSettings={getVendorSettings}
          />
        );

      default:
        return (
          <LeadSourceSettings
            settings={settings}
            updateSettings={getVendorSettings}
          />
        );
    }
  };

  return (
    <div>
      <VendorNavbar />
      <Grid container style={{ minHeight: "100vh", marginTop: "-70px" }}>
        <Grid item xs={1.7}>
          <SideNavbar options={options} />
        </Grid>
        <Grid item xs={10}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12}>
              <div id="vendorDashboard">{renderComponent()}</div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Settings;
