import React, { useState } from "react";
import { GreenButton } from "../reusableComponents/Buttons";
import { Typography, TextField, Grid } from "@mui/material";

import { Vendor } from "../../utils/httpUtils";
import CustomSwitch from "../reusableComponents/Customswitch";

function PaymentSettings({ settings, updateSettings }) {
  const [updatingToggle, setUpdatingToggle] = useState(false);

  const [taxRate, setTaxRate] = useState(null);
  const [paymentTermCommercial, setPaymentTermCommercial] = useState(null);
  const [paymentTermResidential, setPaymentTermResidential] = useState(null);

  const handleUpdateTaxRate = () => {
    const data = {
      collectsTax: settings.settings.collectsTax,
      taxRate: taxRate,
    };

    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      })
      .catch((error) => {
        console.error("Error updating lead source:", error);
      });
  };

  const updateCollectsTax = () => {
    const data = {
      collectsTax: !settings.settings.collectsTax,
    };
    // if (!data.collectsTax) {
    //     console.log("inside")
    //     setTaxRate(null)
    // }
    // console.log("collectsTax: ", taxRate)
    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      });
  };

  const updateVendor = (field, value) => {
    const data = {
      [field]: value,
    };
    Vendor()
      .putJson("updateSettings", data)
      .then((response) => {
        updateSettings();
      })
      .catch((error) => {
        console.error("Error updating lead source:", error);
      });
  };

  return (
    <div id="vendorDashboard">
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "50px",
        }}
      >
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "20px" }}
            >
              Include tax rate calculation in invoicing and payment
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <CustomSwitch
              checked={settings?.settings?.collectsTax}
              onChange={() => updateCollectsTax()}
              disabled={updatingToggle}
            />
          </Grid>

          {/* Second Row */}
          <Grid
            item
            container
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              marginTop: "20px",
            }}
          >
            <TextField
              label={"tax rate"}
              variant="outlined"
              value={taxRate}
              style={{ marginRight: "10px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
              onChange={(e) => setTaxRate(e.target.value)}
            />
            <GreenButton
              variant="contained"
              onClick={handleUpdateTaxRate}
              disabled={taxRate?.length < 1 || taxRate === null}
              sx={{
                height: "56px",
                width: "90px",
                marginLeft: "10px",
              }}
            >
              {settings?.settings?.taxRate ? "Update" : "Add"}
            </GreenButton>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            {settings?.settings?.collectsTax ? (
              <Typography
                variant="body1"
                component="div"
                sx={{ userSelect: "none", fontSize: "30px" }}
              >
                {settings?.settings?.taxRate && settings.settings.taxRate} %
              </Typography>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "30px", marginTop: "30px" }}
            >
              Payment Terms
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "20px" }}
            >
              Commercial (defaults to net-30)
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <TextField
              label={"commercial"}
              variant="outlined"
              value={paymentTermCommercial}
              style={{ marginRight: "10px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
              onChange={(e) => setPaymentTermCommercial(e.target.value)}
            />
            <GreenButton
              variant="contained"
              onClick={() =>
                updateVendor("paymentTermCommercial", paymentTermCommercial)
              }
              disabled={
                paymentTermCommercial?.length < 1 ||
                paymentTermCommercial === null
              }
              sx={{
                height: "56px",
                width: "90px",
                marginLeft: "10px",
              }}
            >
              {settings?.settings?.paymentTermCommercial ? "Update" : "Submit"}
            </GreenButton>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "30px" }}
            >
              {settings?.settings?.paymentTermCommercial &&
                `NET-${settings.settings.paymentTermCommercial}`}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "20px" }}
            >
              Residential (defaults to net-7)
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <TextField
              label={"residential"}
              variant="outlined"
              value={paymentTermResidential}
              style={{ marginRight: "10px" }}
              InputProps={{ sx: { borderRadius: "8px" } }}
              onChange={(e) => setPaymentTermResidential(e.target.value)}
            />
            <GreenButton
              variant="contained"
              onClick={() =>
                updateVendor("paymentTermResidential", paymentTermResidential)
              }
              disabled={
                paymentTermResidential?.length < 1 ||
                paymentTermResidential === null
              }
              sx={{
                height: "56px",
                width: "90px",
                marginLeft: "10px",
              }}
            >
              {settings?.settings?.paymentTermResidential ? "Update" : "Add"}
            </GreenButton>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{ userSelect: "none", fontSize: "30px" }}
            >
              {settings?.settings?.paymentTermResidential &&
                `NET-${settings.settings.paymentTermResidential}`}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PaymentSettings;
