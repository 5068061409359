import React from 'react'
import { Container, Typography, Divider } from '@mui/material';
import CommonNavbar from '../../components/commonComponents/CommonNavbar';

export default function LearnMore() {
  return (
    <>
      <CommonNavbar />
    <Container
    sx={{
      bgcolor: '#e0eae659',
      minHeight: '100vh'
    }}>
      <Typography
            variant="h6"
            sx={{
              fontFamily: 'monospace',
              fontWeight: 900,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              paddingTop: 3,
              paddingBottom: 2,
              fontSize: 35
            }}
          >
            What is Plaid?
      </Typography>
    <Divider/>
    <Typography
      variant="body1"
      sx={{
        display: { xs: 'none', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 300,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        fontSize: 22
      }}
    >
      With Plaid, there is often no need to contact a business to book a service.
    </Typography>
    <Divider/>
    <Typography
      variant="body1"
      sx={{
        display: { xs: 'none', md: 'flex' },
        fontFamily: 'monospace',
        fontWeight: 300,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        fontSize: 22
       }}
    >
      Simply log in, select from the available quotes at your address (you can always add more), and book. Simple.
    </Typography>
    <Divider
      sx={{
        // display: { xs: 'none'}
      }}
    />
    <Typography
      variant="body1"
      sx={{
        display: { xs: 'none', md: 'flex' },
        height: '100',
        fontFamily: 'monospace',
        fontWeight: 300,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        fontSize: 22
      }}
    >
      Book a service from a pre-existing quote, or request a Plaid quote from your favorite business. Plaid will manage all
      of your home and property maintenance needs. No spam calls or emails, we never sell your information.
    </Typography>
    </Container>
    </>
  )
}