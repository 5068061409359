import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Property, Vendor, Customer } from "../../../utils/httpUtils";
import CurrentQuotesAtAddress from "../../../components/vendorComponents/CurrentQuotesAtAddress";
import JobHistoryAtAddress from "../../../components/vendorComponents/JobHistoryAtAddress";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import AddSingleQuote from "../../../components/vendorComponents/AddSingleQuote";
import AddQuoteSet from "../../../components/vendorComponents/AddQuoteSet";
import RecentJobsAtAddress from "../../../components/vendorComponents/RecentJobsAtAddress";
import CustomersAtAddress from "../../../components/vendorComponents/CustomersAtAddress";

function AddressDesktop() {
  const { pid } = useParams();
  const [quotes, setQuotes] = useState();
  const [property, setProperty] = useState();
  const [jobs, setJobs] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [vendor, setVendor] = useState();
  const [quoteCategories, setQuoteCategories] = useState();
  const [savedQuotes, setSavedQuotes] = useState();
  const [quoteSets, setQuoteSets] = useState();
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getPropertyData();
  }, [pid]);

  useEffect(() => {
    getVendor();
  }, []);

  useEffect(() => {
    if (property) {
      setIsLoading(false);
    }
  }, [property]);

  useEffect(() => {
    getCustomersAtAddress();
  }, []);

  const getCustomersAtAddress = () => {
    const data = {
      propertyId: pid,
    };
    Customer()
      .getJson("getCustomersAtAddress", data)
      .then((response) => {
        setCustomers(response.data.customers);
      });
  };

  const getPropertyData = () => {
    const data = {
      propertyId: pid,
    };
    Property()
      .getJson("getPropertyForVendor", data)
      .then((response) => {
        setProperty(response.data.property);
        setQuotes(response.data.quotes);
        setJobs(response.data.jobs);
      });
  };

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
        setSavedQuotes(response.data.savedQuotes);
        setQuoteCategories(response.data.quoteCategories);
        setQuoteSets(response.data.quoteSet);
      });
  };

  const updateProperty = async () => {
    getPropertyData();
  };

  const updateVendor = () => {
    getVendor();
  };

  return (
    <div>
      <VendorNavbar />
      <div id="vendorDashboard">
        {isLoading ? (
          <div style={{ marginTop: "10px", marginBottom: "45px" }}>
            <LoadingIcon />
          </div>
        ) : (
          <Typography
            variant="h4"
            component="div"
            sx={{ marginBottom: 5, marginTop: 3 }}
          >
            {property?.address.number} {property?.address.street},{" "}
            {property?.address.city}, {property?.address.state}
          </Typography>
        )}
        <Grid container columnSpacing={4}>
          <Grid item xs={4}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <CurrentQuotesAtAddress
                  quotes={quotes}
                  propertyId={property?._id}
                  updateProperty={updateProperty}
                  getCustomersAtAddress={getCustomersAtAddress}
                />
              </Grid>
              <Grid item>
                <CustomersAtAddress propertyId={pid} customers={customers} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <RecentJobsAtAddress
                  jobs={jobs}
                  updateProperty={updateProperty}
                  updateVendor={updateVendor}
                />
              </Grid>
              <Grid item>
                <AddSingleQuote
                  savedQuotes={savedQuotes}
                  quoteCategories={quoteCategories}
                  propertyId={property?._id}
                  updateProperty={updateProperty}
                  updateVendor={updateVendor}
                />
              </Grid>
              <Grid item>
                <AddQuoteSet
                  quoteSets={quoteSets}
                  quoteCategories={quoteCategories}
                  updateProperty={updateProperty}
                  updateVendor={updateVendor}
                  property={property}
                />
              </Grid>
              <Grid item>
                <JobHistoryAtAddress
                  jobs={jobs}
                  updateProperty={updateProperty}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AddressDesktop;
