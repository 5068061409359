import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';


// Desktop
import QuotesDesktop from "./desktop/QuotesDesktop";


// Mobile
import QuotesMobile from "./mobile/QuotesMobile";

function Quotes() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
    {isMobile ? (
      <QuotesMobile />
    ) : (
      <QuotesDesktop />
    )}
    </>
  );
    }

export default Quotes;
