import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Service } from "../../../utils/httpUtils";
import LoadingIcon from "../../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { formatDate, formatAddressNoZip } from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  Card,
  CardContent,
} from "@mui/material";
import { TableHeader } from "../../reusableComponents/Typography";

const RecurringJobsDesktop = () => {
  const [recurringServices, setRecurringServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getRecurringJobs();
  }, []);

  useEffect(() => {
    getRecurringJobs();
  }, []);

  const getRecurringJobs = () => {
    Service()
      .getJson("getRecurring")
      .then((response) => {
        setRecurringServices(response.data);
        setIsLoading(false);
      });
  };

  const goToCustomer = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  return (
    <Card
      style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Upcoming Recurring Services
        </Typography>
        {/* <Typography
          variant="h7"
          component="div"
          sx={{ marginBottom: 3, userSelect: "none" }}
        >
          Click to View or Schedule Service
        </Typography> */}

        <TableContainer
          component={Paper}
          style={{ marginBottom: "16px", userSelect: "none" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableHeader>Address</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Last Performed</TableHeader>
                </TableCell>
                <TableCell>
                  <TableHeader>Suggested Next Date</TableHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                recurringServices?.map((service) => (
                  <ClickableTableRow
                    key={service._id}
                    onClick={() => goToCustomer(service.customerId)}
                  >
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatAddressNoZip(service.readableAddress)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatDate(service.recurring?.lastPerformed)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatDate(service.recurring?.recommendedNext)}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default RecurringJobsDesktop;
