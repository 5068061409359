import React from "react";
import { Container, Divider, Button } from "@mui/material";
import CommonNavbar from "../commonComponents/CommonNavbar";
import { Link } from "react-router-dom";

function PaymentSmall(props) {
  const { job } = props;
  return (
    <>
      <CommonNavbar />
      <Container
        sx={{
          bgcolor: "#e0eae659",
          height: "100%",
        }}
      >
        <Container>
          <Divider />
          <img
            src={`${job?.vendor?.logo}`}
            style={{ maxWidth: "100%", width: "50vw", height: "auto" }}
          />

          {job && (
            <div className={`vendorInfoContainerSmall`}>
              <div className={`vendorInfoSmall`}>
                {job?.vendor?.companyName}
              </div>
              <div>{job?.vendor?.email}</div>
              <div>{job?.vendor?.phoneNumber}</div>
            </div>
          )}
          <Divider />
          <div className="job-services-table">
            <table>
              <thead>
                <tr>
                  <th className="service-column">Service</th>
                  <th className="price-column">Price</th>
                </tr>
              </thead>
              <tbody>
                {job.services?.map((service, index) => (
                  <tr key={index}>
                    <td className="service-column">{service.name}</td>
                    <td className="price-column">{service.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button
            variant="contained"
            sx={{
              marginTop: 7,
            }}
          >
            <Link
              to={`/learn`}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              Pay Now
            </Link>
          </Button>
        </Container>
      </Container>
    </>
  );
}

export default PaymentSmall;
