import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import { Invoice } from "../../utils/httpUtils";
import { useNavigate } from "react-router-dom";
import {
  formatAddress,
  formatAddressNoZip,
  formatStatus,
  formatDateStrict,
  formatDateTime,
} from "../../utils/utils";
import { theme } from "../../App";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  styled,
} from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { CustomTextField } from "../../components/reusableComponents/Inputs";
import { TableHeader } from "../../components/reusableComponents/Typography";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("invoiceNumber");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("invoiceNumber");
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = () => {
    Invoice()
      .getJson("getInvoicesForVendor")
      .then((response) => {
        setInvoices(response.data.invoices);
        setIsLoading(false);
      });
  };

  const handleSort = (column) => {
    setSortOrder((prevOrder) =>
      column === sortColumn ? (prevOrder === "asc" ? "desc" : "asc") : "asc"
    );
    setSortColumn(column);
  };

  const sortedInvoices = () => {
    const filtered = filteredInvoices();
    return [...filtered].sort((a, b) => {
      let aValue;
      let bValue;

      switch (sortColumn) {
        case "invoiceNumber":
          aValue = a.invoiceNumber;
          bValue = b.invoiceNumber;
          break;
        case "customerName":
          aValue = `${a.customerFirst} ${a.customerLast}`;
          bValue = `${b.customerFirst} ${b.customerLast}`;
          break;
        case "invoiceDate":
          aValue = a.dateCompleted ? new Date(a.dateCompleted).getTime() : 0;
          bValue = b.dateCompleted ? new Date(b.dateCompleted).getTime() : 0;
          break;
        case "paymentAmount":
          aValue = a.totalPrice;
          bValue = b.totalPrice;
          break;
        case "paymentStatus":
          aValue = a.paymentStatus;
          bValue = b.paymentStatus;
          break;
        case "paymentDue":
          aValue = a.paymentDue ? new Date(a.paymentDue).getTime() : 0;
          bValue = b.paymentDue ? new Date(b.paymentDue).getTime() : 0;
          break;
        case "address":
          aValue = a.address ? formatAddress(a.address.readableAddress) : "";
          bValue = b.address ? formatAddress(b.address.readableAddress) : "";
          break;
        default:
          aValue = "";
          bValue = "";
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortOrder === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
      }
    });
  };

  // const handleFilterOptionChange = (event) => {
  //   setSearchText("");
  //   setFilterOption(event.target.value);
  // };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredInvoices = () => {
    const lowerSearchText = searchText.toLowerCase();

    return invoices.filter((invoice) => {
      if (!invoice) {
        return;
      }
      const fullName = `${invoice.customerFirst ?? ''} ${invoice.customerLast ?? ''}`.toLowerCase();
      const phoneNumber = invoice.customerId?.phoneNumber?.toLowerCase() ?? '';
      const email = invoice.customerId?.email?.toLowerCase() ?? '';
      const status = formatStatus(invoice.paymentStatus ?? '').toLowerCase();
      const invoiceNum = invoice.invoiceNumber?.toString().toLowerCase() ?? '';
      const address = formatAddress(invoice.address?.readableAddress ?? '').toLowerCase();
      const invoiceDate = invoice.dateCompleted ? formatDateStrict(invoice.dateCompleted).toLowerCase() : null;
      const paymentDue = invoice.paymentDue ? formatDateStrict(invoice.paymentDue).toLowerCase() : null;
      const totalPrice = invoice.totalPrice ? invoice.totalPrice.toString() : null;

      return (
        fullName.includes(lowerSearchText) ||
        phoneNumber.includes(lowerSearchText) ||
        email.includes(lowerSearchText) ||
        status.includes(lowerSearchText) ||
        invoiceNum.includes(lowerSearchText) ||
        address.includes(lowerSearchText) ||
        paymentDue.includes(lowerSearchText) ||
        invoiceDate.includes(lowerSearchText) ||
        totalPrice?.includes(lowerSearchText)
      );
    });
  };

  const goToInvoice = (invoiceId) => {
    navigate(`/invoice/${invoiceId}`);
  };

  const getSortIcon = (column) => {
    const sortIconStyle = { color: theme.palette.greenButton.main };

    if (sortColumn === column) {
      return sortOrder === "asc" ? (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowUp style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      ) : (
        <SortIconWrapper style={sortIconStyle}>
          <KeyboardArrowDown style={{ fontSize: "35px" }} />
        </SortIconWrapper>
      );
    }
    return null;
  };

  const SortIconWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginLeft: "15px",
    color: "inherit",
    fontSize: "inherit",
  });

  return (
    <>
      <VendorNavbar />
      {console.log("invoices: ", invoices)}
      <div style={{ margin: "40px" }}>
        {/* <Typography
          variant="h6"
          component="div"
          sx={{ marginBottom: 2, userSelect: "none" }}
        >
          Click invoice to see more information
        </Typography> */}
        <div style={{ marginBottom: "16px" }}>
          <CustomTextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchTextChange}
          />
        </div>
        <TableContainer component={Paper} style={{ marginBottom: "16px" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ userSelect: "none", width: "100%" }}>
                <TableCell
                  onClick={() => handleSort("invoiceNumber")}
                  sx={{ width: "12%" }}
                >
                  <TableHeader sortable>
                    Invoice # {getSortIcon("invoiceNumber")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("customerName")}
                  sx={{ width: "18%" }}
                >
                  <TableHeader sortable>
                    Customer {getSortIcon("customerName")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("invoiceDate")}
                  sx={{ maxWidth: "12%" }}
                >
                  <TableHeader>
                    Invoice Date {getSortIcon("invoiceDate")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("paymentAmount")}
                  sx={{ width: "10%" }}
                >
                  <TableHeader sortable>
                    Total {getSortIcon("paymentAmount")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("paymentStatus")}
                  sx={{ width: "14%" }}
                >
                  <TableHeader sortable>
                    Payment {getSortIcon("paymentStatus")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("paymentDue")}
                  sx={{ width: "10%" }}
                >
                  <TableHeader sortable>
                    Due {getSortIcon("paymentDue")}
                  </TableHeader>
                </TableCell>
                <TableCell
                  onClick={() => handleSort("address")}
                  sx={{ width: "20%" }}
                >
                  <TableHeader sortable>
                    Address {getSortIcon("address")}
                  </TableHeader>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                sortedInvoices().map((invoice) => (
                  <ClickableTableRow
                    key={invoice._id}
                    onClick={() => goToInvoice(invoice._id)}
                  >
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {invoice.invoiceNumber}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {`${invoice.customerFirst} ${invoice.customerLast}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {invoice.dateCompleted
                          ? formatDateStrict(invoice.dateCompleted)
                          : "Not Scheduled"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {`$ ${invoice.totalPrice}`}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatStatus(invoice.paymentStatus)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatDateStrict(invoice.paymentDue)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ fontSize: "18px" }}>
                        {formatAddressNoZip(invoice.address.readableAddress)}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default Invoices;
