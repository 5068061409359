import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIcon = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40px' }}>
      <CircularProgress style={{ color: '#FF5A41' }} />
    </div>
  );
};

export default LoadingIcon;