import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoadingIcon from "../commonComponents/LoadingIcon";

const BestSellingJobsComponent = ({ bestSelling, bestSellingLoading }) => {
  const [isLoading, setIsLoading] = useState(bestSellingLoading);

  useEffect(() => {
    if (bestSelling !== undefined) {
      setIsLoading(false);
    }
  }, [bestSelling, bestSellingLoading]);

  return (
    <Card style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}>
      <CardContent>
        <Typography variant="h4" component="div" sx={{ marginBottom: 1, userSelect: "none" }}>
          Best Selling Services
        </Typography>

        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Table>
            <TableHead>
              <TableRow sx={{userSelect: "none"}}>
                <TableCell sx={{ fontWeight: "bold", fontSize: "22px" }}>
                  Service
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "22px" }}>
                  Average Price
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: "22px" }}>
                  Total Sales
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{userSelect: "none"}}>
              {bestSelling &&
                bestSelling?.length > 0 &&
                bestSelling?.map((service, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: "18px" }}>{service.quoteCategory}</TableCell>
                    <TableCell
                      sx={{ fontSize: "18px" }}
                    >{`$${service.averagePrice.toFixed(2)}`}</TableCell>
                    <TableCell
                      sx={{ fontSize: "18px" }}
                    >{`${service.occurrences}`}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default BestSellingJobsComponent;
