import React, { useState } from "react";

const VendorContext = React.createContext();

export const VendorProvider = (props) => {
  const [vendor, setVendor] = useState();

  return (
    <VendorContext.Provider
      value={{
        vendor,
        setVendor,
      }}
    >
      {props.children}
    </VendorContext.Provider>
  );
};
export default VendorContext;
