import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';

const CustomCheckbox = ({ checked, onChange, color = 'primary', style = {}, sx: customStyles = {}, ...rest }) => {
  const theme = useTheme();

  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      color={color}
      {...rest}
      sx={{
        ...customStyles, // Merge custom styles with default styles
        '& .MuiSvgIcon-root': {
          color: checked ? theme.palette.green.main : null,
        },
        // transform: `scale(1.5)`, // Default scale value
        ...style, // Apply positional styles
      }}
    />
  );
};

export default CustomCheckbox;
