import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Quote } from "../../utils/httpUtils";
import NewCategoryModal from "../../modals/NewCategoryModal";
import CreateSavedQuoteModal from "../../modals/CreateSavedQuoteModal";
import { GreenButton } from "../reusableComponents/Buttons";
import {
  CustomSelect,
  CustomSelectWithLabel,
  CustomTextField,
} from "../reusableComponents/Inputs";

const AddSingleQuote = (props) => {
  const navigate = useNavigate();
  const [savedQuoteTitle, setSavedQuoteTitle] = useState("");
  const [quoteCategory, setQuoteCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [quoteCategories, setQuoteCategories] = useState();
  const [savedQuotes, setSavedQuotes] = useState(props.savedQuotes || []);
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const [displayCreateSavedQuoteModal, setDisplayCreateSavedQuoteModal] =
    useState(false);
  const [propertyId, setPropertyId] = useState(props.propertyId);

  useEffect(() => {
    setSavedQuotes(props.savedQuotes);
  }, [props.savedQuotes]);

  useEffect(() => {
    setQuoteCategories(props.quoteCategories);
  }, [props.quoteCategories]);

  useEffect(() => {
    setPropertyId(props.propertyId);
  }, [props.propertyId]);

  const handleCategoryModalOpen = () => {
    setDisplayCategoryModal(true);
  };

  const handleCategoryModalClose = () => {
    setDisplayCategoryModal(false);
  };

  const handleSavedQuoteModalOpen = () => {
    setDisplayCreateSavedQuoteModal(true);
  };

  const handleSavedQuoteModalClose = () => {
    setDisplayCreateSavedQuoteModal(false);
  };

  const handleSavedQuoteSelect = (selectedTitle) => {
    const selectedSavedQuote = savedQuotes.find(
      (quote) => quote.savedQuoteTitle === selectedTitle
    );
    console.log(selectedSavedQuote);
    setSavedQuoteTitle(selectedSavedQuote?.savedQuoteTitle || "");
    setTitle(selectedSavedQuote?.title || "");
    setPrice(selectedSavedQuote?.price || "");
    setDescription(selectedSavedQuote?.description || "");
    setQuoteCategory(selectedSavedQuote?.quoteCategory || "");
  };

  const addQuote = () => {
    const data = {
      quoteCategory,
      price,
      title,
      description,
      propertyId,
    };
    Quote()
      .postJson("addQuote", data)
      .then((response) => {
        if (response.response.status === 200) {
          props.updateProperty();
          return;
        } else {
          console.log("error creating new quote");
        }
      });
  };

  return (
    <Card
      style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ textAlign: "center", marginBottom: 2, userSelect: "none" }}
        >
          Add Single Quote
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                textAlign: "center",
                marginTop: 1,
                marginBottom: 2,
                userSelect: "none",
              }}
            >
              Select Saved Quote
            </Typography>

            <CustomSelect
              value={savedQuoteTitle}
              onChange={(e) => handleSavedQuoteSelect(e.target.value)}
              options={savedQuotes?.map((quote) => ({
                label: quote.savedQuoteTitle,
                value: quote.savedQuoteTitle,
              }))}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              onAddNewClick={handleSavedQuoteModalOpen}
            />
          </Grid>
          <Grid item xs={9}>
            <Card
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <CustomSelectWithLabel
                      label="Quote Category"
                      value={quoteCategory}
                      onChange={(e) => setQuoteCategory(e.target.value)}
                      options={
                        quoteCategories?.length > 0
                          ? quoteCategories.map((category) => ({
                              label: category,
                              value: category,
                            }))
                          : []
                      }
                      fullWidth
                      onAddNewClick={handleCategoryModalOpen}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomTextField
                      label="Price"
                      variant="outlined"
                      fullWidth
                      value={price}
                      onChange={(e) => {
                        const numericInput = e.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );
                        const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(
                          numericInput
                        );

                        if (isValidInput) {
                          setPrice(numericInput);
                        }
                      }}
                      sx={{ marginBottom: 2 }}
                    />
                  </Grid>
                </Grid>
                <CustomTextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{ marginBottom: 2 }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div style={{ marginTop: "30px" }}>
          <GreenButton
            onClick={addQuote}
            disabled={
              quoteCategory.length === 0 ||
              title.length === 0 ||
              description.length === 0 ||
              price.length === 0
            }
          >
            Add Quote
          </GreenButton>
        </div>
      </CardContent>
      <NewCategoryModal
        open={displayCategoryModal}
        onClose={handleCategoryModalClose}
        updateVendor={props.updateVendor}
      />
      <CreateSavedQuoteModal
        open={displayCreateSavedQuoteModal}
        onClose={handleSavedQuoteModalClose}
        updateVendor={props.updateVendor}
        displayCategoryModal={displayCategoryModal}
        handleCategoryModalOpen={handleCategoryModalOpen}
        closeCategoryModal={handleCategoryModalClose}
        quoteCategories={quoteCategories}
      />
    </Card>
  );
};

export default AddSingleQuote;
