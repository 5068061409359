import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../../commonComponents/LoadingIcon";
import { useNavigate } from "react-router-dom";
import {
  formatDateTime,
  determineName,
} from "../../../utils/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import { MobileTableHeader } from "../../reusableComponents/Typography";

const UpcomingJobsMobile = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState(props.jobs);
  const [upcomingJobs, setUpcomingJobs] = useState([]);
  const navigate = useNavigate();

  const ClickableTableRow = styled(TableRow)({
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  });

  useEffect(() => {
    setJobs(props.jobs);
  }, [props.jobs]);

  useEffect(() => {
    collectUpcomingJobs();
  }, [jobs]);

  const collectUpcomingJobs = () => {
    const upcoming = [];
    if (jobs?.length === 0) {
      setUpcomingJobs(null);
      return;
    }
    jobs?.forEach((job) => {
      if (job.jobStatus !== "CANCELLED" && job.jobStatus !== "COMPLETED" && job.jobStatus !== "REQUESTED") {
        if (isUpcomingJob(job)) {
          upcoming.push(job);
        }
      }
    });
    setUpcomingJobs(upcoming);
    setIsLoading(false);
  };

  const isUpcomingJob = (job) => {
    const jobScheduledDate = new Date(job.scheduledDate);
    const today = new Date();

    const nextSevenDays = new Date();
    nextSevenDays.setDate(today.getDate() + 7);

    return jobScheduledDate >= today && jobScheduledDate <= nextSevenDays;
  };

  const goToJob = (jobId) => {
    navigate(`/job/${jobId}`);
  };

  return (
    <Card style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px", marginTop: "-50px", marginBottom: "30px" }}>
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Upcoming Jobs
        </Typography>
        {/* <Typography
          variant="h7"
          component="div"
          sx={{ marginBottom: 3, userSelect: "none" }}
        >
          Click to View Job
        </Typography> */}
        <TableContainer
          component={Paper}
          style={{ marginBottom: "16px", userSelect: "none", width: "100%", overflowX: "auto" }} // Add styles for responsiveness
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <MobileTableHeader>Scheduled</MobileTableHeader>
                </TableCell>
                <TableCell>
                  <MobileTableHeader>Customer</MobileTableHeader>
                </TableCell>
                {/* <TableCell>
                  <MobileTableHeader>Phone</MobileTableHeader>
                </TableCell>
                <TableCell>
                  <MobileTableHeader>Address</MobileTableHeader>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LoadingIcon />
                  </TableCell>
                </TableRow>
              ) : (
                upcomingJobs?.map((job) => (
                  <ClickableTableRow
                    key={job._id}
                    onClick={() => goToJob(job._id)}
                  >
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        {formatDateTime(job.scheduledDate)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        {determineName(job.customer)}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        {formatPhoneNumber(job.customer.phoneNumber)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        {job.address.readableAddress
                          ? formatAddressNoZip(job.address.readableAddress)
                          : null}
                      </Typography>
                    </TableCell> */}
                  </ClickableTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default UpcomingJobsMobile;
