import React from 'react';
import Switch from '@mui/material/Switch';
import { useTheme } from '@mui/material/styles';

const CustomSwitch = ({ checked, onChange, disabled }) => {
  const theme = useTheme();

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      sx={{
        '& .MuiSwitch-thumb': {
          backgroundColor: checked ? theme.palette.green.main : "gray",
        },
        '& .MuiSwitch-track': {
          backgroundColor: checked ? theme.palette.green.light : theme.palette.red.light,
        },
      }}
    />
  );
};

export default CustomSwitch;
