import React, { useEffect, useState, useContext } from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { Vendor, Auth, Job } from "../../../utils/httpUtils";
import UserContext from "../../../context/UserContext";
import VendorContext from "../../../context/VendorContext";
import Box from "@mui/material/Box";
import UpcomingJobsMobile from "../../../components/vendorComponents/mobile/UpcomingJobsMobile";
import RequestedJobsMobile from "../../../components/vendorComponents/mobile/RequestedJobsMobile";
import RecurringJobsMobile from "../../../components/vendorComponents/mobile/RecurringJobsMobile";

function VendorDashboardMobile() {
  const [jobs, setJobs] = useState();
  const { user, setUser } = useContext(UserContext);
  const { vendor, setVendor } = useContext(VendorContext);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (!vendor) {
      getVendor();
    }
  }, [vendor]);

  useEffect(() => {
    getAllJobsForVendor();
  }, []);

  const getUser = () => {
    Auth()
      .getJson("getUser")
      .then((response) => {
        if (response?.response?.status === 200) {
          setUser(response.data.data);
        } else {
          setError("error communicating with server");
        }
      });
  };

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        if (response?.response?.status === 200) {
          setVendor(response.data);
        } else {
          setError("error communicating with server");
        }
      });
  };

  const getAllJobsForVendor = () => {
    Job()
      .getJson("getAllJobsForVendor")
      .then((response) => {
        if (response?.response?.status === 200) {
          setJobs(response.data.jobs);
        } else {
          setError("error communicating with server");
        }
      });
  };

  return (
    <div>
      <VendorNavbar />
      <Box p={1}>
        <UpcomingJobsMobile jobs={jobs} />
        <RequestedJobsMobile />
        {/* <RecurringJobsMobile /> */}
      </Box>
    </div>
  );
}

export default VendorDashboardMobile;
