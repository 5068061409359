import React from "react";
import Typography from "@mui/material/Typography";

const CardHeader = (props) => {
  return (
    <Typography
      sx={{
        variant: "h5",
        userSelect: "none",
        fontWeight: "700",
        marginBottom: "28px",
        fontSize: "28px",
        ...props.sx,
      }}
      {...props}
    />
  );
};

const TableHeader = ({ children, sortable }) => {
  return (
    <Typography
      variant="subtitle1"
      component="div"
      sx={{
        fontSize: 22,
        color: "#252F3D",
        fontWeight: "700",
        userSelect: "none",
        cursor: sortable ? "pointer" : "default",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      }}
      // onClick={handleClick}
    >
      {children}
    </Typography>
  );
};

const MobileTableHeader = ({ children, sortable }) => {
  return (
    <Typography
      variant="subtitle1"
      component="div"
      sx={{
        fontSize: 21,
        color: "#252F3D",
        fontWeight: "700",
        userSelect: "none",
        cursor: sortable ? "pointer" : "default",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      }}
    >
      {children}
    </Typography>
  );
};

export { CardHeader, TableHeader, MobileTableHeader };
