import React, { useState, useEffect, useRef } from "react";
import { Vendor } from "../utils/httpUtils";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { GreenButton, RedButton } from "../components/reusableComponents/Buttons";

const NewCategoryModal = ({ open, onClose, currentLogo, updateVendor }) => {
  const [logo, setLogo] = useState(currentLogo || null);
  const fileInputRef = useRef(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [newLogo, setNewLogo] = useState(null);

  useEffect(() => {
    if (currentLogo) {
      setLogo(currentLogo);
    }
  }, [currentLogo]);

  useEffect(() => {
    setLogoPreview(false);
  }, [open]);

  const handleFileChange = (e) => {
    setNewLogo(null);
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setNewLogo(selectedFile);
      const reader = new FileReader();
      reader.onload = (event) => {
        setLogoPreview(event.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUploadLogo = async () => {
    try {
      const formData = new FormData();
      formData.append("logo", newLogo);

      const response = await Vendor().postJson("uploadLogo", formData);

      if (response.response.status === 200) {
        updateVendor();
        onClose();
        return;
      } else {
        console.log("error updating logo");
      }
    } catch {
      console.log("error");
    }
  };

  const handleCustomButtonClick = () => {
    // Trigger the click event on the hidden file input
    fileInputRef.current.click();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logo ? (
          <img
            src={logoPreview ? logoPreview : logo}
            alt="Vendor Logo"
            style={{ maxWidth: 300, marginBottom: 16 }}
          />
        ) : null}

        {logoPreview && (
          <img
            src={logoPreview}
            alt="Vendor Logo"
            style={{ maxWidth: 300, marginBottom: 16 }}
          />
        )}

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: "none" }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleCustomButtonClick}
          style={{ margin: "16px 0" }}
        >
          Choose File
        </Button>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginBottom: "20px" }}>
        <GreenButton onClick={handleUploadLogo} variant="contained" color="primary">
          Submit
        </GreenButton>
        <RedButton onClick={onClose} variant="contained" color="primary">
          Close
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewCategoryModal;
