import React, { useState, useEffect } from "react";
import Autocomplete from "../components/commonComponents/AutoComplete";
import { Customer } from "../utils/httpUtils";
import { formatPhoneNumber } from "../utils/utils";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { CustomTextField } from "../components/reusableComponents/Inputs";
import CustomCheckbox from "../components/reusableComponents/Customcheckbox";

const AddNewCustomerModal = ({ open, onClose, updateVendor }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isCommercial, setIsCommercial] = useState(false);
  const [commercialAccountName, setCommercialAccountName] = useState("");
  const [selectedPropertyId, setSelectedPropertyId] = useState();
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState("");

  useEffect(() => {
    if (!open) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setIsCommercial(false);
      setCommercialAccountName("");
      setSelectedPropertyId("");
      setEmailValidation(false);
    }
  }, [open]);

  function handleisCommercialChange(event) {
    setIsCommercial(event.target.checked);

    if (!event.target.checked) {
      setCommercialAccountName("");
    }
  }

  const createNewCustomer = () => {
    if (!email || email.length === 0) {
      setEmailValidationMessage("Email is required");
      setEmailValidation(true);
      return;
    }
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      propertyId: selectedPropertyId,
      isCommercial,
      commercialAccountName,
    };
    Customer()
      .postJson("createNewCustomer", data)
      .then((response) => {
        if (response.response.status === 400) {
          setEmailValidation(true);
          setEmailValidationMessage(response.response.data.message);
        } else {
          updateVendor();
          onClose();
        }
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ userSelect: "none" }}>Create New Customer</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <CustomTextField
              label="First Name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Last Name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Email"
              error={emailValidation}
              id="outlined-error"
              helperText={emailValidation ? emailValidationMessage : null}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(event) =>
                setPhoneNumber(formatPhoneNumber(event.target.value))
              }
              fullWidth
              autoComplete="off"
              sx={{ marginBottom: "10px" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete setSelectedPropertyId={setSelectedPropertyId} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isCommercial}
                  onChange={handleisCommercialChange}
                />
              }
              label="Commercial Client"
              sx={{ marginBottom: "10px" }}
            />
            {isCommercial && (
              <CustomTextField
                label="Account Name"
                autoComplete="off"
                value={commercialAccountName}
                onChange={(event) =>
                  setCommercialAccountName(event.target.value)
                }
                fullWidth
                sx={{ marginBottom: "10px" }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <RedButton onClick={onClose} variant="contained" color="primary">
          Close
        </RedButton>
        <GreenButton
          onClick={() => {
            createNewCustomer();
          }}
        >
          Submit
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewCustomerModal;
