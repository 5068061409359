import React, { useState, useEffect, useContext } from "react";
import { Vendor } from "../../utils/httpUtils";
import VendorContext from "../../context/VendorContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  CustomTextField,
  CustomSelectWithLabel,
} from "../../components/reusableComponents/Inputs";
import { GreenButton, RedButton } from "../../components/reusableComponents/Buttons";

const CreateSavedQuoteModalMobile = ({
  open,
  onClose,
  updateVendor,
  handleCategoryModalOpen,
  quoteCategories,
}) => {
  const [savedQuote, setSavedQuote] = useState({
    savedQuoteTitle: "",
    title: "",
    description: "",
    price: "",
    quoteCategory: "",
  });
  const [vendorState, setVendorState] = useState();
  const [editMode, setEditMode] = useState(false);
  const [savedQuoteId, setSavedQuoteId] = useState("");
  const { vendor, setVendor } = useContext(VendorContext);

  useEffect(() => {
    getVendor();
  }, [vendor]);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        if (response?.response?.status === 200) {
          setVendorState(response.data);
        } else {
          console.log("error communicating with server");
        }
      });
  };

  function handleQuoteChange(field, value) {
    setSavedQuote({
      ...savedQuote,
      [field]: value,
    });
  }

  function submitSavedQuote() {
    Vendor()
      .postJson("createSavedQuote", savedQuote)
      .then((response) => {
        if (response.error) {
          console.error("API Error: ", response.error);
        } else {
          updateVendor();
          onClose();
        }
      });
  }

  function deleteSavedQuote() {
    if (savedQuoteId) {
      const data = {
        id: savedQuoteId,
      };
      Vendor()
        .deleteJson("deleteSavedQuote", data)
        .then((response) => {
          if (response.error) {
            console.error("API Error: ", response.error);
          } else {
            onClose();
          }
        });
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Saved Quote</DialogTitle>
      <DialogContent>
        <CustomTextField
          sx={{ marginTop: "10px", marginBottom: 2 }}
          label="Saved Quote Title"
          value={savedQuote.savedQuoteTitle}
          onChange={(event) =>
            handleQuoteChange("savedQuoteTitle", event.target.value)
          }
          fullWidth
        />

        <CustomSelectWithLabel
          label="Quote Category"
          value={savedQuote.quoteCategory}
          onChange={(e) => handleQuoteChange("quoteCategory", e.target.value)}
          options={
            quoteCategories?.length > 0
              ? quoteCategories.map((category) => ({
                  label: category,
                  value: category,
                }))
              : []
          }
          fullWidth
          onAddNewClick={handleCategoryModalOpen}
          sx={{ marginBottom: 2 }}
        />

        <CustomTextField
          label="Quote Title"
          value={savedQuote.title}
          onChange={(event) => handleQuoteChange("title", event.target.value)}
          fullWidth
          sx={{
            marginBottom: "10px",
          }}
        />
        <CustomTextField
          label="Quote Description"
          value={savedQuote.description}
          onChange={(event) =>
            handleQuoteChange("description", event.target.value)
          }
          fullWidth
          sx={{
            marginBottom: "10px",
          }}
        />

        <CustomTextField
          label="Price"
          value={savedQuote.price}
          onChange={(event) => {
            const numericInput = event.target.value.replace(/[^0-9.]/g, "");
            const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(numericInput);
            if (isValidInput) {
              handleQuoteChange("price", numericInput);
            }
          }}
          fullWidth
          sx={{
            marginBottom: "10px",
          }}
        />
      </DialogContent>
      <DialogActions>
        

        <GreenButton onClick={submitSavedQuote} variant="contained" color="primary">
          {editMode ? "Update Saved Quote" : "Submit"}
        </GreenButton>
        
        {editMode && (
          <RedButton
            onClick={deleteSavedQuote}
            variant="contained"
            color="secondary"
          >
            Delete Saved Quote
          </RedButton>
        )}
        <RedButton onClick={onClose} variant="contained" color="primary">
          Close
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSavedQuoteModalMobile;
