import React, { useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { ApiProvider } from "./context/ApiContext";
import UserContext from "./context/UserContext";
import LearnMore from "./pages/commonPages/LearnMore";
import Payment from "./pages/clientPages/Payment";
import Quotes from "./pages/clientPages/Quotes";
import TrackJob from "./pages/clientPages/TrackJob";
import NotFound from "./pages/commonPages/NotFound";
import LoginPage from "./pages/commonPages/LoginPage";
import VendorDashboard from "./pages/vendorPages/vendorDashboard";
import Register from "./pages/vendorPages/Register";
import Account from "./pages/vendorPages/Account";
import EditProfile from "./pages/vendorPages/EditProfile";
import Job from "./pages/vendorPages/Job";
import VendorQuotes from "./pages/vendorPages/Quotes";
import Address from "./pages/vendorPages/Address";
import ManageQuotes from "./pages/vendorPages/ManageQuotes";
import Jobs from "./pages/vendorPages/Jobs";
import Profile from "./pages/vendorPages/Profile";
import ManageAccount from "./pages/vendorPages/ManageAccount";
import AccountHelp from "./pages/vendorPages/AccountHelp";
import Customers from "./pages/vendorPages/Customers";
import Customer from "./pages/vendorPages/Customer";
import AllCustomerJobs from "./pages/vendorPages/AllCustomerJobs";
import Service from "./pages/vendorPages/Service";
import Coupons from "./pages/vendorPages/Coupons";
import Emails from "./pages/vendorPages/Emails";
import Invoices from "./pages/vendorPages/Invoices";
import Settings from "./pages/vendorPages/Settings";
import Invoice from "./pages/vendorPages/desktop/Invoice";
import AdminDashboard from "./pages/adminPages/AdminDashboard";
import Vendors from "./pages/adminPages/Vendors";
import Vendor from "./pages/adminPages/Vendor";

const theme = createTheme({
  palette: {
    primary: {
      // main: "#444444",
      main: "#252F3D",
      light: "#FF8369",
      dark: "#14181f",
      contrastText: "white",
    },
    secondary: {
      main: "tomato",
      light: "#00FF00",
      dark: "#006600",
      contrastText: "white",
    },
    green: {
      main: "#4f8f2f",
      dark: "#396627",
      contrastText: "white",
    },
    red: {
      main: "#bf0423",
      dark: "#6e0113",
      contrastText: "white",
    },
    greenButton: {
      main: "#4f8f2f",
      dark: "#396627",
      contrastText: "white",
      fontFamily: 'Roboto, sans-serif', // Override button font specifically if needed
      fontWeight: 'bold', // Example of setting font weight to bold
      fontSize: "15px",
      borderRadius: "20px"
    }
  },
  typography: {
    fontFamily: "Arial, sans-serif",
    // fontFamily: "Helvetica, sans-serif",
  },
  tableHeader: {
    main: "#252F3D",
    fontFamily: 'Roboto, sans-serif', // Override button font specifically if needed
    fontWeight: 'bold', // Example of setting font weight to bold
    fontSize: "20px"
  }
});

export { theme };

//#006600

function App() {

const { user } = useContext(UserContext)

useEffect(() => {
  isAdmin()
}, [user]);
const isAdmin = () => localStorage.getItem('userRole') === "PLAIDMIN";

  return (
    <>
      <ThemeProvider theme={theme}>
        <ApiProvider>
          {/* <UserProvider> */}
            {/* <VendorProvider> */}
              <Router>
                <div className="App">
                  {
                    <Routes>

                        {/* Plaidmin routes */}
                        {isAdmin() ? (
                          <>
                            <Route path="/admin" element={<AdminDashboard />} />
                            <Route path="/vendors" element={<Vendors />} />
                            <Route path="/vendor/:vendorId" element={<Vendor />} />
                     
                          </>
                        ) : (
                          <>
                          <Route path="/" element={<LoginPage />}></Route>
                          </>
                        )}
                      {/* Plaidmin routes */}


                      <Route path="/" element={<LoginPage />}></Route>
                      <Route path="/learn" element={<LearnMore />}></Route>
                      <Route path="/emails" element={<Emails />}></Route>
                      {/* <Route path="/login" element={<LoginPage />}></Route> */}
                      <Route path="/job/:id" element={<Job />}></Route>
                      <Route path="/address/:pid" element={<Address />}></Route>
                      <Route
                        path="/dashboard"
                        element={<VendorDashboard />}
                      ></Route>
                      <Route path="/account" element={<Account />}></Route>
                      <Route
                        path="account/profile"
                        element={<Profile />}
                      ></Route>
                      <Route
                        path="account/manage"
                        element={<ManageAccount />}
                      ></Route>
                      <Route path="customers" element={<Customers />}></Route>
                      <Route path="coupons" element={<Coupons />}></Route>
                      <Route
                        path="customers/:id"
                        element={<Customer />}
                      ></Route>
                      <Route
                        path="services/:serviceId"
                        element={<Service />}
                      ></Route>
                      <Route
                        path="customers/:id/jobs"
                        element={<AllCustomerJobs />}
                      ></Route>
                      <Route
                        path="/account/editprofile"
                        element={<EditProfile />}
                      ></Route>
                      <Route
                        path="/account/help"
                        element={<AccountHelp />}
                      ></Route>
                      <Route path="/quotes" element={<VendorQuotes />}></Route>
                      <Route
                        path="/quotes/manage"
                        element={<ManageQuotes />}
                      ></Route>
                      <Route path="/jobs" element={<Jobs />}></Route>
                      <Route path="/invoices/" element={<Invoices />}></Route>
                      <Route path="/invoice/:invoiceId" element={<Invoice />}></Route>
                      {/* <Route path="/quote/:id" element={<EditQuote />}></Route> */}
                      <Route path="/presets" element={<EditProfile />}></Route>
                      <Route path="/register" element={<Register />}></Route>
                      <Route path="/settings/:selectedOption?" element={<Settings />}></Route>
                      <Route
                        path="/quotes/:requestId/:emailId?"
                        element={<Quotes />}
                      ></Route>{" "}
                      <Route path="/payment/:id" element={<Payment />} />{" "}
                      {/* :id here is the jobId */}
                      <Route path="/track/:id" element={<TrackJob />} />{" "}
                      {/* :id here is the jobId */}
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  }
                </div>
              </Router>
            {/* </VendorProvider> */}
          {/* </UserProvider> */}
        </ApiProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
// {isAdmin() ? (
//   <>
//     <Route path="/dashboard" element={<VendorDashboard />} />
//     <Route path="/account" element={<Account />} />
//     <Route path="/account/profile" element={<Profile />} />
//     <Route path="/account/manage" element={<ManageAccount />} />
//     {/* Other admin routes */}
//   </>
// ) : (
//   <Navigate to="/dashboard" replace />
// )}