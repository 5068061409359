import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

const SideNavbar = ({ onSelect, options }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate(`/settings/${item.toLowerCase().replace(" ", "-")}`);
  };

  return (
    <div
      style={{
        width: 200,
        minHeight: "100%",
        // backgroundColor: '#edebe6',
        backgroundColor: "#f4f4ffd9",
        // borderRight: '1px solid #e3e1d6'
        borderRight: "1px solid #eaeaf4d9",
      }}
    >
      <List>
        {options.map((text, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              selected={selectedItem === text}
              onClick={() => handleItemClick(text)}
              sx={{
                borderRadius: "8px",
                width: "90%",
                margin: "auto",
                marginBottom: "5px",
              }}
            >
              <ListItemText primary={text} />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default SideNavbar;
