import React, { useState } from "react";
import { Vendor } from "../utils/httpUtils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  CustomTextField,
  CustomSelectWithLabel,
} from "../components/reusableComponents/Inputs";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";

const CreateSavedQuoteModal = ({
  open,
  onClose,
  updateVendor,
  handleCategoryModalOpen,
  quoteCategories,
}) => {
  const [savedQuote, setSavedQuote] = useState({
    savedQuoteTitle: "",
    quoteCategory: "",
    title: "",
    description: "",
    price: "",
  });
  const [quoteCategory, setQuoteCategory] = useState("");

  function handleQuoteChange(field, value) {
    setSavedQuote({
      ...savedQuote,
      [field]: value,
    });
  }

  function submitSavedQuote() {
    Vendor()
      .postJson("createSavedQuote", savedQuote)
      .then((response) => {
        if (response.error) {
          console.error("API Error: ", response.error);
        } else {
          updateVendor();
          onClose();
        }
      });
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Saved Quote</DialogTitle>
      <DialogContent>
        <CustomTextField
          sx={{ marginTop: "10px", marginBottom: "10px" }}
          label="Saved Quote Title"
          value={savedQuote.savedQuoteTitle}
          onChange={(event) =>
            handleQuoteChange("savedQuoteTitle", event.target.value)
          }
          fullWidth
        />

        <CustomSelectWithLabel
          label="Quote Category"
          value={savedQuote.quoteCategory}
          onChange={(e) => handleQuoteChange("quoteCategory", e.target.value)}
          options={
            quoteCategories?.length > 0
              ? quoteCategories.map((category) => ({
                  label: category,
                  value: category,
                }))
              : []
          }
          fullWidth
          onAddNewClick={handleCategoryModalOpen}
          sx={{ marginBottom: 1 }}
        />
        <CustomTextField
          label="Quote Title"
          value={savedQuote.title}
          onChange={(event) => handleQuoteChange("title", event.target.value)}
          fullWidth
          sx={{
            marginBottom: "10px",
          }}
        />
        <CustomTextField
          label="Quote Description"
          value={savedQuote.description}
          onChange={(event) =>
            handleQuoteChange("description", event.target.value)
          }
          fullWidth
          sx={{
            marginBottom: "10px",
          }}
        />

        <CustomTextField
          label="Price"
          value={savedQuote.price}
          onChange={(event) => {
            const numericInput = event.target.value.replace(/[^0-9.]/g, "");
            const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(numericInput);
            if (isValidInput) {
              handleQuoteChange("price", numericInput);
            }
          }}
          fullWidth
          sx={{
            marginBottom: "10px",
          }}
        />
      </DialogContent>
      <DialogActions>
        <RedButton onClick={onClose}>Close</RedButton>
        <GreenButton onClick={submitSavedQuote}>Submit</GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateSavedQuoteModal;
