import React, { useEffect, useState } from "react";
import VendorNavbar from "../../../components/vendorComponents/VendorNavbar";
import { useParams, useNavigate } from "react-router-dom";
import { Job as JobRoute } from "../../../utils/httpUtils";
import {
  formatDateTime,
  formatAddress,
  formatStatus,
} from "../../../utils/utils";
import {
  GreenButton,
  RedButton,
} from "../../../components/reusableComponents/Buttons";
import CustomCheckbox from "../../../components/reusableComponents/Customcheckbox";
import {
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
} from "@mui/material";
import LoadingIcon from "../../../components/commonComponents/LoadingIcon";
import ScheduleJobModal from "../../../modals/ScheduleJobModal";
import RecurringServiceModal from "../../../modals/RecurringServiceModal";
import CancelRecurringModal from "../../../modals/CancelRecurringModal";
import EditIcon from "@mui/icons-material/Edit";
import { TableHeader } from "../../../components/reusableComponents/Typography";
import ManualPaymentModal from "../../../modals/ManualPaymentModal";

function JobDesktop() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showScheduleJobModal, setShowScheduleJobModal] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [customer, setCustomer] = useState();
  const [selectedService, setSelectedService] = useState(null);
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [displayPaymentModal, setDisplayPaymentModal] = useState(false);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    isAdmin()
    if (isAdmin()) {
    } else {
    }
  }, []);
  const isAdmin = () => localStorage.getItem('userRole') === "KEVIN" || localStorage.getItem('userRole') === "PLAIDMIN";

  useEffect(() => {
    getJob();
  }, []);

  useEffect(() => {
    if (job) {
      setPropertyId(job.propertyId);
      setIsLoading(false);
    }
  }, [job]);

  useEffect(() => {
    if (job) {
      setCustomerId(job.customerId);
    }
  }, [job]);

  const getJob = () => {
    const data = {
      jobId: id,
    };
    JobRoute()
      .getJson("getJobForVendor", data)
      .then((response) => {
        setJob(response.data.job);
        setServices(response.data.services);
        setCustomer(response.data.customer);
      });
  };

  const scheduleJob = () => {
    setShowScheduleJobModal(true);
  };

  const handleCheckboxChange = (service) => {
    if (service.recurring?.isRecurring) {
      setSelectedService(service);
      setShowCancelRecurring(true);
    } else {
      setSelectedService(service);
      setShowRecurringServiceModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowScheduleJobModal(false);
    setShowRecurringServiceModal(false);
  };

  const handleUpdate = () => {
    getJob();
  };

  const updateStatus = (value) => {
    const data = {
      jobId: id,
      jobStatus: value,
    };
    JobRoute()
      .postJson("updateJobStatus", data)
      .then((response) => {
        getJob();
      });
  };

  const cancelJob = () => {
    console.log("cancel job");
  };

  const goToAddress = () => {
    navigate(`/address/${propertyId}`);
  };

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const goToCustomer = () => {
    navigate(`/customers/${customer._id}`);
  };

  const openPaymentModal = () => {
    setDisplayPaymentModal(true);
  };

  const handleClosePaymentModal = () => {
    setDisplayPaymentModal(false);
  };

  const goToInvoice = (jobId) => {
    navigate(`/invoice/${job.invoiceId}`);
  };

  const uploadBeforeImages = async () => {
    console.log("files: ", files)
    try {
      const data = new FormData();
      // Convert files object into an array
      const filesArray = Object.values(files);
      filesArray.forEach(file => {
        data.append("images", file);
      });
      data.append("jobId", id);
      data.append("type", "before");
  
      const response = await JobRoute().postJson("uploadJobImages", data);
      console.log("Upload response:", response);
      // Add any handling after successful upload
    } catch (error) {
      console.error("Error uploading images:", error);
      // Handle upload error
    }
};


  const handleFileChange = (e) => {
    const files = e.target.files;
    setFiles(files)
    // setFiles([...files, e.target.value])
  };
  

  return (
    <div>
      <VendorNavbar />
      {/* {console.log("isAdmin: ", isAdmin())} */}
      {/* Below works; just commented out until it's fully implemented */}
        {/* <input type="file" id="fileInput" onChange={handleFileChange} multiple /> */}
      

      <ScheduleJobModal
        open={showScheduleJobModal}
        onClose={handleCloseModal}
        jobId={job?._id}
        handleUpdate={handleUpdate}
      />
      <ManualPaymentModal
        open={displayPaymentModal}
        onClose={handleClosePaymentModal}
        job={job}
      />
      <RecurringServiceModal
        open={showRecurringServiceModal}
        onClose={handleCloseRecurringModal}
        service={selectedService}
        handleUpdate={handleUpdate}
        jobId={id}
        propertyId={propertyId}
        customerId={customer?._id}
        scheduledDate={job?.scheduledDate}
      />
      <CancelRecurringModal
        open={showCancelRecurring}
        onClose={handleCloseCancelRecurringModal}
        service={selectedService}
        handleUpdate={handleUpdate}
      />

      {job ? (
        <div style={{ width: "70%", margin: "0 auto" }}>
          <Card
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "10px",
              marginTop: "30px",
              textAlign: "left",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                sx={{ marginBottom: "28px", textDecoration: "underline" }}
              >
                Job Details
                <GreenButton
                  onClick={() => goToInvoice(job._id)}
                  sx={{ marginLeft: "30px" }}
                  disabled={!job.invoiceId}
                >
                  View Invoice
                </GreenButton>

                {/* {isAdmin() ? <GreenButton onClick={uploadBeforeImages}>Upload images</GreenButton> : null} */}
                {/* Below works; just commented out until it's tested on prod */}
                {/* <GreenButton onClick={uploadBeforeImages} sx={{marginLeft: "10px"}}>Upload images</GreenButton> */}
              </Typography>

              {/* <Grid> */}
              <div style={{ width: "100%" }}>
                <Typography
                  variant="h5"
                  onClick={goToAddress}
                  sx={{
                    // color: "blue",
                    // textDecoration: "underline",
                    marginBottom: "30px",
                  }}
                >
                  {job.address.readableAddress
                    ? formatAddress(job.address.readableAddress)
                    : null}
                  <GreenButton sx={{ marginLeft: "20px" }}>
                    Go To Address
                  </GreenButton>
                </Typography>
              </div>

              {/* </Grid> */}

              <Box sx={{ marginBottom: "20px", marginLeft: "15px" }}>
                <Grid container spacing={2}>
                  <Typography variant="h5">
                    Customer Name: {customer?.firstName} {customer?.lastName}
                  </Typography>
                  <GreenButton
                    variant="contained"
                    color="primary"
                    onClick={goToCustomer}
                    sx={{ marginLeft: "20px" }}
                  >
                    View Customer
                  </GreenButton>
                </Grid>
              </Box>

              <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                Customer Email: {customer?.email}
              </Typography>

              <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                Customer Phone: {formatPhoneNumber(customer.phoneNumber)}
              </Typography>

              <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                Scheduled Date:{" "}
                {job?.scheduledDate
                  ? formatDateTime(job.scheduledDate)
                  : "Not Scheduled"}
              </Typography>

              <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                Job Status:{" "}
                <span style={{ color: "black" }}>
                  {job.jobStatus && formatStatus(job.jobStatus)}
                </span>
              </Typography>

              <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                Total Price: ${job?.totalPrice && job?.totalPrice?.toFixed(2)}
              </Typography>

              {job.upsell > 0 && (
                <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                  Upsell Amount: ${job?.upsell?.toFixed(2)}
                </Typography>
              )}

              <Typography variant="h5" sx={{ marginBottom: "28px" }}>
                Date Requested:{" "}
                {job?.dateRequested && formatDateTime(job?.dateRequested)}
              </Typography>

              <Typography variant="h5">
                <Typography
                  variant="h4"
                  component="div"
                  sx={{ textDecoration: "underline" }}
                >
                  Services
                </Typography>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "25%" }}>
                          <TableHeader>Service</TableHeader>
                        </TableCell>
                        <TableCell sx={{ width: "17%" }}>
                          <TableHeader>Price</TableHeader>
                        </TableCell>
                        <TableCell sx={{ width: "18%" }}>
                          <TableHeader>Recurring</TableHeader>
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          <TableHeader>Frequency</TableHeader>
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          <TableHeader>
                          Last Performed
                          </TableHeader>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {services?.map((service, index) => (
                        <TableRow key={index}>
                          <TableCell>{service.quoteTitle}</TableCell>
                          <TableCell>
                            ${service?.quotePrice?.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <div style={{ marginLeft: "-10px" }}>
                              <CustomCheckbox
                                checked={!!service.recurring?.isRecurring}
                                onChange={() => handleCheckboxChange(service)}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            {service.recurring?.recurringFrequency
                              ? `${service.recurring.recurringFrequency} ${service.recurring.recurringUnit}`
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {service.recurring?.lastPerformed
                              ? formatDateTime(service.recurring.lastPerformed)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>

              <Box sx={{ marginTop: "20px" }}>
                <GreenButton
                  variant="contained"
                  color="primary"
                  onClick={scheduleJob}
                  sx={{ marginRight: "20px" }}
                >
                  Schedule Job
                </GreenButton>
                <GreenButton
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus("COMPLETED")}
                  sx={{ marginRight: "20px" }}
                  disabled={job.jobStatus === "COMPLETED"}
                >
                  Mark as Completed
                </GreenButton>
                {/* <GreenButton
                  variant="contained"
                  color="primary"
                  onClick={() => openPaymentModal()}
                  sx={{ marginRight: "20px" }}
                >
                  Add Payment
                </GreenButton> */}
                <RedButton
                  variant="contained"
                  color="primary"
                  onClick={() => updateStatus("CANCELLED")}
                  sx={{ marginRight: "20px" }}
                >
                  Cancel Job
                </RedButton>
              </Box>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div style={{ marginTop: "300px" }}>
          <LoadingIcon />
        </div>
      )}
    </div>
  );
}

export default JobDesktop;
