import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import DateTimePickerMobile from "../../components/commonComponents/mobile/DatePickerMobile";
import { Job } from "../../utils/httpUtils";
import {
  GreenButton,
  RedButton,
} from "../../components/reusableComponents/Buttons";

const ScheduleJobModalMobile = ({
  open,
  onClose,
  jobId,
  handleUpdate,
  customer,
  serviceIds,
  propertyId,
  quoteIds,
  handleUpdateDate = null,
}) => {
  const [selectedDate, setSelectedDate] = useState();

  const handleDateChange = (date) => {
    updateSelectedDate(date);
  };

  const updateSelectedDate = (date) => {
    setSelectedDate(date);
    if (handleUpdateDate) {
      handleUpdateDate(date);
    }
  };

  const scheduleJob = () => {
    if (jobId) {
      let data;
      if (customer) {
        const data = {
          serviceIds,
          customer,
          propertyId,
          quoteIds,
          userId: customer.userId,
          selectedDate,
        };
        Job()
          .postJson("scheduleJob", data)
          .then((response) => {
            handleUpdate(response.data.job._id);
            onClose();
          });
      } else {
        const data = {
          jobId,
          selectedDate,
        };
        Job()
          .postJson("scheduleJob", data)
          .then((response) => {
            handleUpdate();
            onClose();
          });
      }
    } else {
      onClose();
      return;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "100vw",
            height: "450px",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DateTimePickerMobile
          updateSelectedDate={(date) => updateSelectedDate(date)}
          onDateChange={handleDateChange}
        />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <GreenButton onClick={scheduleJob} variant="contained" color="primary">
          Schedule
        </GreenButton>
        <RedButton onClick={onClose} variant="contained" color="primary">
          Close
        </RedButton>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleJobModalMobile;
