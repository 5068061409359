import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LoadingIcon from "../commonComponents/LoadingIcon";

const UpsellData = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [upsell, setUpsell] = useState(props.upsellData || {});

  useEffect(() => {
    setUpsell(props.upsellData);
  }, [props.upsellData]);

  useEffect(() => {
    if (upsell) {
      setIsLoading(false);
    }
  }, [upsell]);

  return (
    <Card style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}>
      <CardContent>
        <Typography variant="h4" component="div" sx={{ marginBottom: 1, userSelect: "none" }}>
          Upsell Data
        </Typography>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Typography variant="h6" color="text.secondary" sx={{userSelect: "none"}}>
            {upsell === undefined ? (
              "You do not have any upsell data"
            ) : (
              <>
                Your total upsell with Plaid is{" "}
                <span style={{ color: "green" }}>${upsell.toFixed(2)}</span>
              </>
            )}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default UpsellData;
