import React from "react";
// import { Service } from "../utils/httpUtils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

const TextMessageModalMobile = ({
  open,
  onClose,
  requestLink
}) => {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2, userSelect: "none" }}>
          Link to quotes
        </Typography>

        <Typography variant="body1" component="div" sx={{ marginBottom: 2, fontSize: "10px" }}>
          {requestLink}
        </Typography>
        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>

          <Button
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default TextMessageModalMobile;
