import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Autocomplete from "../commonComponents/AutoComplete";
import { useNavigate } from "react-router-dom";

const NewQuote = () => {
  const [selectedPropertyId, setSelectedPropertyId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedPropertyId) {
      goToAddress();
    }
  }, [selectedPropertyId]);

  const goToAddress = () => {
    navigate(`/address/${selectedPropertyId}`);
  };

  return (
    <Card
      style={{ width: "100%", maxHeight: "100vh", border: "1px solid #ccc", borderRadius: "10px" }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          sx={{ marginBottom: 1, userSelect: "none" }}
        >
          Enter Address
        </Typography>
        <Autocomplete setSelectedPropertyId={setSelectedPropertyId} />
      </CardContent>
    </Card>
  );
};

export default NewQuote;