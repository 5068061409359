import React, { useEffect, useState } from "react";
import VendorNavbar from "../../components/vendorComponents/VendorNavbar";
import LoadingIcon from "../../components/commonComponents/LoadingIcon";
import { Quote, Vendor } from "../../utils/httpUtils";
import BulkEditQuotesModal from "../../modals/BulkEditQuotesModal";
import ConfirmBulkEditModal from "../../modals/ConfirmBulkEditModal";
import { GreenButton } from "../../components/reusableComponents/Buttons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { TableHeader } from "../../components/reusableComponents/Typography";

function ManageQuotes() {
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState();
  const [quotes, setQuotes] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    field: null,
    isDescending: false,
  });
  const [filterOption, setFilterOption] = useState("filterOption");
  const [searchText, setSearchText] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [edits, setEdits] = useState();

  const [currentlyRenderedQuotes, setCurrentlyRenderedQuotes] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);

  useEffect(() => {
    const fetchQuotes = async () => {
      try {
        const response = await Quote().getJson("getAllQuotesByVendor");
        setQuotes(response.data);
        setCurrentlyRenderedQuotes(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching quotes:", error);
        setLoading(false);
      }
    };

    fetchQuotes();
  }, []);

  useEffect(() => {
    updateSelectedQuotes();
  }, [quotes]);

  const updateSelectedQuotes = () => {
    quotes?.map((quote) => {});
  };

  const refreshQuotes = () => {
    Quote()
      .getJson("getAllQuotesByVendor")
      .then((response) => {
        setQuotes(response.data);
      });
  };

  useEffect(() => {
    setCurrentlyRenderedQuotes(filterQuotes());
  }, [searchText, quotes]);

  useEffect(() => {
    getVendor();
  }, []);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
      });
  };

  function filterQuotes() {
    if (!quotes || quotes?.length === 0) {
      return;
    }
    if (!searchText) {
      return quotes;
    }

    const filteredQuotes = quotes.filter((quote) => {
      const fieldValue =
        filterOption === "address.readableAddress"
          ? quote.address && quote.address.readableAddress
          : quote[filterOption];

      const fieldValueString = fieldValue ? fieldValue.toString() : "";
      return fieldValueString.toLowerCase().includes(searchText.toLowerCase());
    });

    return filteredQuotes;
  }

  const formatAddress = (address) => {
    const splitAddress = address.split(",");
    const splitZip = splitAddress[2].split("-");
    return `${splitAddress[0]}, ${splitAddress[1]}, ${splitZip[0]}`;
  };

  const sortQuotes = (field) => {
    let isDescending = false;

    if (sortConfig.field === field && !sortConfig.isDescending) {
      isDescending = true;
    }

    const sortedQuotes = [...currentlyRenderedQuotes].sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      if (valueA < valueB) {
        return isDescending ? 1 : -1;
      }
      if (valueA > valueB) {
        return isDescending ? -1 : 1;
      }
      return 0;
    });

    setQuotes(() => [...sortedQuotes]);
    setSortConfig({ field, isDescending });
  };

  const toggleRowSelection = (quoteId) => {
    const isSelected = selectedQuotes.some((quote) => quote._id === quoteId);

    if (isSelected) {
      setSelectedQuotes(
        selectedQuotes.filter((quote) => quote._id !== quoteId)
      );
    } else {
      const selectedQuote = quotes.find((quote) => quote._id === quoteId);
      setSelectedQuotes([...selectedQuotes, selectedQuote]);
    }
  };

  const isQuoteSelected = (quoteId) => {
    const isSelected = selectedQuotes.some((quote) => quote._id === quoteId);
    return isSelected;
  };

  const handleHeaderCheckboxChange = () => {
    if (selectedQuotes?.length === 0) {
      setSelectedQuotes(currentlyRenderedQuotes);
    }

    if (
      !selectedQuotes ||
      !currentlyRenderedQuotes ||
      selectedQuotes?.length === 0 ||
      currentlyRenderedQuotes?.length === 0
    ) {
      return;
    }

    if (selectedQuotes.length === currentlyRenderedQuotes.length) {
      setSelectedQuotes([]);
    } else {
      setSelectedQuotes(currentlyRenderedQuotes);
    }
  };

  const handleFilterOptionChange = (event) => {
    setSearchText("");
    setSelectedQuotes([]);

    if (event.target.value === "") {
      setFilterOption("");
    } else {
      setFilterOption(event.target.value);
    }
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    setSelectedQuotes([]);
  };

  const closeConfirmModal = () => {
    setDisplayConfirmModal(false);
  };

  const handleUpdateQuotes = (incomingEdits) => {
    setEdits(incomingEdits);
    closeEditQuotesModal();
    setDisplayConfirmModal(true);
    refreshQuotes();
  };
  const updateQuotes = () => {
    setFilterOption("");
    setSearchText("");
    const quoteIds = selectedQuotes.map((quote) => quote._id);
    const data = {
      quoteIds,
      edits,
    };

    Quote()
      .putJson("updateQuotes", data)
      .then((response) => {
        if (response.response.status === 200) {
          setQuotes(response.data);
          const updatedQuotes = response.data;
          const updatedSelectedQuotes = selectedQuotes.map((selectedQuote) => {
            const updatedQuote = updatedQuotes.find(
              (updatedQuote) => updatedQuote._id === selectedQuote._id
            );
            return updatedQuote || selectedQuote;
          });

          setSelectedQuotes(updatedSelectedQuotes);
          closeConfirmModal();
        }
      });
  };

  const getSortingIcon = (field) => {
    if (sortConfig.field === field) {
      return sortConfig.isDescending ? "🔽" : "🔼";
    }
    return null;
  };

  const closeEditQuotesModal = () => {
    setDisplayModal(false);
  };

  const openEditQuotesModal = () => {
    setDisplayModal(true);
  };

  // const getSortIcon = (column) => {
  //   const sortIconStyle = { color: theme.palette.greenButton.main };
  //   if (sortColumn === column) {
  //     return sortOrder === "asc" ? (
  //       <SortIconWrapper style={sortIconStyle}>
  //         <KeyboardArrowUp style={{ fontSize: "35px" }} />
  //       </SortIconWrapper>
  //     ) : (
  //       <SortIconWrapper style={sortIconStyle}>
  //         <KeyboardArrowDown style={{ fontSize: "35px" }} />
  //       </SortIconWrapper>
  //     );
  //   }
  //   return null;
  // };

  // const SortIconWrapper = styled("div")({
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   height: "100%",
  //   marginLeft: "15px",
  //   color: "inherit",
  //   fontSize: "inherit",
  // });

  return (
    <div>
      <VendorNavbar />
      <BulkEditQuotesModal
        open={displayModal}
        onClose={closeEditQuotesModal}
        quoteCategories={vendor?.quoteCategories}
        handleUpdateQuotes={(edits) => handleUpdateQuotes(edits)}
        updateVendor={getVendor}
      />
      <ConfirmBulkEditModal
        open={displayConfirmModal}
        onClose={closeConfirmModal}
        exampleRow={selectedQuotes[0]}
        updateQuotes={() => updateQuotes()}
        edits={edits}
      />
      {loading ? (
        <LoadingIcon />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
              marginTop: "40px",
              marginLeft: "150px",
              marginRight: "150px",
            }}
          >
            <div>
              <InputLabel sx={{ fontSize: 30, userSelect: "none" }}>
                Search
              </InputLabel>
              <Select
                value={filterOption}
                onChange={handleFilterOptionChange}
                sx={{ width: "180px", borderRadius: "8px" }}
              >
                <MenuItem value="filterOption" sx={{ height: "auto" }}>
                  {/* <Typography variant="inherit" sx={{ visibility: "hidden" }}> */}
                  <Typography variant="inherit">Filter Option</Typography>
                </MenuItem>
                <MenuItem value="quoteCategory">Quote Category</MenuItem>
                <MenuItem value="title">Quote Title</MenuItem>
                <MenuItem value="description">Quote Description</MenuItem>
                <MenuItem value="price">Quote Price</MenuItem>
                <MenuItem value="address.readableAddress">Address</MenuItem>
              </Select>
              <TextField
                label={
                  filterOption === "" || filterOption === "filterOption"
                    ? "Select Filter Option"
                    : "Search"
                }
                variant="outlined"
                value={searchText}
                onChange={handleSearchTextChange}
                style={{ marginLeft: "10px" }}
                InputProps={{ sx: { borderRadius: "8px" } }}
                disabled={
                  filterOption === "" || filterOption === "filterOption"
                }
              />
            </div>
          </div>
          <GreenButton
            onClick={openEditQuotesModal}
            disabled={selectedQuotes.length === 0}
          >
            Update Selected Quotes {`(${selectedQuotes?.length})`}
          </GreenButton>
          <TableContainer
            component={Paper}
            sx={{ width: "90vw", margin: "auto", marginTop: "40px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={
                        currentlyRenderedQuotes?.length > 0 &&
                        selectedQuotes?.length ===
                          currentlyRenderedQuotes?.length
                      }
                      onClick={handleHeaderCheckboxChange}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => sortQuotes("quoteCategory")}
                  >
                    <TableHeader sortable>Quote Category</TableHeader>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => sortQuotes("title")}
                  >
                    <TableHeader sortable>Quote Title</TableHeader>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => sortQuotes("description")}
                  >
                    <TableHeader sortable>Quote Description</TableHeader>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => sortQuotes("price")}
                  >
                    <TableHeader sortable>Quote Price</TableHeader>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 20,
                      color: "#252F3D",
                      fontWeight: "700",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => sortQuotes("address")}
                  >
                    <TableHeader sortable>Address</TableHeader>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentlyRenderedQuotes &&
                  currentlyRenderedQuotes.map((quote) => (
                    <TableRow
                      key={quote._id}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      onClick={() => toggleRowSelection(quote._id)}
                    >
                      <TableCell>
                        <Checkbox
                          checked={isQuoteSelected(quote._id)}
                          onClick={() => toggleRowSelection(quote._id)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "18px" }}>
                          {quote.quoteCategory}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "18px" }}>
                          {quote.title}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "18px" }}>
                          {quote.description}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "18px" }}>
                          ${quote.price}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: "18px" }}>
                          {quote.address.readableAddress
                            ? formatAddress(quote.address.readableAddress)
                            : null}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export default ManageQuotes;
