import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  MenuItem,
  DialogTitle
} from "@mui/material";

import { Quote, Vendor } from "../utils/httpUtils";
import VendorContext from "../context/VendorContext";
import NewCategoryModal from "./NewCategoryModal";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";

const EditQuoteModal = ({ open, onClose, quote, updateProperty }) => {
  const [title, setTitle] = useState(quote?.title || "");
  const [description, setDescription] = useState(quote?.description || "");
  const [price, setPrice] = useState(quote?.price || "");
  const [quoteCategory, setQuoteCategory] = useState(
    quote?.quoteCategory || ""
  );
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(open);
  const { vendor, setVendor } = useContext(VendorContext);
  const [quoteCategories, setQuoteCategories] = useState(
    vendor?.quoteCategories || []
  );
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (vendor) {
      setQuoteCategories(vendor.quoteCategories);
    }
  }, [vendor]);

  useEffect(() => {
    if (!vendor) {
      getVendor();
    }
  }, []);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
      });
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleCategoryModalOpen = () => {
    setDisplayCategoryModal(true);
  };

  const handleCategoryQuoteModalClose = () => {
    setDisplayCategoryModal(false);
  };

  const updateQuote = () => {
    const data = {
      id: quote._id,
      title,
      description,
      price,
      quoteCategory,
    };
    Quote()
      .putJson("updateQuote", data)
      .then((response) => {
        if (response.response.status === 200) {
          updateProperty();
          onClose();
        }
      });
  };

  const deleteQuote = () => {
    Quote()
      .deleteJson(`/${quote._id}`)
      .then((response) => {
        if (response.response.status === 200) {
          updateProperty();
          onClose();
        } else {
          setError("Error editing quote");
        }
      });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Edit Quote</DialogTitle>
      <NewCategoryModal
        open={displayCategoryModal}
        onClose={handleCategoryQuoteModalClose}
        updateVendor={getVendor}
      />
      <DialogContent>
        <TextField
          select
          label="Quote Category"
          variant="outlined"
          fullWidth
          value={quoteCategory}
          onChange={(e) => setQuoteCategory(e.target.value)}
          sx={{ marginBottom: 2 }}
        >
          {quoteCategories?.length > 0
            ? quoteCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))
            : null}
          <MenuItem value="AddNew" onClick={handleCategoryModalOpen}>
            Add New
          </MenuItem>
        </TextField>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          label="Price"
          variant="outlined"
          fullWidth
          value={price}
          onChange={(e) => {
            const numericInput = e.target.value.replace(/[^0-9.]/g, "");
            const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(numericInput);
            if (isValidInput) {
              setPrice(numericInput);
            }
          }}
          sx={{ marginBottom: 2 }}
        />
        <GreenButton onClick={updateQuote}>Update</GreenButton>
        <RedButton onClick={onClose} sx={{ margin: "5px", marginLeft: "10px" }}>
          Close
        </RedButton>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default EditQuoteModal;
