import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/system";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Card,
  CardContent,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import NewCategoryModal from "./NewCategoryModal";
import VendorContext from "../context/VendorContext";
import { Vendor } from "../utils/httpUtils";
import {
  CustomSelectWithLabel,
  CustomTextField,
} from "../components/reusableComponents/Inputs";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";

const BulkEditQuotesModal = ({
  open,
  onClose,
  handleUpdateQuotes,
  // quoteCategories,
}) => {
  const [quoteCategory, setQuoteCategory] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [adjustmentType, setAdjustmentType] = useState(null);
  const [amountType, setAmountType] = useState(null);
  const [displayCategoryModal, setDisplayCategoryModal] = useState(false);
  const [quoteCategories, setQuoteCategories] = useState([]);

  const { vendor, setVendor } = useContext(VendorContext);

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(open);

  const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.green.main,
    },
  });

  useEffect(() => {
    setIsOpen(open);
    setQuoteCategory("");
    setTitle("");
    setDescription("");
    setAdjustmentType(null);
    setAmountType(null);
  }, [open]);

  useEffect(() => {
      getVendor();
  }, [open]);

  useEffect(() => {
    if (adjustmentType === null && amountType === null) {
      setPrice("");
    }
  }, [adjustmentType, amountType]);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
        setQuoteCategories(response.data.quoteCategories)
      });
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleCategoryQuoteModalClose = () => {
    setDisplayCategoryModal(false);
  };

  const handleCategoryModalOpen = () => {
    setDisplayCategoryModal(true);
  };

  const clearCategory = () => {
    setQuoteCategory("");
  };

  const handleAdjustmentTypeChange = (event, newAdjustmentType) => {
    setAdjustmentType(newAdjustmentType);
  };

  const handleAmountTypeChange = (event, newAmountType) => {
    setAmountType(newAmountType);
  };

  const handleSubmit = () => {
    const formData = {
      quoteCategory,
      title,
      description,
      price,
      adjustmentType,
      amountType,
    };

    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value?.length !== 0)
    );

    handleUpdateQuotes(filteredFormData);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <NewCategoryModal
        open={displayCategoryModal}
        onClose={handleCategoryQuoteModalClose}
        updateVendor={getVendor}
      />
      <DialogContent>
        <Card
          style={{
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <CardContent>
            <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
              Bulk Edit Quotes
            </Typography>
            <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
              Only fill out fields you want to edit
            </Typography>

            <CustomSelectWithLabel
              label="Quote Category"
              value={quoteCategory}
              onChange={setQuoteCategory}
              options={
                quoteCategories?.length > 0
                  ? quoteCategories.map((category) => ({
                      label: category,
                      value: category,
                    }))
                  : []
              }
              fullWidth
              onAddNewClick={handleCategoryModalOpen}
              sx={{ marginBottom: 2 }}
            />
            <CustomTextField
              label="Edit Titles"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <CustomTextField
              label="Edit Descriptions"
              variant="outlined"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <div
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={adjustmentType}
                exclusive
                onChange={handleAdjustmentTypeChange}
              >
                <StyledToggleButton value="+" aria-label="plus">
                  Add (+)
                </StyledToggleButton>
                <StyledToggleButton value="-" aria-label="minus">
                  Discount (-)
                </StyledToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                value={amountType}
                exclusive
                onChange={handleAmountTypeChange}
                sx={{ marginLeft: "4%" }}
              >
                <StyledToggleButton value="%" aria-label="percentage">
                  Percent (%)
                </StyledToggleButton>
                <StyledToggleButton value="$" aria-label="dollar">
                  Amount ($)
                </StyledToggleButton>
              </ToggleButtonGroup>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <CustomTextField
                label="Prices"
                variant="outlined"
                fullWidth
                value={price}
                onChange={(e) => {
                  const numericInput = e.target.value.replace(/[^0-9.]/g, "");
                  const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(
                    numericInput
                  );
                  if (isValidInput) {
                    setPrice(numericInput);
                  }
                }}
                sx={{ width: "18%" }}
                disabled={adjustmentType === null || amountType === null}
              />
              <div style={{ width: "40px" }}>
                {amountType === "%" && (
                  <IconButton>
                    <PercentIcon />
                  </IconButton>
                )}
                {amountType === "$" && (
                  <IconButton>
                    <AttachMoneyIcon />
                  </IconButton>
                )}
              </div>
            </div>
            <GreenButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </GreenButton>
            <RedButton
              variant="contained"
              color="primary"
              onClick={onClose}
              sx={{ marginLeft: "10px" }}
            >
              Cancel
            </RedButton>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default BulkEditQuotesModal;
