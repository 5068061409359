import React, { useState, useEffect } from "react";
import { Quote, Service } from "../utils/httpUtils";
import CancelRecurringModal from "./CancelRecurringModal";
import RecurringServiceModal from "./RecurringServiceModal";
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomCheckbox from "../components/reusableComponents/Customcheckbox";
import { CustomSelectNoAdd } from "../components/reusableComponents/Inputs";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";

const CreateRecurringServiceModal = ({
  open,
  onClose,
  properties,
  customer,
  handleUpdate,
}) => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isOpen, setIsOpen] = useState(open);
  const [quotes, setQuotes] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [showCancelRecurring, setShowCancelRecurring] = useState(false);
  const [showRecurringServiceModal, setShowRecurringServiceModal] =
    useState(false);
  const [selectedQuoteIds, setSelectedQuoteIds] = useState([]);

  useEffect(() => {
    setIsOpen(open);
    setSelectedProperty(properties[0]);
    setSelectedQuotes([]);
    setServices([]);
    getQuotesAtProperty();
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    getServices();
  }, [selectedQuotes, selectedQuoteIds]);

  useEffect(() => {
    getQuotesAtProperty();
  }, [selectedProperty]);

  const getServices = () => {
    if (selectedQuoteIds.length > 0) {
      const quoteIds = selectedQuotes.map((quote) => quote._id);
      const data = {
        quoteIds,
        customerId: customer?._id,
      };
      Service()
        .getJson("getServices", data)
        .then((response) => {
          setServices(response.data.services);
        });
    }
  };

  const handlePropertyChange = (event) => {
    const selectedProperty = properties.find(
      (property) => property.address.readableAddress === event.target.value
    );
    if (selectedProperty) {
      setSelectedProperty(selectedProperty);
    }
  };

  const getQuotesAtProperty = () => {
    if (!customer || !selectedProperty) {
      return;
    }
    const data = {
      propertyId: selectedProperty._id,
      customerId: customer._id,
    };
    Quote()
      .getJson("getAllQuotesByVendorAtAddressNoRecurring", data)
      .then((response) => {
        setQuotes(response.data);
      });
  };

  const handleAddQuote = (event) => {
    setSelectedQuoteIds([...selectedQuoteIds, event.target.value]);
    const selectedQuote = quotes.find(
      (quote) => quote._id === event.target.value
    );
    setSelectedQuotes([...selectedQuotes, selectedQuote]);
    const updatedQuotes = quotes.filter(
      (quote) => quote._id !== selectedQuote._id
    );
    setQuotes(updatedQuotes);
  };

  const handleRemoveQuote = (quoteId) => {
    const updatedQuotes = selectedQuotes.filter(
      (quote) => quote._id !== quoteId
    );
    setSelectedQuotes(updatedQuotes);
  };

  const handleCheckboxChange = (service) => {
    setSelectedService(service);
    if (service.recurring?.isRecurring) {
      setShowCancelRecurring(true);
    } else {
      setShowRecurringServiceModal(true);
    }
  };

  const handleCloseCancelRecurringModal = () => {
    setShowCancelRecurring(false);
  };

  const handleCloseRecurringModal = () => {
    setShowRecurringServiceModal(false);
  };

  const updateAndClose = () => {
    handleUpdate();
    onClose();
  };

  const formatDate = (date) => {
    const localDateOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };

    const localDate = new Date(date).toLocaleString(
      undefined,
      localDateOptions
    );

    return localDate;
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{ margin: "auto" }}
      fullWidth={true}
      maxWidth="md"
    >
      <CancelRecurringModal
        open={showCancelRecurring}
        onClose={handleCloseCancelRecurringModal}
        service={selectedService}
        handleUpdate={getServices}
        handleUpdateQuotes={getQuotesAtProperty}
      />
      <RecurringServiceModal
        open={showRecurringServiceModal}
        onClose={handleCloseRecurringModal}
        service={selectedService}
        handleUpdate={getServices}
        handleUpdateQuotes={getQuotesAtProperty}
        customerId={customer?._id}
      />
      <DialogContent>
        {/* <Card
          style={{
            width: "100%",
            border: "1px solid #ccc",
            marginTop: "30px",
          }}
        > */}
        <CardContent>
          <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
            Add Recurring Services
          </Typography>
          {properties && properties.length > 1 ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginBottom: "28px", userSelect: "none" }}
                >
                  Select Property:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <CustomSelectNoAdd
                  value={selectedProperty?.address.readableAddress}
                  onChange={handlePropertyChange}
                  fullWidth
                  options={properties.map((property) => ({
                    label: property.address?.readableAddress,
                    // value: property._id
                    value: property?.address.readableAddress,
                  }))}
                />
              </Grid>
            </Grid>
          ) : (
            <TextField
              label="Property"
              variant="outlined"
              fullWidth
              value={properties[0]?.address.readableAddress || ""}
              InputProps={{ readOnly: true }}
              sx={{
                marginBottom: 2,
                userSelect: "none",
                "& input::placeholder": {
                  color: "#000",
                },
              }}
            />
          )}

          {/* {Quotes Dropdown} */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: "28px", userSelect: "none" }}
              >
                Select Services:
              </Typography>
            </Grid>
            {quotes?.length > 0 ? (
              <Grid item xs={4}>
                <CustomSelectNoAdd
                  value={
                    selectedQuotes.length > 0
                      ? selectedQuotes[selectedQuotes.length - 1]._id
                      : ""
                  }
                  fullWidth
                  onChange={handleAddQuote}
                  options={quotes?.map((quote) => ({
                    label: quote.title,
                    value: quote._id,
                  }))}
                />
              </Grid>
            ) : (
              <Grid item xs={8}>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{ marginBottom: "28px", userSelect: "none" }}
                >
                  You don't have any non-recurring quotes at this address. To
                  add a recurring service, add more quotes to this address.
                </Typography>
              </Grid>
            )}
          </Grid>

          {services && services.length > 0 && (
            <CardContent>
              <TableContainer component={Card}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Service</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Recurring</TableCell>
                      <TableCell>Frequency</TableCell>
                      <TableCell>Last Performed</TableCell>
                      <TableCell>Suggested Next Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {services &&
                      services?.map((service, index) => (
                        <TableRow key={index}>
                          <TableCell>{service?.quoteTitle}</TableCell>
                          <TableCell>
                            ${service?.quotePrice?.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <CustomCheckbox
                              checked={!!service?.recurring?.isRecurring}
                              onChange={() => handleCheckboxChange(service)}
                            />
                          </TableCell>
                          <TableCell>
                            {service?.recurring?.recurringFrequency
                              ? `${service?.recurring.recurringFrequency} ${service?.recurring.recurringUnit}`
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {service.recurring?.lastPerformed
                              ? formatDate(service.recurring.lastPerformed)
                              : "-"}
                          </TableCell>
                          <TableCell>
                            {service.recurring?.recommendedNext
                              ? formatDate(service.recurring.recommendedNext)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          )}

          <GreenButton
            variant="contained"
            color="primary"
            onClick={updateAndClose}
            sx={{ marginRight: "10px" }}
          >
            Submit
          </GreenButton>
          <RedButton variant="contained" color="primary" onClick={onClose}>
            Cancel
          </RedButton>
        </CardContent>
        {/* </Card> */}
      </DialogContent>
    </Dialog>
  );
};

export default CreateRecurringServiceModal;
