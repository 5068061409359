import React, { useState, useEffect } from "react";
import { Auth } from "../utils/httpUtils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  Snackbar,
} from "@mui/material";
import { GreenButton, RedButton } from "../components/reusableComponents/Buttons";
import { CustomTextField } from "../components/reusableComponents/Inputs";

const ResetPasswordModal = ({ open, onClose }) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    setPasswordMatch(password === passwordConfirm);
  }, [password, passwordConfirm]);

  const handleSubmit = () => {
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters");
      return;
    }

    const data = {
      newPassword: password,
    };

    Auth()
      .postJson("resetPassword", data)
      .then((response) => {
        if (response.response.status === 200) {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
            onClose();
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePasswordChange = (event) => {
    setPasswordError("");
    setPassword(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
          Reset Password
        </Typography>
        <form autoComplete="on">
          <CustomTextField
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            autoComplete="on"
            type="password"
            sx={{
              marginBottom: "10px",
              width: "250px",
            }}
            error={passwordError !== ""}
            helperText={passwordError}
          />
        </form>
        <form>
          <CustomTextField
            label="Confirm Password"
            value={passwordConfirm}
            onChange={(event) => setPasswordConfirm(event.target.value)}
            fullWidth
            error={!passwordMatch}
            autoComplete="off"
            type="password"
            sx={{ width: "250px",
          }}
          />
        </form>

        <DialogActions sx={{ justifyContent: "center", marginTop: "10px" }}>
          <RedButton
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </RedButton>
          <GreenButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!passwordMatch || passwordError !== ""}
            style={{ margin: "5px" }}
          >
            Submit
          </GreenButton>
        </DialogActions>
      </DialogContent>

      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={() => setShowSuccessMessage(false)}
        message="Password Updated"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          position: "absolute",
          bottom: "20px",
          zIndex: 9999,
          "& .MuiSnackbarContent-root": {
            textAlign: "center",
          },
        }}
      />
    </Dialog>
  );
};

export default ResetPasswordModal;
