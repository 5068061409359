import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import QuoteDescriptionModal from "../../modals/QuoteDescriptionModal";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import CustomCheckbox from "../reusableComponents/Customcheckbox";

export default function QuoteCardSmall({ quote, isSelected, onSelect }) {
  const [showDetailsModal, setShowDetailsModal] = useState();

  const showDetails = () => {
    setShowDetailsModal(true);
  };

  const closeDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const determinePrice = (quote) => {
    if (quote.couponPrice === quote.price || !quote.couponPrice) {
      return (
        <Typography variant="subtitle1" color="primary">
          <span style={{ fontSize: 20 }}>{`Price: $${quote.price}`}</span>
        </Typography>
      );
    } else {
      return (
        <div>
          <Typography variant="subtitle1">
            <span
              style={{ textDecoration: "line-through", fontSize: 20 }}
            >{`Price: $${quote?.price?.toFixed(2)}`}</span>
            <div
              style={{ fontWeight: 700, fontSize: 20, color: "green" }}
            >{`Coupon Price: $${quote?.couponPrice?.toFixed(2)}`}</div>
          </Typography>
        </div>
      );
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
        padding: "1px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        borderRadius: "20px",
      }}
    >
      {showDetailsModal && (
        <QuoteDescriptionModal
          onClose={closeDetailsModal}
          open={showDetailsModal}
          quote={quote}
        />
      )}
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2}>
          <CustomCheckbox
            checked={isSelected}
            onChange={onSelect}
            inputProps={{ "aria-label": "Select Quote" }}
            sx={{ transform: "scale(1.5)" }}
          />
        </Grid>
        <Grid item xs={7}>
          <CardContent
            sx={{
              textAlign: "center",
              userSelect: "none",
            }}
          >
            <Typography variant="h6" component="div">
              {quote.title}
            </Typography>
            <Typography variant="h6" component="div">
              {determinePrice(quote)}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={3}>
          <IconButton
            onClick={showDetails}
            sx={{
              color: "tomato",
              cursor: "pointer",
              userSelect: "none",
              color: "green",
              transform: "scale(1.5)",
            }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
}
