import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  CardContent,
} from "@mui/material";
import {
  GreenButton,
  RedButton,
} from "../components/reusableComponents/Buttons";
import {
  CustomSelectWithLabelNoAdd,
  CustomTextField,
} from "../components/reusableComponents/Inputs";

const CustomerInfoModal = ({
  open,
  onClose,
  createJob,
  customerInfo,
  includeLeadSource,
  leadSourceOptions,
}) => {
  const [customerFirst, setCustomerFirst] = useState("");
  const [customerLast, setCustomerLast] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerNotes, setCustomerNotes] = useState("");
  const [leadSource, setLeadSource] = useState(null);
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (customerInfo) {
      setCustomerFirst(customerInfo.customerFirst);
      setCustomerLast(customerInfo.customerLast);
      setCustomerPhone(customerInfo.customerPhone);
      setLeadSource(customerInfo.leadSource);
    }
  }, [customerInfo]);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const formatPhoneNumber = (value) => {
    const phoneNumberDigits = value.replace(/\D/g, "");
    const formattedPhoneNumber = phoneNumberDigits.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedPhoneNumber;
  };

  const determineDisabled = () => {
    if (!customerFirst || customerFirst.length === 0) {
      return true;
    }
    if (!customerPhone || customerPhone.length === 0) {
      return true;
    }
    if (!customerLast || customerLast.length === 0) {
      return true;
    }
    return false;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <CardContent>
          {!customerInfo?.customerPhone ||
            !customerInfo?.customerFirst ||
            (!customerInfo?.customerLast && (
              <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
                Enter Contact Information
              </Typography>
            ))}
          {customerInfo?.customerPhone &&
            customerInfo?.customerLast &&
            customerInfo?.customerPhone && (
              <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
                Notes
              </Typography>
            )}
          {(!customerInfo || !customerInfo.customerFirst) && (
            <CustomTextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={customerFirst}
              onChange={(e) => setCustomerFirst(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          )}
          {(!customerInfo || !customerInfo.customerLast) && (
            <CustomTextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={customerLast}
              onChange={(e) => setCustomerLast(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          )}
          {(!customerInfo || !customerInfo.customerPhone) && (
            <CustomTextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              value={customerPhone ? formatPhoneNumber(customerPhone) : null}
              onChange={(e) => setCustomerPhone(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
          )}
          {includeLeadSource &&
            leadSourceOptions?.length > 0 &&
            (!customerInfo || !customerInfo.leadSource) && (
              <CustomSelectWithLabelNoAdd
                label="Where'd you hear about us?"
                value={leadSource}
                onChange={(e) => setLeadSource(e.target.value)}
                options={
                  leadSourceOptions?.length > 0
                    ? leadSourceOptions.map((source) => ({
                        label: source,
                        value: source,
                      }))
                    : []
                }
                sx={{ marginBottom: 2, maxWidth: "100%" }}
                fullWidth={true}
              />
            )}
          <CustomTextField
            label="Anything we need to know about this job?"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            value={customerNotes}
            onChange={(e) => setCustomerNotes(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <GreenButton
            variant="contained"
            color="primary"
            disabled={determineDisabled()}
            onClick={() =>
              createJob(
                customerFirst,
                customerLast,
                customerPhone,
                customerNotes,
                leadSource
              )
            }
            sx={{ marginRight: "10px" }}
          >
            Submit
          </GreenButton>

          <RedButton
            variant="contained"
            color="primary"
            onClick={onClose}
            style={{ margin: "5px" }}
          >
            Cancel
          </RedButton>
        </CardContent>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerInfoModal;
