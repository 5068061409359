import axios from "axios";

const baseUrl = process.env.REACT_APP_API_BASE_URL

const googleApiUrl = "https://maps.googleapis.com/maps/api/place/autocomplete/json?";
const getAuthToken = () => document.cookie.split("; ").find(row => row.startsWith("token="))?.split("=")[1];

const errorHandler = (error) => {
  console.error("API Request Error:", error);
  return {
    error: "An error occurred while processing your request.",
    response: error.response || null,
  };
};

const createAxiosInstance = (apiPath) => {
  const token = getAuthToken();
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  return axios.create({
    baseURL: apiPath,
    headers: headers,
  });
};

const createGoogleInstance = () => {
  return axios.create({
    baseURL: googleApiUrl,
  });
};

const createRequestFunction = (instance) => {
  return (method, path, data) => {
    return instance[method](path, data)
      .then(response => ({ data: response.data, response }))
      .catch(error => errorHandler(error));
  };
};

const general = (apiPath) => {
  const instance = createAxiosInstance(apiPath);
  const requestFunction = createRequestFunction(instance);

  return {
    getJson: (path, data) => requestFunction("get", path, { params: data }),
    postJson: (path, data) => requestFunction("post", path, data),
    putJson: (path, data) => requestFunction("put", path, data),
    deleteJson: (path, data) => requestFunction("delete", path, { params: data }),
  };
};

const google = () => {
  const instance = createGoogleInstance();
  const requestFunction = createRequestFunction(instance);

  return {
    getJson: (key, location) => requestFunction("get", "", { params: { key, input: location } }),
  };
};

export const Auth = () => general(`${baseUrl}/auth`);
export const Job = () => general(`${baseUrl}/jobs`);
export const Property = () => general(`${baseUrl}/properties`);
export const Quote = () => general(`${baseUrl}/quotes`);
export const Utils = () => general(`${baseUrl}/utils`);
export const Vendor = () => general(`${baseUrl}/vendors`);
export const Customer = () => general(`${baseUrl}/customers`);
export const Service = () => general(`${baseUrl}/services`);
export const Coupon = () => general(`${baseUrl}/coupons`);
export const Request = () => general(`${baseUrl}/requests`);
export const Email = () => general(`${baseUrl}/emails`);
export const Invoice = () => general(`${baseUrl}/invoices`);
export const Admin = () => general(`${baseUrl}/admin`);


export const GoogleAutocomplete = google();







// import axios from "axios";

// //local server
// const baseUrl = "http://localhost:8080/api";

// //prod server
// // const baseUrl = "http://18.234.149.45/api";
// const errorHandler = (error) => {
//   console.error("API Request Error:", error);
//   return {
//     error: "An error occurred while processing your request.",
//     response: error.response || null,
//   };
// };

// const getAuthToken = () => {
//   return document.cookie.split("; ").find(row => row.startsWith("token="))?.split("=")[1];
// };

// const general = (apiPath) => {
//   const token = getAuthToken();
//   console.log("token: ", token)
//   const headers = token ? { Authorization: `Bearer ${token}` } : {};

//   const instance = axios.create({
//     baseURL: apiPath,
//     headers: headers,
//   });

//   return {
//     getJson: (path, data) => {
//       return instance
//         .get(path, { params: data })
//         .then((response) => {
//           return { data: response.data, response };
//         })
//         .catch((error) => {
//           return errorHandler(error);
//         });
//     },
//     postJson: (path, data) => {
//       return instance
//         .post(path, data)
//         .then((response) => {
//           return { data: response.data, response };
//         })
//         .catch((error) => {
//           return errorHandler(error);
//         });
//     },
//     putJson: (path, data) => {
//       return instance
//         .put(path, data)
//         .then((response) => {
//           return { data: response.data, response };
//         })
//         .catch((error) => {
//           return errorHandler(error);
//         });
//     },
//     deleteJson: (path, data) => {
//       return instance
//         .delete(path, { params: data })
//         .then((response) => {
//           return { data: response.data, response };
//         })
//         .catch((error) => {
//           return errorHandler(error);
//         });
//     },
//   };
// };

// const google = (apiPath) => {
//   return {
//     getJson: (key, location) => {
//       return axios
//         .get(`${apiPath}key=${key}&input=${location}`)
//         .then((res) => {
//           return { data: res.data, response: res };
//         })
//         .catch((error) => {
//           return errorHandler(error);
//         });
//     },
//   };
// };

// export const Auth = () => general(`${baseUrl}/auth`);
// export const Job = () => general(`${baseUrl}/jobs`);
// export const Property = () => general(`${baseUrl}/properties`);
// export const Quote = () => general(`${baseUrl}/quotes`);
// export const Utils = () => general(`${baseUrl}/utils`);
// export const Vendor = () => general(`${baseUrl}/vendors`);

// export const GoogleAutocomplete = google(`https://maps.googleapis.com/maps/api/place/autocomplete/json?`)
