import React, { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/system";
import {
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Card,
  CardContent,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import VendorContext from "../context/VendorContext";
import { Vendor, Email } from "../utils/httpUtils";
import { GreenButton, RedButton } from "../components/reusableComponents/Buttons";
import CustomCheckbox from "../components/reusableComponents/Customcheckbox";

const EmailModal = ({ open, onClose, selectedEmail, handleUpdateEmails }) => {
  const [emailTitle, setEmailTitle] = useState("");
  const [duration, setDuration] = useState("");
  const [durationUnit, setDurationUnit] = useState("");
  const [editMode, setEditMode] = useState();
  const [triggerCode, setTriggerCode] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [beforeAfter, setBeforeAfter] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [includeQuotes, setIncludeQuotes] = useState(false);

  const { vendor, setVendor } = useContext(VendorContext);

  const theme = useTheme();

  const StyledToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: theme.palette.green.main,
    },
  });

  useEffect(() => {
    if (!vendor) {
      getVendor();
    }
  }, []);

  useEffect(() => {
    if (selectedEmail) {
      setEmailTitle(selectedEmail.emailTitle);
      setSubject(selectedEmail.subject);
      setBody(selectedEmail.body);
      setDuration(selectedEmail.duration);
      setDurationUnit(selectedEmail.durationUnit);
      setBeforeAfter(selectedEmail.beforeAfter);
      setTriggerCode(selectedEmail.triggerCode);
      setIncludeQuotes(selectedEmail.includeQuotes);
      determineDropdownOptions(selectedEmail.beforeAfter);
    } else {
      setEmailTitle("");
      setSubject("");
      setBody("");
      setDuration("");
      setDurationUnit("");
      setBeforeAfter("");
      setTriggerCode("");
      setIncludeQuotes(false);
    }
  }, [selectedEmail]);

  useEffect(() => {
    determineDropdownOptions();
  }, [beforeAfter]);

  const getVendor = () => {
    Vendor()
      .getJson("getVendor")
      .then((response) => {
        setVendor(response.data);
      });
  };

  const handleDurationUnitChange = (event, newDurationUnit) => {
    if (newDurationUnit !== "DAYS" && newDurationUnit !== "WEEKS") {
      setDuration("");
    }
    setDurationUnit(newDurationUnit);
  };

  const handleBeforeAfter = (event, newBeforeAfter) => {
    if (newBeforeAfter !== "BEFORE" && newBeforeAfter !== "AFTER") {
      setBeforeAfter("");
    }
    setBeforeAfter(newBeforeAfter);
    determineDropdownOptions(newBeforeAfter);
  };

  const determineDropdownOptions = (newBeforeAfter) => {
    if (newBeforeAfter === "BEFORE") {
      setDropdownOptions([
        { title: "Scheduled Job", triggerCode: 4 },
        { title: "Recurring Job", triggerCode: 5 },
      ]);
    }
    if (newBeforeAfter === "AFTER") {
      setDropdownOptions([
        { title: "Quotes Sent (if no job is booked)", triggerCode: 1 },
        { title: "Job Booked", triggerCode: 2 },
        { title: "Job Completed", triggerCode: 3 },
      ]);
    }
  };

  const handleSubmit = () => {
    let data = {
      emailTitle,
      subject,
      body,
      duration,
      durationUnit,
      beforeAfter,
      includeQuotes,
      triggerCode,
    };

    if (editMode) {
      //   data = {
      //     ...data,
      //   };
      //   Coupon()
      //     .putJson("updateCoupon", data)
      //     .then((response) => {
      //       console.log("response: ", response);
      //       if (response.response.status === 200) {
      //         // handleUpdateCoupons();
      //         onClose();
      //       }
      //     });
      //kevin
    } else {
      Email()
        .postJson("createAutomatedEmail", data)
        .then((response) => {
          if (response.response.status === 200) {
            handleUpdateEmails();
            onClose();
          }
        });
    }
  };

  const handleClose = () => {
    // setSelectedCoupon(null);
    // handleUpdateCoupons();
    onClose();
  };

  const handleCheckboxChange = () => {
    if (includeQuotes) {
      setIncludeQuotes(false);
    } else {
      setIncludeQuotes(true);
    }
  };

  const determineDurationUnitText = () => {
    if (durationUnit === "DAYS") {
      if (duration === "1") {
        return <IconButton>Day</IconButton>;
      }
      if (duration !== "1") {
        return <IconButton>Days</IconButton>;
      }
    }
    if (durationUnit === "WEEKS") {
      if (duration === "1") {
        return <IconButton>Week</IconButton>;
      }
      if (duration !== "1") {
        return <IconButton>Weeks</IconButton>;
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Card
          style={{
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <CardContent>
            <Typography variant="h4" component="div" sx={{ marginBottom: 2 }}>
              {editMode ? "Edit Automated Email" : "Create Automated Email"}
            </Typography>

            <TextField
              label="Email Title"
              variant="outlined"
              fullWidth
              value={emailTitle}
              onChange={(e) => setEmailTitle(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Subject Line"
              variant="outlined"
              fullWidth
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="Body"
              variant="outlined"
              fullWidth
              value={body}
              onChange={(e) => setBody(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              Determine when this email should be sent
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <TextField
                label="Duration"
                variant="outlined"
                fullWidth
                type="number"
                value={duration}
                onChange={(e) => {
                  const numericInput = e.target.value.replace(/[^0-9.]/g, "");
                  const isValidInput = /^(\d+)?(\.\d{0,2})?$/.test(
                    numericInput
                  );
                  if (isValidInput) {
                    setDuration(numericInput);
                  }
                }}
                sx={{ width: "22%" }}
              />
              <div style={{ width: "40px" }}>{determineDurationUnitText()}</div>
            </div>

            <div
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={durationUnit}
                exclusive
                onChange={handleDurationUnitChange}
              >
                <StyledToggleButton value="DAYS">Days</StyledToggleButton>
                <StyledToggleButton value="WEEKS">Weeks</StyledToggleButton>
              </ToggleButtonGroup>
            </div>

            <div
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ToggleButtonGroup
                value={beforeAfter}
                exclusive
                onChange={handleBeforeAfter}
              >
                <StyledToggleButton value="BEFORE">Before</StyledToggleButton>
                <StyledToggleButton value="AFTER">After</StyledToggleButton>
              </ToggleButtonGroup>
            </div>

            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel id="trigger-code-select">Select a Trigger</InputLabel>
              <Select
                labelId="trigger-code-select"
                value={triggerCode}
                onChange={(event) => {
                  setTriggerCode(event.target.value);
                }}
                label="Select a Trigger"
              >
                {dropdownOptions?.map((option) => (
                  <MenuItem key={option.title} value={option.triggerCode}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div
              style={{
                marginBottom: 16,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CustomCheckbox
                checked={includeQuotes}
                onChange={() => handleCheckboxChange()}
              />
              <Typography variant="body1" sx={{ marginLeft: 1 }}>
                Include Link to Quotes
              </Typography>
            </div>

            <GreenButton
              onClick={handleSubmit}
              disabled={
                subject === "" ||
                emailTitle === "" ||
                body === "" ||
                duration === "" ||
                durationUnit === "" ||
                beforeAfter === "" ||
                triggerCode === ""
              }
            >
              Submit
            </GreenButton>
            <RedButton onClick={handleClose} sx={{ marginLeft: "10px" }}>
              Cancel
            </RedButton>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default EmailModal;
