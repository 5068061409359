import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AverageTicket from "./AverageTicket";
import JobsPerTicket from "./JobsPerTicket";
import BoughtTogetherComponent from "./BoughtTogetherComponent";
import BestSellingJobsComponent from "./BestSellingJobsComponent";
import UpsellData from "./UpsellData";
import { Vendor, Job } from "../../utils/httpUtils";
import VendorContext from "../../context/VendorContext";
import { Typography } from "@mui/material";

function AnalyticsComponent(props) {
  const [jobs, setJobs] = useState(props.jobs);
  const [average, setAverage] = useState();
  const [jobsPerTicket, setJobsPerTicket] = useState();
  const [bestSelling, setBestSelling] = useState();
  const [upsellData, setUpsellData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { vendor } = useContext(VendorContext);
  const [upsellLoading, setUpsellLoading] = useState(true);
  const [averageTicketLoading, setAverageTicketLoading] = useState(true);
  const [perTicketLoading, setPerTicketLoading] = useState(true);
  const [bestSellingLoading, setBestSellingLoading] = useState(true);

  // useEffect(() => {
  //   if (!jobs) {
  //     getAllJobsForVendor();
  //   }
  // });

  useEffect(() => {
    if (props.jobs) {
      setJobs(props.jobs)
    }
  }, [props.jobs])

  useEffect(() => {
    getBestSelling();
  }, []);

  useEffect(() => {
    calculateAverage();
    calculateJobsPerTicket();
    getUpsellData();
  }, [jobs, vendor]);

  const getBestSelling = () => {
    Job()
      .getJson("getBestSellingServices")
      .then((response) => {
        setBestSelling(response.data)
      });
  };

  // const getAllJobsForVendor = () => {
  //   Vendor()
  //     .getJson("getAllJobsForVendor")
  //     .then((response) => {
  //       setJobs(response.data);
  //     });
  // };

  const getUpsellData = () => {
    Vendor()
      .getJson("upsell")
      .then((response) => {
        setUpsellData(response.data);
      });
  };

  const calculateAverage = () => {
    let total = 0;
    if (!jobs) {
      return 0;
    }
    if (jobs && jobs.length > 0) {
      jobs?.map((job) => {
        (total += job.totalPrice).toFixed(2);
      });
      if (total === 0) {
        return setAverage(0);
      }
      setAverage(total / jobs.length || 0);
    }
  };

  const calculateJobsPerTicket = () => {
    let total = 0;
    if (!jobs) {
      setJobsPerTicket(0);
      return;
    }
    if (jobs && jobs.length > 0) {
      jobs?.map((job) => {
        total += job.services.length;
      });
      if (total === 0) {
        setJobsPerTicket(0);
        setPerTicketLoading(false);
        return;
      }
      setJobsPerTicket(total / jobs.length);
      setPerTicketLoading(false);
    }
  };

  // const calculateBestSellingJobs = () => {
  //   if (!jobs) {
  //     setBestSellingLoading(false);
  //     return setBestSelling({});
  //   }

  //   const serviceTitles = jobs?.flatMap((job) =>
  //     job.services.map((service) => service.serviceTitle)
  //   );
  //   const serviceTitleCounts = serviceTitles.reduce((counts, title) => {
  //     counts[title] = (counts[title] || 0) + 1;
  //     return counts;
  //   }, {});

  //   const sortedServiceTitles = Object.keys(serviceTitleCounts).sort(
  //     (a, b) => serviceTitleCounts[b] - serviceTitleCounts[a]
  //   );
  //   const topThreeServices = sortedServiceTitles.slice(0, 3);

  //   const averagePrices = topThreeServices.map((title) => {
  //     const servicesWithSameTitle = serviceTitles.filter(
  //       (serviceTitle) => serviceTitle === title
  //     );
  //     const totalPrices = jobs.reduce((sum, job) => {
  //       const matchingServices = job.services.filter(
  //         (service) => service.serviceTitle === title
  //       );
  //       return (
  //         sum +
  //         matchingServices.reduce(
  //           (serviceSum, service) =>
  //             serviceSum + parseFloat(service.servicePrice),
  //           0
  //         )
  //       );
  //     }, 0);

  //     const averagePrice = totalPrices / servicesWithSameTitle.length;
  //     const numberOfSales = serviceTitleCounts[title];

  //     return { title, averagePrice, numberOfSales };
  //   });

  //   setBestSellingLoading(false);
  //   setBestSelling(averagePrices);
  // };

  return (
    <div>
      <Card style={{ width: "100%", border: "1px solid #ccc", borderRadius: "10px" }}>
        <CardContent>
          <Typography
            variant="h1"
            component="div"
            sx={{ marginBottom: 1, fontSize: "40px", marginBottom: "30px", userSelect: "none" }}
          >
            Business Analytics
          </Typography>
          <Grid container rowSpacing={4}>
            <Grid item xs={12}>
              <UpsellData
                upsellData={upsellData}
                upsellLoading={upsellLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <AverageTicket
                average={average}
                averageTicketLoading={averageTicketLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <JobsPerTicket
                jobsPerTicket={jobsPerTicket}
                perTicketLoading={perTicketLoading}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <BoughtTogetherComponent />
            </Grid> */}
            <Grid item xs={12}>
              <BestSellingJobsComponent
                bestSelling={bestSelling}
                bestSellingLoading={bestSellingLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default AnalyticsComponent;
